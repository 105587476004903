import React from 'react';

function ItemTiles({ image, name, icode, price, discount, onClick }) {

  return (
    <div className="relative min-w-[245px] max-w-[230px] min-h-[280px] max-h-[280px] rounded-xl bg-white flex flex-col px-1 mb-5 hover:border-[#76767639] hover:border-[3px] border-[3px] border-transparent" onClick={onClick}>
      {discount !== 0 &&
        <div className='flex absolute max-w-[230px] w-[100%] items-end justify-end'><p className=' text-[#c43030]'>-{discount.toFixed(2)}</p></div>
      }
      <div className='items-center'>
        <img src={image} alt={name} className="mt-6 mb-2 max-h-[165px] max-w-[230px] min-h-[165px] mx-auto" />
      </div>
      <div className="px-3 mt-1 mb-5">
        <h1 className="text-black text-[14px] font-semibold">{name}</h1>
        <h1 className="text-[#929292] text-[10px]">Item Code: {icode}</h1>
        <h1 className="mt-2 text-black text-[14px] font-semibold">LKR {price.toFixed(2)}</h1>
      </div>
    </div>

  )
}

export default ItemTiles
