import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';

const PendingOrders = () => {
    const [token, setToken] = useState(null);
    const [userID, setUserID] = useState(null);
    const [requests, setRequests] = useState([]);
    const statuses = ['Accepted', 'PriceAccepted'];
    const statusQuery = statuses.join(',');


    useEffect(() => {
        const storedData = localStorage.getItem('token');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            setToken(parsedData.token);

            try {
                const decodedToken = jwtDecode(parsedData.token);
                if (decodedToken) {
                    setUserID(decodedToken.userId);
                }
            } catch (error) {
                setUserID(null);
            }
        } else {
            setUserID(null);
        }

        if (token) {
            fetchRequests();
        }
    }, [token]);

    const fetchRequests = () => {
        fetch(`https://ceramic-back.achila.tech/api/supplier/requestspecific?UserID=${userID}&status=${statusQuery}`, {
            headers: {
                'Authorization': token,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch request details');
                }
                return response.json();
            })
            .then(data => {
                setRequests(data);
            })
            .catch(error => {
                console.error('Error fetching request details:', error);
            });
    };



    return (
        <div className="h-screen flex bg-[#F7F7F7]">
            <div className='w-20 h-screen'></div>
            <div className="flex w-full overflow-y-auto overflow-x-hidden">
                <div className='flex-col w-full'>
                    <div className='font-bold text-3xl px-[60px] mt-11 py-2 -m-5 bg-[#00000019]'>
                        <h1>Pending Orders</h1>
                    </div>
                    <div className='flex-col mt-10 px-10'>
                        {requests.length === 0 ? (
                            <div className="text-gray-500 text-lg">No pending orders found</div>
                        ) : (
                            requests.map(request => (
                                <div key={request.RequestID} className="flex flex-col w-full mb-4 p-4 bg-white rounded-lg shadow-md">
                                    <div className="flex items-center">
                                        <img src={request.photo} alt={request.ProductName} className="w-[250px] max-w-[250px]" />
                                        <div className="ml-8 mr-[80px] space-y-3 w-[40%]">
                                            <div>RequestID: {request.RequestID}</div>
                                            <div>ItemName: {request.ProductName}</div>
                                            <div>Quantity: {request.Quantity}</div>
                                            <div>Required date: {request.FormattedRequiredDate}</div>
                                        </div>
                                        <div className="flex items-stretch mr-[80px]">
                                            <div className="border-l border-gray-300"></div>
                                        </div>
                                        <div className="flex space-x-10 w-full h-full items-center">
                                            <div>
                                                <h1 className='text-3xl font-bold'>Unit Price : {request.UnitPrice} LKR</h1>
                                                {request.Status === 'Accepted' &&
                                                    <h1 className='text-[#7d0000] mt-4'>Order Pending for Approval</h1>
                                                }
                                                {request.Status === 'PriceAccepted' &&
                                                    <h1 className='text-[#1a6070] mt-4'>Price Accepted Deliver it before required date</h1>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PendingOrders;
