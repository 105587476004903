import React, { useState } from 'react';
import Table from '../../Components/admin/CategoryTable';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Swal from 'sweetalert2';

const Categories = () => {
  const [open, setOpen] = useState(false);
  const [categoryData, setCategoryData] = useState({
    Name: '',
    Description: '',
  });

  const storedData = localStorage.getItem('token');
  let token;

  if (storedData) {
    const parsedData = JSON.parse(storedData);
    token = parsedData.token;
  } else {// Handle the case where the token is not stored
    token = null; 
  }

  const [errors, setErrors] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCategoryData({
      Name: '',
      Description: '',
    });
    setErrors({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === 'Name' ) {
      newValue = newValue.slice(0, 20); // Limit to 20 characters for Address
      newValue = newValue.replace(/[0-9]/g, ''); // Allow only alphabets
    } else if (name === 'Description') {
      newValue = newValue.slice(0, 50);
      newValue = newValue.replace(/[0-9]/g, ''); // Allow only alphabets
    }
    setCategoryData({ ...categoryData, [name]: newValue });
    setErrors({ ...errors, [name]: '' }); // Clear the error when input changes
  };

  const validateForm = () => {
    const errors = {};
    if (!categoryData.Name.trim()) {
      errors.Name = 'Category Name is required';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0; // Return true if there are no errors
  };

  //Add Category Function
  const handleAdd = async () => {
    if (validateForm()) {
      try {
        const response = await fetch('https://ceramic-back.achila.tech/api/category/add', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'authorization': token,
          },
          body: JSON.stringify(categoryData),
        });

        if (response.ok) {
          // category added successfully
          const data = await response.json();
          console.log('Category added:', data);
          Swal.fire({
            icon: 'success',
            title: 'Category Added Successfully!',
            customClass: {
              popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
            },
            didOpen: () => {
              document.querySelector('.swal2-container').style.zIndex = '9999'; 
            }
          }).then(() => {
            handleClose();
            window.location.reload(); // Close the dialog box
          });
        } else if (response.status === 400) {// Error due to existing fields
          const { errors } = await response.json();
          // Handle specific error cases
          if (errors.Name) {
            Swal.fire({
              icon: 'error',
              title: 'Category Addition Failed',
              text: errors.Name,
              customClass: {
                popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
              },
              didOpen: () => {
                document.querySelector('.swal2-container').style.zIndex = '9999'; 
              }
            });
          } else if (errors.Description) {
            Swal.fire({
              icon: 'error',
              title: 'Category Addition Failed',
              text: errors.Description,
              customClass: {
                popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
              },
              didOpen: () => {
                document.querySelector('.swal2-container').style.zIndex = '9999'; 
              }
            });
          } else {
            // Handle other validation errors
            Swal.fire({
              icon: 'error',
              title: 'Category Addition Failed',
              text: '',
              html: Object.values(errors).map(error => `<div>${error}</div>`).join(''),
              customClass: {
                popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
              },
              didOpen: () => {
                document.querySelector('.swal2-container').style.zIndex = '9999'; 
              }
            });
          }
        } else {
          // Other server-side error
          console.error('Failed to add Category');
          Swal.fire({
            icon: 'error',
            title: 'Category Addition Failed',
            text: 'An error occurred while adding the Category.',
            customClass: {
              popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
            },
            didOpen: () => {
              document.querySelector('.swal2-container').style.zIndex = '9999'; 
            }
          });
        }
      } catch (error) {
        // Handle network or unexpected errors
        console.error('Error adding Category:', error);
        let errorMessage = 'An error occurred while adding the Category.';
        Swal.fire({
          icon: 'error',
          title: 'Category Addition Failed',
          text: errorMessage,
          customClass: {
            popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
          },
          didOpen: () => {
            document.querySelector('.swal2-container').style.zIndex = '9999'; 
          }
        });
      }
    }
  };

  return (
    <div className='flex-col w-full h-screen bg-[#F7F7F7]'>
        <div className='flex justify-end mt-10 mr-10'>
        <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={handleClickOpen}>Add Product Category</Button>
        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            disableEscapeKeyDown={true}
            BackdropProps={{
            style: { backdropFilter: 'blur(5px)' },
            invisible: true // This will prevent backdrop click
            }}
        >
            <DialogTitle id="form-dialog-title" className='text-center font-extrabold'>Add Category</DialogTitle>
            <div className='mb-3'>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Name"
                    name="Name"
                    value={categoryData.Name}
                    onChange={handleChange}
                    fullWidth
                    error={Boolean(errors.Name)}
                    helperText={errors.Name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    name="Description"
                    value={categoryData.Description}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={4} // Adjust the number of rows as needed
                    error={Boolean(errors.Description)}
                    helperText={errors.Description}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            </div>
            <DialogActions className="mx-4 mb-4">
            <div className='w-full space-y-2'>
                <Button onClick={handleAdd} color="success" variant="contained" fullWidth >
                Add Category
                </Button>
                <Button onClick={handleClose} color="error" variant="contained" fullWidth >
                Cancel
                </Button>
            </div>
            </DialogActions>
        </Dialog>
        </div>
        <div className='p-10'>
        <Table />
        </div>
    </div>
  );
};

export default Categories;