import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import Swal from 'sweetalert2';

const SupplierDashboard = () => {
  const [token, setToken] = useState(null);
  const [userID, setUserID] = useState(null);
  const [requests, setRequests] = useState([]);
  const statuses = ['Requested'];
  const statusQuery = statuses.map(status => `${encodeURIComponent(status)}`).join('&');

  useEffect(() => {
    const storedData = localStorage.getItem('token');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setToken(parsedData.token);

      try {
        const decodedToken = jwtDecode(parsedData.token);
        if (decodedToken) {
          setUserID(decodedToken.userId);
        }
      } catch (error) {
        setUserID(null);
      }
    } else {
      setUserID(null);
    }

    if (token) {
      fetchRequests();
    }
  }, [token]);

  const fetchRequests = () => {
    fetch(`https://ceramic-back.achila.tech/api/supplier/requestspecific?UserID=${userID}&status=${statusQuery}`, {
      headers: {
        'Authorization': token,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch request details');
        }
        return response.json();
      })
      .then(data => {
        setRequests(data);
      })
      .catch(error => {
        console.error('Error fetching request details:', error);
      });
  };

  const handleAccept = (requestID) => {
    Swal.fire({
      title: 'Enter Unit Item Price',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
        maxLength: 10,
        autocomplete: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Accept the Order',
      showLoaderOnConfirm: true,
      preConfirm: (unitPrice) => {
        // Check if unit price is empty
        if (!unitPrice || unitPrice.trim() === "") {
          Swal.showValidationMessage('Please enter a unit item price');
          return false; // Prevent the modal from closing if validation fails
        }
        // Validate unit price (only numbers with optional decimal)
        if (!/^\d*\.?\d*$/.test(unitPrice)) {
          Swal.showValidationMessage('Please enter a valid unit item price (numbers only)');
          return false; // Prevent the modal from closing if validation fails
        }
        // Send a request to the backend to accept the order with the given requestID and unit price
        return fetch(`https://ceramic-back.achila.tech/api/supplier/acceptOrder`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
          body: JSON.stringify({ requestID, unitPrice }),
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Failed to accept the order');
            }
            // Show success notification
            Swal.fire({
              icon: 'success',
              title: 'Order Accepted',
              text: 'The order has been accepted successfully.',
            }).then(() => {
              // Reload the page after successful acceptance
              window.location.reload();
            });
          })
          .catch(error => {
            // Show error notification
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Failed to accept the order. Please try again later.',
            });
          });
      }
    });

    // Prevent alphabets from being entered into the input field
    const inputField = document.querySelector('.swal2-input');
    inputField.addEventListener('input', function () {
      const inputValue = inputField.value;
      inputField.value = inputValue.replace(/\D/g, ''); // Replace non-numeric characters with an empty string
    });
  };


  const handleDecline = (requestID) => {
    // Show confirmation dialog before declining the order
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'You are about to decline the order. This action cannot be undone.',
      showCancelButton: true,
      confirmButtonText: 'Yes, decline it',
      cancelButtonText: 'No, keep it',
      reverseButtons: true // Display confirm button on the left
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed, proceed with declining the order
        // Send a request to the backend to decline the order with the given requestID
        fetch(`https://ceramic-back.achila.tech/api/supplier/declineOrder`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
          body: JSON.stringify({ requestID }),
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Failed to decline the order');
            }
            // Show success notification
            Swal.fire({
              icon: 'success',
              title: 'Order Declined',
              text: 'The order has been declined successfully.',
            }).then(() => {
              // Reload the page after successful decline
              window.location.reload();
            });
          })
          .catch(error => {
            // Show error notification
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Failed to decline the order. Please try again later.',
            });
          });
      }
    });
  };

  return (
    <div className="h-screen flex bg-[#F7F7F7]">
      <div className='w-20 h-screen'></div>
      <div className="flex w-full overflow-y-auto overflow-x-hidden">
        <div className='flex-col w-full'>
          <div className='font-bold text-3xl px-[60px] mt-11 py-2 -m-5 bg-[#00000019]'>
            <h1>Recent Requests</h1>
          </div>
          <div className='flex-col mt-10 px-10'>
            {requests.length === 0 ? (
              <div className="text-gray-500 text-lg">No pending orders found</div>
            ) : (
              requests.map(request => (
                <div key={request.RequestID} className="flex flex-col w-full mb-4 p-4 bg-white rounded-lg shadow-md">
                  <div className="flex items-center">
                    <img src={request.photo} alt={request.ProductName} className="w-[250px] max-w-[250px]" />
                    <div className="ml-8 mr-[80px] space-y-3 w-[40%]">
                      <div>RequestID: {request.RequestID}</div>
                      <div>ItemName: {request.ProductName}</div>
                      <div>Quantity: {request.Quantity}</div>
                      <div>Required date: {request.FormattedRequiredDate}</div>
                    </div>
                    <div className="border-l border-gray-300 h-full mr-[80px]"></div>
                    <div className="flex space-x-10 w-full h-full items-center">
                      <button onClick={() => handleAccept(request.RequestID)} className="bg-[green] text-white px-4 py-2 rounded-xl">
                        Accept the order
                      </button>
                      <button onClick={() => handleDecline(request.RequestID)} className="bg-red-500 text-white px-4 py-2 rounded-xl">
                        Decline the order
                      </button>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierDashboard;
