import React from 'react';

const CategoryTiles = ({ icon, text, isIconComponent, onClick, isSelected }) => {
  const iconStyles = {
    width: '40px',
    height: '40px',
  };

  return (
    <div
      className={`min-w-24 max-w-24 rounded-xl bg-white items-center flex flex-col px-1 mt-5 cursor-pointer
        ${isSelected ? 'bg-[#FFEEEF] border-[#F05756]' : 'hover:bg-[#FFEEEF] hover:border-[#F05756]'}
        border-[1px] ${isSelected ? 'border-[#F05756]' : 'border-transparent'}`}
      onClick={onClick}
    >
      {isIconComponent ? (
        <div className="mt-6 mb-2" style={iconStyles}>{React.createElement(icon, { style: iconStyles })}</div>
      ) : (
        <img src={icon} alt={text} className="mt-6 mb-2" style={iconStyles} />
      )}
      <h1 className={`mb-6 text-xs ${isSelected ? 'text-[#F05756]' : 'text-[#929292]'}`}>{text}</h1>
    </div>
  );
};

export default CategoryTiles;
