import React, { useState, useEffect } from 'react';
import Table1 from '../../Components/showroom/DispatchTable';
import Table from '../../Components/showroom/CompleteDispatchTable';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, MenuItem, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import { jwtDecode } from 'jwt-decode';

import Swal from 'sweetalert2';

const Dispatch = () => {
    const [open, setOpen] = useState(false);
    const [completeRequestsOpen, setCompleteRequestsOpen] = useState(false);
    const [stockEntries, setStockEntries] = useState([{ item: null, quantity: '' }]);
    const [productList, setProductList] = useState([]);
    const [errors, setErrors] = useState({});
    const [token, setToken] = useState(null);
    const [userID, setuserID] = useState(null);
    const [userRole, setuserRole] = useState(null);
    const [warehouse, setwarehouse] = useState(null);

    useEffect(() => {
        const storedData = localStorage.getItem('token');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            setToken(parsedData.token);

            try {
                const decodedToken = jwtDecode(parsedData.token);
                if (decodedToken) {
                    setuserID(decodedToken.userId);
                    setuserRole(decodedToken.role);
                    if (decodedToken.role === 'Warehouse Staff' || decodedToken.role === 'Manager') {
                        setwarehouse(decodedToken.WarehouseName);
                    }
                }
            } catch (error) {
                setuserID(null);
                setuserRole(null);
            }
        } else {
            setuserID(null);
            setuserRole(null);
        }
        if (token) {
            fetchProductList();
        }
    }, [token]);

    const fetchProductList = async () => {
        try {
            const response = await fetch('https://ceramic-back.achila.tech/api/product/getproduct', {
                headers: {
                    'authorization': token,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setProductList(data.products);
            } else {
                console.error('Failed to fetch product list');
            }
        } catch (error) {
            console.error('Error fetching product list:', error);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setStockEntries([{ item: '', quantity: '' }]);
        setErrors({});
    };

    const handleCompleteRequestsOpen = () => {
        setCompleteRequestsOpen(true);
    };

    const handleCompleteRequestsClose = () => {
        setCompleteRequestsOpen(false);
    };

    const handleStockEntryChange = (index, e) => {
        const { name, value } = e.target;
        const newStockEntries = [...stockEntries];
        newStockEntries[index][name] = value;
        setStockEntries(newStockEntries);
    };

    const handleAddRow = () => {
        setStockEntries([...stockEntries, { item: '', quantity: '' }]);
    };

    const handleDeleteRow = (index) => {
        const newStockEntries = stockEntries.filter((_, i) => i !== index);
        setStockEntries(newStockEntries);
    };

    const validateForm = () => {
        const errors = {};
        stockEntries.forEach((entry, index) => {
            if (!entry.item || !entry.item.trim()) {
                errors[`item${index}`] = 'Item is required';
            }
            if (!entry.quantity.trim()) {
                errors[`quantity${index}`] = 'Quantity is required';
            } else if (!/^\d{1,10}$/.test(entry.quantity)) {
                errors[`quantity${index}`] = 'Quantity should be a number with up to 10 digits';
            }
        });
        setErrors(errors);
        return Object.keys(errors).length === 0; // Return true if there are no errors
    };

    const handleAddRequest = async () => {
        if (validateForm()) {
            try {
                const response = await fetch('https://ceramic-back.achila.tech/api/dispatch/add', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    body: JSON.stringify({ stockEntries, userID }),
                });
                if (response.ok) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Dispatch Requested Successfully!',
                        customClass: {
                            popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                        },
                        didOpen: () => {
                            document.querySelector('.swal2-container').style.zIndex = '9999'; 
                        }
                    }).then(() => {
                        handleClose();
                        window.location.reload();
                    });
                } else {
                    if (response.status === 400) {
                        // If status is 400, set a specific error message
                        Swal.fire({
                            icon: 'error',
                            title: 'Failed to Request',
                            customClass: {
                                popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                            },
                            didOpen: () => {
                                document.querySelector('.swal2-container').style.zIndex = '9999'; 
                            },
                            text: 'Not enough stock in warehouse 1 for one or more items',
                        });
                    } else {
                        const responseData = await response.json();
                        // Handle error response
                        Swal.fire({
                            icon: 'error',
                            title: 'Failed to Request',
                            customClass: {
                                popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                            },
                            didOpen: () => {
                                document.querySelector('.swal2-container').style.zIndex = '9999'; 
                            },
                            text: responseData.message || 'An error occurred while requesting.',
                        });
                    }
                }
            } catch (error) {
                console.error('Error adding request:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to Add Request',
                    customClass: {
                        popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                    },
                    didOpen: () => {
                        document.querySelector('.swal2-container').style.zIndex = '9999'; 
                    },
                    text: error.message || 'An error occurred while requesting.',
                });
            }
        }
    };

    return (
        <div className="h-screen flex bg-[#F7F7F7]">
            <div className='w-20 h-screen'></div>
            <div className="flex w-full overflow-y-auto overflow-x-hidden">
                <div className='flex-col w-full'>
                    <div className='font-bold text-3xl px-[60px] mt-11 py-2 -m-5 bg-[#00000019]'>
                        <h1>Dispatch Requests</h1>
                    </div>
                    <div className='flex justify-end mt-10 mr-10'>
                        <Button variant="contained" color="success" style={{ backgroundColor: '#916600', marginRight: '12px' }} startIcon={<BookOutlinedIcon />} onClick={handleCompleteRequestsOpen}>Complete Requests</Button>
                        {((userRole === 'Manager' && warehouse === 'Kandy') || userRole === 'Admin' || userRole === 'Showroom Staff') &&
                            <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={handleClickOpen}>Add Request</Button>
                        }
                        <Dialog
                            open={open}
                            aria-labelledby="form-dialog-title"
                            disableEscapeKeyDown={true}
                            BackdropProps={{
                                style: { backdropFilter: 'blur(5px)' },
                                invisible: true // This will prevent backdrop click
                            }}
                            maxWidth="sm" // Ensure consistent width
                            fullWidth
                            disableAutoFocus
                        >
                            <DialogTitle id="form-dialog-title" className='text-center font-extrabold'>Add Dispatch Request</DialogTitle>
                            <div className='mb-3'>
                                <DialogContent>
                                    <Grid container spacing={2}>
                                        {stockEntries.map((entry, index) => (
                                            <React.Fragment key={index}>
                                                <Grid item xs={6.7}>
                                                    <Autocomplete
                                                        autoHighlight
                                                        options={productList.filter(option =>
                                                            !stockEntries.some((entry, i) => i !== index && entry.item === option.ItemCode)
                                                        )}
                                                        getOptionLabel={(option) => option.ItemCode}
                                                        filterOptions={(options, { inputValue }) => options.filter(item => item.Name.includes(inputValue) || item.ItemCode.toString().includes(inputValue))}
                                                        onChange={(e, newValue) => {
                                                            const selectedItemCode = newValue ? newValue.ItemCode : ''; // Extract ItemCode from newValue
                                                            handleStockEntryChange(index, { target: { name: 'item', value: selectedItemCode } }); // Pass only the ItemCode
                                                        }}
                                                        renderOption={(props, option) => (
                                                            <Box {...props}>
                                                                {option.ItemCode} - {option.Name}
                                                            </Box>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Select Item"
                                                                error={Boolean(errors[`item${index}`])}
                                                                helperText={errors[`item${index}`]}
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    onInput: (event) => {
                                                                        event.target.value = event.target.value.toUpperCase().replace(/[^A-Z0-9\s]/g, ''); // Convert to uppercase and remove non-uppercase characters
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={3.5}>
                                                    <TextField
                                                        label="Quantity"
                                                        name="quantity"
                                                        value={entry.quantity}
                                                        onChange={(e) => {
                                                            if (/^\d*$/.test(e.target.value) && e.target.value.length <= 10) {
                                                                handleStockEntryChange(index, e);
                                                            }
                                                        }}
                                                        fullWidth
                                                        error={Boolean(errors[`quantity${index}`])}
                                                        helperText={errors[`quantity${index}`]}
                                                    />
                                                </Grid>
                                                <Grid item xs={1} className="flex items-center">
                                                    {index !== 0 && (
                                                        <Button onClick={() => handleDeleteRow(index)} color="error" variant="contained">
                                                            <DeleteIcon />
                                                        </Button>
                                                    )}
                                                </Grid>
                                            </React.Fragment>
                                        ))}
                                        <Grid item xs={2} className="flex">
                                            <Button onClick={handleAddRow} color="primary" variant="contained" fullWidth>
                                                <AddIcon /> Add
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                            </div>
                            <DialogActions className="mx-4 mb-4">
                                <div className='w-full space-y-2'>
                                    <Button onClick={handleAddRequest} color="success" variant="contained" fullWidth >
                                        Add Request
                                    </Button>
                                    <Button onClick={handleClose} color="error" variant="contained" fullWidth >
                                        Cancel
                                    </Button>
                                </div>
                            </DialogActions>
                        </Dialog>

                        {/* Complete Requests Dialog */}

                        <Dialog
                            open={completeRequestsOpen}
                            aria-labelledby="complete-requests-dialog-title"
                            disableEscapeKeyDown={true}
                            BackdropProps={{
                                style: { backdropFilter: 'blur(5px)' },
                                invisible: true // This will prevent backdrop click
                            }}
                            maxWidth="lg"
                            fullWidth
                            disableAutoFocus
                        >
                            <DialogTitle id="form-dialog-title" className='text-center font-extrabold'>Complete Requests</DialogTitle>
                            <div className='mb-3'>
                                <DialogContent style={{ maxHeight: '600px' }}>
                                    <Table />
                                </DialogContent>
                            </div>
                            <DialogActions className="mx-4 mb-4">
                                <div className='w-full space-y-2'>
                                    <Button onClick={handleCompleteRequestsClose} color="error" variant="contained" fullWidth >
                                        Cancel
                                    </Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                    </div>
                    <div className='p-10'>
                        <Table1 />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Dispatch;

