import React, { useState, useEffect } from 'react';

const ProductCard = ({ product, handleRemove, count, onAdd, onMinus }) => {
  const [inputCount, setInputCount] = useState(count);
  const [amount, setAmount] = useState((product.Price * count).toFixed(2));

  useEffect(() => {
    setAmount((product.Price * count).toFixed(2));
    setInputCount(count);
  }, [count, product.Price]);

  const handleCountChange = (event) => {
    let newCount = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    newCount = newCount === '' ? '' : Math.min(999999, parseInt(newCount, 10)); // Limit count to 999999

    setInputCount(newCount);
  };

  const handleBlur = () => {
    if (inputCount === '' || isNaN(inputCount) || inputCount < 1) {
      setInputCount(count); // Reset to previous count if input is empty, invalid, or less than 1
    } else {
      const newCount = parseInt(inputCount, 10);
      const difference = newCount - count;
      if (difference > 0) {
        onAdd(difference);
      } else if (difference < 0) {
        onMinus(-difference);
      }
    }
  };

  const addItem = () => {
    if (inputCount < 999999) {
      onAdd(1);
      setInputCount(inputCount + 1);
    }
  };

  const minusItem = () => {
    if (inputCount > 1) {
      onMinus(1);
      setInputCount(inputCount - 1);
    }
  };

  return (
    <div className="flex items-center justify-between w-full p-4 border-b border-gray-200">
      <div className="flex items-center">
        <img className="w-16 h-16 object-cover mr-4" src={product.Photo} alt={product.Name} />
        <div>
          <div className="font-bold text-lg">{product.Name}</div>
          <div className="text-gray-500">Item Code: {product.ItemCode}</div>
        </div>
      </div>
      <div className="flex items-center">
        <div className='flex flex-col mx-4 items-center'>
            <div className="flex items-center">
                <button className="mr-2" onClick={minusItem}>-</button>
                <input
                type="text"
                value={inputCount}
                onChange={handleCountChange}
                onBlur={handleBlur}
                className="w-16 text-center border border-gray-300 rounded px-2"
                maxLength={6}
                />
                <button className="ml-2" onClick={addItem}>+</button>
            </div>
            <div className="text-lg font-bold mt-2">LKR {amount}</div>
            </div>
        <div>
            <button
            className="text-red-500 hover:text-red-700"
            onClick={() => handleRemove(product)}
            >
            ❌
            </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
