import LoginDash from '../../Components/login_dash';

const Login = () => {

    return (
        <div className="flex items-center justify-center h-screen">
            <LoginDash />
        </div>
    );
};

export default Login;
