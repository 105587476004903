import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton, Collapse, Box, TextField, MenuItem, InputAdornment, Button } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import Pagination from '@mui/material/Pagination';
import SearchIcon from '@mui/icons-material/Search';
import { jwtDecode } from 'jwt-decode';
import Swal from 'sweetalert2';

const RequestTable = () => {
    const [requests, setRequests] = useState([]);
    const [expandedRow, setExpandedRow] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredRequests, setfilteredRequests] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Default to 10 items per page
    const [token, setToken] = useState(null);
    const [userRole, setuserRole] = useState(null);
    const [warehouse, setwarehouse] = useState(null);

    useEffect(() => {
        const storedData = localStorage.getItem('token');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            setToken(parsedData.token);
            try {
                const decodedToken = jwtDecode(parsedData.token);
                if (decodedToken) {
                    setuserRole(decodedToken.role);
                    setwarehouse(decodedToken.warehouse);
                }
            } catch (error) {
                setuserRole(null);
            }
        }

        if (token) {
            fetchrequests();
        }
    }, [token]);

    const fetchrequests = () => {
        fetch('https://ceramic-back.achila.tech/api/supplier/requestdetails', {
            headers: {
                'Authorization': token,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch request details');
                }
                return response.json();
            })
            .then(data => {
                setRequests(data);
            })
            .catch(error => {
                console.error('Error fetching request details:', error);
            });
    };

    const handleRowClick = (rowId) => {
        if (expandedRow === rowId) {
            setExpandedRow(null);
        } else {
            setExpandedRow(rowId);
        }
    };

    useEffect(() => {
        const filteredItems = requests.filter(request => {
            const ItemCode = request.ItemCode.toString().toLowerCase();
            const Name = request.ProductName?.toString().toLowerCase() || '';
            const search = searchQuery.toLowerCase();
            const matchesSearchQuery = Name.includes(search) || Name === search || ItemCode.includes(search) || ItemCode === search;
            // const matchesCategories = categoryfilter.length === 0 || categoryfilter.some(category => category.title === item.category);
            // const matchesWarehouses = warehousefilter.length === 0 || warehousefilter.some(warehouse => warehouse.title === item.warehouse);
            // const matchesAmountRange = item.quanity >= minValue && item.quanity <= maxValue;
            return matchesSearchQuery;
        });

        setfilteredRequests(filteredItems);
    }, [searchQuery, requests]);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeItemsPerPage = (event) => {
        setItemsPerPage(event.target.value);
        setCurrentPage(1); // Reset to the first page when changing items per page
    };

    const handleCancel = (requestID) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you really want to cancel this order?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, cancel it!'
        }).then((result) => {
            if (result.isConfirmed) {
                // Send a request to the backend to cancel the order with the given requestID
                fetch(`https://ceramic-back.achila.tech/api/supplier/cancelOrder`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    body: JSON.stringify({ requestID }),
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Failed to cancel the order');
                        }
                        // Show success notification
                        Swal.fire({
                            icon: 'success',
                            title: 'Order Canceled',
                            text: 'The order has been canceled successfully.',
                        }).then(() => {
                            // Reload the page after successful cancellation
                            window.location.reload();
                        });
                    })
                    .catch(error => {
                        // Show error notification
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'Failed to cancel the order. Please try again later.',
                        });
                    });
            }
        });
    };

    const handlePriceAccept = (requestID) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you really want to accept the price?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, accept it!'
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`https://ceramic-back.achila.tech/api/supplier/priceAccept`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    body: JSON.stringify({ requestID }),
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Failed to accept the price');
                        }
                        return response.json();
                    })
                    .then(data => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Price Accepted',
                            text: 'The price has been accepted successfully.',
                        }).then(() => {
                            // Reload the page after successful acceptance
                            window.location.reload();
                        });
                    })
                    .catch(error => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'Failed to accept the price. Please try again later.',
                        });
                    });
            }
        });
    };

    const handleReject = (requestID) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you really want to reject the price?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, reject it!'
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`https://ceramic-back.achila.tech/api/supplier/rejectPrice`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    body: JSON.stringify({ requestID }),
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Failed to reject the price');
                        }
                        return response.json();
                    })
                    .then(data => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Price Rejected',
                            text: 'The price has been rejected successfully.',
                        }).then(() => {
                            // Reload the page after successful rejection
                            window.location.reload();
                        });
                    })
                    .catch(error => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'Failed to reject the price. Please try again later.',
                        });
                    });
            }
        });
    };




    // Calculate pagination range
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredRequests.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div className="h-screen flex bg-[#F7F7F7]">
            <div className="w-full px-5 overflow-auto">
                <div className='mb-5'>
                    <div className='py-2 w-[20%]'>
                        <TextField
                            fullWidth
                            label="Search Item Name or ItemCode"
                            value={searchQuery}
                            onChange={(e) => {
                                const value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
                                setSearchQuery(value);
                            }}
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: searchQuery && (
                                    <IconButton onClick={() => setSearchQuery('')}>
                                        <ClearIcon />
                                    </IconButton>
                                ),
                            }}
                        />
                    </div>
                </div>
                <div className='mb-4 flex items-end justify-end'>
                    <div className='flex items-center justify-end mr-5'>
                        <span style={{ color: '#4E4E4E' }}>Rows per page: </span>
                        <TextField sx={{ minWidth: 60 }} size="small"
                            select
                            value={itemsPerPage}
                            onChange={handleChangeItemsPerPage}
                            variant="outlined"
                            className="w-18 left-2"
                        >
                            {[10, 25, 50, 100].map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className="flex items-end justify-end">
                        <Pagination
                            count={Math.ceil(filteredRequests.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handleChangePage}
                            variant="outlined"
                            shape="rounded"
                        />
                    </div>
                </div>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Request ID</b></TableCell>
                                <TableCell><b>Requested Date</b></TableCell>
                                <TableCell><b>Supplier ID</b></TableCell>
                                <TableCell><b>Supplier Name</b></TableCell>
                                <TableCell><b>Requested User</b></TableCell>
                                <TableCell><b>Required Date</b></TableCell>
                                <TableCell><b>Status</b></TableCell>
                                <TableCell><b>Notes</b></TableCell>
                                <TableCell />
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentItems.map((request) => (
                                <React.Fragment key={request.RequestID}>
                                    <TableRow onClick={() => handleRowClick(request.RequestID)}>
                                        <TableCell>{request.RequestID}</TableCell>
                                        <TableCell>{request.FormattedRequestDate}</TableCell>
                                        <TableCell>{request.SupplierID}</TableCell>
                                        <TableCell>{request.SupplierName}</TableCell>
                                        <TableCell>{request.RequestName}</TableCell>
                                        <TableCell>{request.FormattedRequiredDate}</TableCell>
                                        <TableCell>{request.Status}</TableCell>
                                        <TableCell>{request.SpecialNotes}</TableCell>
                                        <TableCell>
                                            <IconButton aria-label="expand row" size="small" onClick={() => handleRowClick(request.RequestID)}>
                                                {expandedRow === request.RequestID ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            {request.Status === 'Requested' && (userRole === 'Manager' || userRole === 'Admin') &&
                                                <Button variant="contained" color="success" style={{ backgroundColor: '#7d0000', width: '70%' }} onClick={() => handleCancel(request.RequestID)}>Cancel Request</Button>
                                            }
                                            {request.Status === 'Accepted' && (userRole === 'Manager' || userRole === 'Admin') && (
                                                <div className='flex space-x-2'>
                                                    <h1>Unit Price : {request.UnitPrice} LKR</h1>
                                                    <Button variant="contained" color="success" style={{ width: '70%' }} onClick={() => handlePriceAccept(request.RequestID)}>Accept Price</Button>
                                                    <Button variant="contained" color="success" style={{ backgroundColor: '#7d0000', width: '70%' }} onClick={() => handleReject(request.RequestID)}>Reject Price</Button>
                                                </div>
                                            )}
                                        </TableCell>

                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                                            <Collapse in={expandedRow === request.RequestID} timeout="auto" unmountOnExit>
                                                <Box margin={1} style={{ backgroundColor: '#F0F0F0', padding: '10px', borderRadius: '10px' }}>
                                                    <Typography variant="h6" gutterBottom component="div">
                                                        Product Details
                                                    </Typography>
                                                    <Table size="small" aria-label="purchases">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell><b>Item Code</b></TableCell>
                                                                <TableCell><b>Item Name</b></TableCell>
                                                                <TableCell><b>Quantity</b></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell>{request.ItemCode}</TableCell>
                                                                <TableCell>{request.ProductName}</TableCell>
                                                                <TableCell>{request.Quantity}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default RequestTable;


