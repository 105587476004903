import React, { useState } from 'react';
import { HiOutlineSearch, HiX } from 'react-icons/hi';

const SearchBar = ({ onSearch, suggestions, onSelectSuggestion }) => {
  const [searchText, setSearchText] = useState('');

  const handleChange = (event) => {
    const newText = event.target.value.toUpperCase();
    if (/^[A-Z0-9 ]*$/.test(newText)) { // Updated regex to include uppercase letters and spaces
      setSearchText(newText);
      onSearch(newText);
    }
  };

  const handleClear = () => {
    setSearchText('');
    onSearch('');
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchText(suggestion.Name);
    onSelectSuggestion(suggestion);
  };

  return (
    <div className="relative z-0">
      <div className="flex items-center bg-white rounded-lg overflow-hidden mx-8 mt-11 h-9">
        <input
          type="text"
          placeholder="Search Item"
          value={searchText}
          onChange={handleChange}
          className="py-4 px-4 bg-transparent outline-none flex-grow rounded-lg text-s"
        />
        <button onClick={handleClear} className="p-2 rounded-lg text-[#8a8a8a]">
          {searchText ? <HiX className="w-5 h-5" /> : <HiOutlineSearch className="w-5 h-5" />}
        </button>
      </div>
      {suggestions.length > 0 && (
        <ul className="absolute bg-white border border-gray-300 rounded mt-2 max-h-60 overflow-y-auto z-10 px-2 pt-2 mx-8 w-[calc(100%-60px)]">
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
              className="p-2 cursor-pointer hover:bg-gray-100"
            >
              {suggestion.Name} ({suggestion.ItemCode})
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
