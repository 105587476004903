import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton, Collapse, Box, TextField, MenuItem, InputAdornment, Button } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import Pagination from '@mui/material/Pagination';
import SearchIcon from '@mui/icons-material/Search';

const SupplierProductTable = () => {
    const [supplierProductDetails, setSupplierProductDetails] = useState([]);
    const [expandedRow, setExpandedRow] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredSupplierProductDetails, setFilteredSupplierProductDetails] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Default to 10 items per page
    const [token, setToken] = useState(null);

    useEffect(() => {
        const storedData = localStorage.getItem('token');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            setToken(parsedData.token);
        }

        if (token) {
            fetchSupplierProductDetails();
        }
    }, [token]);

    const fetchSupplierProductDetails = () => {
        fetch('https://ceramic-back.achila.tech/api/supplier/supplierProductDetails', {
            headers: {
                'Authorization': token,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch supplier product details');
                }
                return response.json();
            })
            .then(data => {
                setSupplierProductDetails(data);
            })
            .catch(error => {
                console.error('Error fetching supplier product details:', error);
            });
    };

    const handleRowClick = (rowId) => {
        if (expandedRow === rowId) {
            setExpandedRow(null);
        } else {
            setExpandedRow(rowId);
        }
    };

    useEffect(() => {
        const filteredData = supplierProductDetails.filter(supplierProduct => {
            const products = supplierProduct.products || [];
            const matchesSearchQuery = products.some(product => {
                const itemCode = product[0] ? product[0].toString().toLowerCase() : '';
                const itemName = product[1] ? product[1].toString().toLowerCase() : '';
                const search = searchQuery.toLowerCase();
                return itemName.includes(search) || itemCode.includes(search);
            });
            return matchesSearchQuery;
        });

        setFilteredSupplierProductDetails(filteredData);
    }, [searchQuery, supplierProductDetails]);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeItemsPerPage = (event) => {
        setItemsPerPage(event.target.value);
        setCurrentPage(1); // Reset to the first page when changing items per page
    };

    // Calculate pagination range
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredSupplierProductDetails.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div className="h-screen flex bg-[#F7F7F7]">
            <div className="w-full px-5 overflow-auto">
                <div className='mb-5'>
                    <div className='py-2 w-[20%]'>
                        <TextField
                            fullWidth
                            label="Search Item Name or ItemCode"
                            value={searchQuery}
                            onChange={(e) => {
                                const value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
                                setSearchQuery(value);
                            }}
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: searchQuery && (
                                    <IconButton onClick={() => setSearchQuery('')}>
                                        <ClearIcon />
                                    </IconButton>
                                ),
                            }}
                        />
                    </div>
                </div>
                <div className='mb-4 flex items-end justify-end'>
                    <div className='flex items-center justify-end mr-5'>
                        <span style={{ color: '#4E4E4E' }}>Rows per page: </span>
                        <TextField sx={{ minWidth: 60 }} size="small"
                            select
                            value={itemsPerPage}
                            onChange={handleChangeItemsPerPage}
                            variant="outlined"
                            className="w-18 left-2"
                        >
                            {[10, 25, 50, 100].map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className="flex items-end justify-end">
                        <Pagination
                            count={Math.ceil(filteredSupplierProductDetails.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handleChangePage}
                            variant="outlined"
                            shape="rounded"
                        />
                    </div>
                </div>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Supplier ID</b></TableCell>
                                <TableCell><b>User ID</b></TableCell>
                                <TableCell><b>First Name</b></TableCell>
                                <TableCell><b>Last Name</b></TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentItems.map((supplierProduct) => (
                                <React.Fragment key={supplierProduct.SupplierID}>
                                    <TableRow onClick={() => handleRowClick(supplierProduct.SupplierID)}>
                                        <TableCell>{supplierProduct.SupplierID}</TableCell>
                                        <TableCell>{supplierProduct.UserID}</TableCell>
                                        <TableCell>{supplierProduct.FirstName}</TableCell>
                                        <TableCell>{supplierProduct.LastName}</TableCell>
                                        <TableCell>
                                            <IconButton aria-label="expand row" size="small" onClick={() => handleRowClick(supplierProduct.SupplierID)}>
                                                {expandedRow === supplierProduct.SupplierID ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                                            <Collapse in={expandedRow === supplierProduct.SupplierID} timeout="auto" unmountOnExit>
                                                <Box margin={1} style={{ backgroundColor: '#F0F0F0', padding: '10px', borderRadius: '10px' }}>
                                                    <Typography variant="h6" gutterBottom component="div">
                                                        Product Details
                                                    </Typography>
                                                    <Table size="small" aria-label="purchases">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell><b>Item Code</b></TableCell>
                                                                <TableCell><b>Item Name</b></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {supplierProduct.products.map((product, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>{product[0]}</TableCell>
                                                                    <TableCell>{product[1]}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default SupplierProductTable;


