import React from 'react';

const ItemCard = ({ category, itemCode, productName, totalQuantity, totalRevenue }) => {
    return (
        <div className="item-card w-full h-[50px] border rounded-md p-4 bg-[#ffffffb1] mb-[8px]">
            <div className='flex w-full'>
                <div className='w-[20%]'>
                    {itemCode}
                </div>
                <div className='w-[30%]'>
                    {productName}
                </div>
                <div className='w-[25%]'>
                    {category}
                </div>
                <div className='w-[20%] flex justify-center'>
                    {totalQuantity}
                </div>
                <div className='w-[25%] flex justify-end'>
                    <div className='w-full ml-10 flex justify-start'>Rs.</div>
                    <div>{totalRevenue.toLocaleString()}</div>
                </div>
            </div>
        </div>

    );
};

export default ItemCard;
