import React, { useState, useEffect } from 'react';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ItemCard from '../../Components/admin/ItemCard';
import 'react-datepicker/dist/react-datepicker.css';
import Report from '../../Components/admin/SalesReport';


const AdminDashboard = () => {
  const [salesData, setSalesData] = useState([]);
  const [topsell, settopsell] = useState([]);
  const [barsalesData, setbarSalesData] = useState([]);
  const [timeRange, setTimeRange] = useState('week');
  const [timeRang, setTimeRang] = useState('week');
  const [loading, setLoading] = useState(true);
  const [averageRevenue, setAverageRevenue] = useState(0);
  const [token, setToken] = useState(() => {
    const storedData = localStorage.getItem('token');
    return storedData ? JSON.parse(storedData)?.token : null;
  });


  //calculate average revenue when sales data updated
  useEffect(() => {
    const calculateAverageRevenue = () => {
      const totalRevenueForWeek = salesData.reduce((total, item) => total + item.totalRevenue, 0);
      const average = totalRevenueForWeek / salesData.length;
      setAverageRevenue(average);
    };

    calculateAverageRevenue();
    setLoading(false);
  }, [salesData]);

 //fetch all sales data
  useEffect(() => {
    fetchSalesData();
    BarchartSalesData();
    fetchtopSalesData();
  }, [timeRange, timeRang, token]);

  const fetchtopSalesData = async () => {
    try {
      const response = await fetch(`https://ceramic-back.achila.tech/api/order/topsales?range=${timeRang}`, {
        headers: {
          'Authorization': token,
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch sales data');
      }
      const data = await response.json();
      settopsell(data);
    } catch (error) {
      console.error('Error fetching sales data:', error);
    }
  };

  const BarchartSalesData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://ceramic-back.achila.tech/api/order/sales?range=${timeRange}`, {
        headers: {
          'Authorization': token,
        },
      });
      const data = await response.json();
      setbarSalesData(data);
    } catch (error) {
      console.error('Error fetching sales data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSalesData = async () => {
    try {
      const response = await fetch('https://ceramic-back.achila.tech/api/order/sales-data', {
        headers: {
          'Authorization': token,
        },
      }) // Make a GET request to fetch sales data from the backend
      if (!response.ok) {
        throw new Error('Failed to fetch sales data');
      }
      const data = await response.json();
      setSalesData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching sales data:', error);
    }
  };



  return (
    <div className="h-screen flex bg-[#F7F7F7]">
      <div className='w-20 h-screen'>
      </div>
      <div className='flex p-5 flex-wrap  w-screen mt-[1%]'>
        <div className="flex w-full  overflow-y-auto space-x-6 h-[50%]">
          <div className='w-full sm:w-4/5 lg:w-3/4 bg-[#dfdfdf7d] h-[95%] rounded-lg shadow-md mx-auto'>
            <div className=''>
              <div className='px-5 pt-5 font-bold text-2xl'>Sales Chart</div>
              <span className='px-5'>(Last 7 Days)</span>
              <div className='text-md flex justify-center'>Average Revenue</div>
              <div className='font-semibold text-xl flex justify-center'>
                Rs.{averageRevenue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </div>
              {loading ? (
                <div className='flex justify-center mt-5'>Loading...</div>
              ) : (
                <div className='px-4 sm:px-6 lg:px-10'>
                  <ResponsiveContainer width='100%' height={300}>
                    <LineChart
                      data={salesData}
                      margin={{ top: 10, right: 30, left: 50, bottom: 40 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="orderDate" label={{ value: 'Order Date', position: 'insideBottom', offset: -25, style: { fill: 'black' } }} />
                      <YAxis
                        label={{
                          value: 'Total Revenue (LKR)',
                          angle: -90,
                          position: 'insideLeft',
                          dx: -40, // Adjust horizontal position
                          dy: 70,   // Adjust vertical position
                          style: { fill: 'black' }
                        }}
                      />
                      <Tooltip
                        contentStyle={{ backgroundColor: '#f0f0f0', border: '1px solid #ccc' }}
                        labelStyle={{ color: '#333', fontWeight: 'bold' }}
                        formatter={(value) => `LKR ${value.toLocaleString()}`}
                      />
                      <Line
                        type="monotone"
                        dataKey="totalRevenue"
                        stroke="#8884d8"
                        strokeWidth={2}
                        dot={{ stroke: '#8884d8', fill: '#fff', r: 5 }}
                        activeDot={{ stroke: '#8884d8', strokeWidth: 2, r: 8 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              )}
            </div>
          </div>

          <div className='w-[30%] bg-[#dfdfdf7d] h-[95%] rounded-lg shadow-md'>
            <div className='w-[100%] h-[80%]'>
              <div className='px-5 pt-5 font-bold text-2xl mb-4'>Reports</div>
              <div className='border border-[#00000050] h-[100%] rounded-lg p-4 mx-5 mt-1'>
                <p className=' font-semibold text-md'>Sales Report</p>
                <div className='mt-6'>
                  <Report />
                </div>
              </div>
            </div>
          </div>
        </div>

        

        <div className="flex w-full overflow-y-auto space-x-5 h-[50%]">
          <div className='w-[40%] bg-[#dfdfdf7d] h-[95%] rounded-lg shadow-md'>
            <div className=''>
              <div className='px-5 pt-5 mb-4 font-bold text-2xl'>Sales by Category</div>
              <div className="flex justify-center space-x-4 mb-5">
                <button
                  className={`px-4 py-2 rounded text-white ${timeRange === 'year' ? 'bg-blue-500' : 'bg-[#000000b1] hover:bg-[#00000092]'
                    }`}
                  onClick={() => setTimeRange('year')}
                >
                  Year
                </button>
                <button
                  className={`px-4 py-2 rounded text-white ${timeRange === 'month' ? 'bg-blue-500' : 'bg-[#000000b1] hover:bg-[#00000092]'
                    }`}
                  onClick={() => setTimeRange('month')}
                >
                  Month
                </button>
                <button
                  className={`px-4 py-2 rounded text-white ${timeRange === 'week' ? 'bg-blue-500' : 'bg-[#000000b1] hover:bg-[#00000092]'
                    }`}
                  onClick={() => setTimeRange('week')}
                >
                  Week
                </button>
              </div>
              {loading ? (
                <div>Loading...</div>
              ) : (
                <div className='px-4 sm:px-6 lg:px-10'>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={barsalesData}
                      margin={{ top: 10, right: 30, left: 50, bottom: 40 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="categoryName" label={{ value: 'Category', position: 'insideBottom', offset: -15, style: { fill: 'black' } }} />
                      <YAxis
                        label={{
                          value: 'Total Revenue (LKR)',
                          angle: -90,
                          position: 'insideLeft',
                          dx: -40, // Adjust horizontal position
                          dy: 70,   // Adjust vertical position
                          style: { fill: 'black' }
                        }}
                      />
                      <Tooltip
                        contentStyle={{ backgroundColor: '#f0f0f0', border: '1px solid #ccc' }}
                        labelStyle={{ color: '#333', fontWeight: 'bold' }}
                        formatter={(value) => `LKR ${value.toLocaleString()}`} // Format value with commas
                      />
                      <Bar dataKey="totalRevenue" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              )}
            </div>
          </div>
          <div className='w-[60%] bg-[#dfdfdf7d] h-[95%] rounded-lg shadow-md'>
            <div className=''>
              <div className='px-5 pt-5 font-bold text-2xl'>Top Selling Products</div>
              <div className="flex justify-end space-x-4 mb-5 -mt-8 mr-5">
                <button
                  className={`px-4 py-2 rounded text-white ${timeRang === 'year' ? 'bg-blue-500' : 'bg-[#000000b1] hover:bg-[#00000092]'
                    }`}
                  onClick={() => setTimeRang('year')}
                >
                  Year
                </button>
                <button
                  className={`px-4 py-2 rounded text-white ${timeRang === 'month' ? 'bg-blue-500' : 'bg-[#000000b1] hover:bg-[#00000092]'
                    }`}
                  onClick={() => setTimeRang('month')}
                >
                  Month
                </button>
                <button
                  className={`px-4 py-2 rounded text-white ${timeRang === 'week' ? 'bg-blue-500' : 'bg-[#000000b1] hover:bg-[#00000092]'
                    }`}
                  onClick={() => setTimeRang('week')}
                >
                  Week
                </button>
              </div>
              {loading ? (
                <div>Loading...</div>
              ) : (
                <div className='px-2'>
                  <div className='flex justify-between px-4 my-4'>
                    <div className='w-[20%]'><b>Item Code</b></div>
                    <div className='w-[30%]'><b>Product Name</b></div>
                    <div className='w-[25%]'><b>Category Name</b></div>
                    <div className='w-[20%] flex justify-center'><b>Sales Quantity</b></div>
                    <div className='w-[25%] flex justify-end'><b>Sales Revenue</b></div>
                  </div>
                  {topsell.map((item, index) => (
                    <ItemCard
                      key={index} // Make sure to provide a unique key for each ItemCard
                      category={item.CategoryName}
                      itemCode={item.ItemCode}
                      productName={item.ProductName}
                      totalQuantity={item.TotalQuantity}
                      totalRevenue={item.TotalRevenue}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;


