import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const Welcome = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userID, setUserID] = useState(null);
  const [Token, setToken] = useState(null);
  const navigate = useNavigate();

  //extract useid from token
  useEffect(() => {
    const storedData = localStorage.getItem('token');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const token = parsedData.token;
      setToken(token);

      if (token) {
        const decodedToken = jwtDecode(token);
        setUserID(decodedToken.userId);
      }
    }
  },[]);

  //change password logic
  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Passwords do not match',
        text: 'Please make sure the passwords match.',
      });
      return;
    }

    try {
      const response = await fetch(`https://ceramic-back.achila.tech/api/user/changepass/${userID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': Token,
        },
        body: JSON.stringify({ newPassword }),
      });

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Password Changed Successfully',
          text: 'Your password has been changed. Please log in with your new password.',
        }).then(() => {
          localStorage.removeItem('token');
          navigate('/login');
        });
      } else {
        throw new Error('Failed to change password');
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'There was an error changing your password. Please try again.',
      });
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-8 bg-white rounded shadow-md">
        <h1 className="text-2xl font-bold text-center">Welcome to Ramitha Ceramic</h1>
        <p className="text-center">Please create a new password to continue.</p>
        <form onSubmit={handlePasswordChange} className="space-y-6">
          <div>
            <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">New Password</label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              className="block w-full px-3 py-2 mt-1 text-gray-900 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="block w-full px-3 py-2 mt-1 text-gray-900 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <button
            type="submit"
            className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Create New Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default Welcome;
