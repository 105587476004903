import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Select, MenuItem, FormControl, TextField, Pagination, InputAdornment } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Swal from 'sweetalert2'
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { jwtDecode } from 'jwt-decode';


function createData(id, firstName, lastName, email, phoneNumber, address, nic) {
  return {
    id,
    firstName,
    lastName,
    email,
    phoneNumber,
    address,
    nic
  };
}

const storedData = localStorage.getItem('token');
let token;

if (storedData) {
  const parsedData = JSON.parse(storedData);
  token = parsedData.token;
} else {// Handle the case where the token is not stored
  token = null;
}


function Row({ row, isOpen, onExpand }) {
  const [errors, setErrors] = useState({});
  const [userRole, setuserRole] = useState();
  const [customerData, setCustomerData] = useState({
    FirstName: row.firstName,
    LastName: row.lastName,
    NIC: row.nic,
    Email: row.email,
    PhoneNumber: row.phoneNumber,
    Address: row.address
  });

  const checkUserRole = async () => {
    const storedData = localStorage.getItem('token');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const token = parsedData.token;
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken) {
          setuserRole(decodedToken.role);
        }
      } catch (error) {
        setuserRole(null);
      }
    } else {
      setuserRole(null);
    }
    //setIsLoading(false); // Set loading state to false once authentication check is done
  };

  useEffect(() => {
    checkUserRole();
  }, []);


  useEffect(() => { //when the expand is open do these things
    setCustomerData({
      FirstName: row.firstName,
      LastName: row.lastName,
      NIC: row.nic,
      Email: row.email,
      PhoneNumber: row.phoneNumber,
      Address: row.address
    });
    setErrors({});
  }, [row, isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === 'FirstName' || name === 'LastName') {
      newValue = newValue.slice(0, 20); // Limit to 20 characters for Address
      newValue = newValue.replace(/[0-9]/g, ''); // Allow only alphabets
    } else if (name === 'PhoneNumber') {
      newValue = newValue.slice(0, 10);
      newValue = newValue.replace(/[^0-9]/g, ''); // Allow only numbers
    } else if (name === 'Address') {
      newValue = newValue.slice(0, 100); // Limit to 100 characters for Address
    } else if (name === 'Email') {
      newValue = newValue.slice(0, 50); // Limit to 50 characters for Email
    } else if (name === 'NIC') {
      newValue = newValue.slice(0, 12); // Limit to 50 characters for Email
    }
    setCustomerData({ ...customerData, [name]: newValue });
    setErrors({ ...errors, [name]: '' }); // Clear the error when input changes
  };

  const validateForm = () => {
    const errors = {};
    if (!customerData.FirstName.trim()) {
      errors.FirstName = 'First Name is required';
    }
    if (!customerData.LastName.trim()) {
      errors.LastName = 'Last Name is required';
    }
    if (!customerData.NIC.trim()) {
      errors.NIC = 'NIC is required';
    } else if (!/^\d{9}[vVxX]?$/.test(customerData.NIC) && !/^\d{12}$/.test(customerData.NIC)) {
      errors.NIC = 'Invalid NIC format';
    }
    if (!customerData.Email) {
      customerData.Email = "";
    } else if (!/\S+@\S+\.\S+/.test(customerData.Email)) {
      errors.Email = 'Invalid email address';
    }
    if (!customerData.PhoneNumber.trim()) {
      errors.PhoneNumber = 'Phone Number is required';
    } else if (!/^0\d{9}$/.test(customerData.PhoneNumber)) {
      errors.PhoneNumber = 'Invalid phone number';
    }
    if (!customerData.Address.trim()) {
      errors.Address = 'Address is required';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0; // Return true if there are no errors
  };

  const handleUpdate = async () => {
    if (validateForm()) {
      try {
        if (userRole === 'Admin' || userRole === 'Manager') {
          const confirmed = await Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: 'You are about to update this customer. This action cannot be undone.',
            showCancelButton: true,
            confirmButtonText: 'Yes, update it!',
            cancelButtonText: 'Cancel',
          });

          if (confirmed.isConfirmed) {
            const response = await fetch(`https://ceramic-back.achila.tech/api/customer/update/${row.id}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'authorization': token,
              },
              body: JSON.stringify(customerData),
            });

            if (response.ok) {
              // User updated successfully
              const data = await response.json();
              const customerId = data.CustomerId;
              console.log('Cusotmer Updated:', customerId);
              Swal.fire({
                icon: 'success',
                title: 'Customer Updated Successfully!',
                customClass: {
                  popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                },
                didOpen: () => {
                  document.querySelector('.swal2-container').style.zIndex = '9999'; 
                }
              }).then(() => {
                window.location.reload(); // Reload the page after successful deletion
              });
            } else if (response.status === 400) {// Error due to existing fields
              const { errors } = await response.json();
              // Handle specific error cases
              if (errors.Username) {
                Swal.fire({
                  icon: 'error',
                  title: 'Customer Updating Failed',
                  text: errors.Username,
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  }
                });
              } else if (errors.Email) {
                Swal.fire({
                  icon: 'error',
                  title: 'Customer Updating Failed',
                  text: errors.Email,
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  }
                });
              } else if (errors.NIC) {
                Swal.fire({
                  icon: 'error',
                  title: 'Customer Updating Failed',
                  text: errors.NIC,
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  }
                });
              } else if (errors.PhoneNumber) {
                Swal.fire({
                  icon: 'error',
                  title: 'Customer Updating Failed',
                  text: errors.PhoneNumber,
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  }
                });
              } else {
                // Handle other validation errors
                Swal.fire({
                  icon: 'error',
                  title: 'Customer Updating Failed',
                  text: '',
                  html: Object.values(errors).map(error => `<div>${error}</div>`).join(''),
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  }
                });
              }
            } else {
              // Other server-side error
              console.error('Failed to Update customer');
              Swal.fire({
                icon: 'error',
                title: 'Customer Updating Failed',
                text: 'An error occurred while Updating the customer.',
                customClass: {
                  popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                },
                didOpen: () => {
                  document.querySelector('.swal2-container').style.zIndex = '9999'; 
                }
              });
            }
          }
        } else if (userRole === 'Showroom Staff') {
          const { value: formValues } = await Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: 'You are about to update this customer. This action cannot be undone.',
            showCancelButton: true,
            confirmButtonText: 'Yes, update it!',
            cancelButtonText: 'Cancel',
            html: `
              <input type="text" id="swal-username" class="swal2-input" placeholder="Username">
              <input type="password" id="swal-password" class="swal2-input" placeholder="Password">
            `,
            focusConfirm: false,
            preConfirm: () => {
              const username = document.getElementById('swal-username').value;
              const password = document.getElementById('swal-password').value;

              if (!username || !password) {
                Swal.showValidationMessage('Please enter both username and password');
                return false;
              }

              return {
                username,
                password
              };
            }
          });

          if (formValues) {
            // Call the authentication endpoint
            const authResponse = await fetch('https://ceramic-back.achila.tech/api/auth/managerlogin', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'authorization': token
              },
              body: JSON.stringify({
                username: formValues.username,
                password: formValues.password,
                warehouse: '2'
              })
            });

            const authResult = await authResponse.json();

            if (authResponse.ok && authResult.success) {
              // If authentication is successful, update the customer
              const response = await fetch(`https://ceramic-back.achila.tech/api/customer/update/${row.id}`, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                  'authorization': token
                },
                body: JSON.stringify(customerData)
              });

              if (response.ok) {
                // User updated successfully
                const data = await response.json();
                const customerId = data.CustomerId;
                console.log('Cusotmer Updated:', customerId);
                Swal.fire({
                  icon: 'success',
                  title: 'Customer Updated Successfully!',
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  }
                }).then(() => {
                  window.location.reload(); // Reload the page after successful deletion
                });
              } else if (response.status === 400) {// Error due to existing fields
                const { errors } = await response.json();
                // Handle specific error cases
                if (errors.Username) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Customer Updating Failed',
                    text: errors.Username,
                    customClass: {
                      popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                    },
                    didOpen: () => {
                      document.querySelector('.swal2-container').style.zIndex = '9999'; 
                    }
                  });
                } else if (errors.Email) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Customer Updating Failed',
                    text: errors.Email,
                    customClass: {
                      popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                    },
                    didOpen: () => {
                      document.querySelector('.swal2-container').style.zIndex = '9999'; 
                    }
                  });
                } else if (errors.NIC) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Customer Updating Failed',
                    text: errors.NIC,
                    customClass: {
                      popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                    },
                    didOpen: () => {
                      document.querySelector('.swal2-container').style.zIndex = '9999'; 
                    }
                  });
                } else if (errors.PhoneNumber) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Customer Updating Failed',
                    text: errors.PhoneNumber,
                    customClass: {
                      popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                    },
                    didOpen: () => {
                      document.querySelector('.swal2-container').style.zIndex = '9999'; 
                    }
                  });
                } else {
                  // Handle other validation errors
                  Swal.fire({
                    icon: 'error',
                    title: 'Customer Updating Failed',
                    text: '',
                    html: Object.values(errors).map(error => `<div>${error}</div>`).join(''),
                    customClass: {
                      popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                    },
                    didOpen: () => {
                      document.querySelector('.swal2-container').style.zIndex = '9999'; 
                    }
                  });
                }
              } else {
                // Other server-side error
                console.error('Failed to Update customer');
                Swal.fire({
                  icon: 'error',
                  title: 'Customer Updating Failed',
                  text: 'An error occurred while Updating the customer.',
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  }
                });
              }
            } else {
              if (authResponse.status === 401) {
                // If status is 401, set a specific error message
                Swal.fire({
                  icon: 'error',
                  title: 'Failed to Delete Customer',
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  },
                  text: 'Invalid username or password',
                });
              } else {
                // If authentication fails, show an error message
                Swal.fire('Error!', 'Username or password incorrect.', 'error');
              }
            }
          }
        }
      } catch (error) {
        // Handle network or unexpected errors
        console.error('Error Updating customer:', error);
        let errorMessage = 'An error occurred while Updating the customer.';
        Swal.fire({
          icon: 'error',
          title: 'Customer Updating Failed',
          text: errorMessage,
          customClass: {
            popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
          },
          didOpen: () => {
            document.querySelector('.swal2-container').style.zIndex = '9999'; 
          }
        });
      }
    }
  };

  const handleDelete = async () => {
    try {
      if (userRole === 'Admin' || userRole === 'Manager') {
        const confirmed = await Swal.fire({
          icon: 'warning',
          title: 'Are you sure?',
          text: 'You are about to delete this customer. This action cannot be undone.',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'Cancel',

        });

        if (confirmed.isConfirmed) {
          const response = await fetch(`https://ceramic-back.achila.tech/api/customer/delete/${row.id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'authorization': token,
            },
          });

          if (response.ok) {
            // User deleted successfully
            const data = await response.json();
            const customerId = data.CustomerId;
            console.log('Customer Deleted:', customerId);
            Swal.fire({
              icon: 'success',
              title: 'Customer Deleted Successfully!',
              customClass: {
                popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
              },
              didOpen: () => {
                document.querySelector('.swal2-container').style.zIndex = '9999'; 
              }
            }).then(() => {
              window.location.reload(); // Reload the page after successful deletion
            });
          }
        } else {
          Swal.close();
        }
      } else if (userRole === 'Showroom Staff') {
        const { value: formValues } = await Swal.fire({
          icon: 'warning',
          title: 'Are you sure?',
          text: 'You are about to update this customer. This action cannot be undone.',
          showCancelButton: true,
          confirmButtonText: 'Yes, update it!',
          cancelButtonText: 'Cancel',
          html: `
          <input type="text" id="swal-username" class="swal2-input" placeholder="Username">
          <input type="password" id="swal-password" class="swal2-input" placeholder="Password">
          `,
          focusConfirm: false,
          preConfirm: () => {
            const username = document.getElementById('swal-username').value;
            const password = document.getElementById('swal-password').value;

            if (!username || !password) {
              Swal.showValidationMessage('Please enter both username and password');
              return false;
            }

            return {
              username,
              password
            };
          }
        });

        if (formValues) {
          // Call the authentication endpoint
          const authResponse = await fetch('https://ceramic-back.achila.tech/api/auth/managerlogin', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'authorization': token
            },
            body: JSON.stringify({
              username: formValues.username,
              password: formValues.password,
              warehouse: '2'
            })
          });

          const authResult = await authResponse.json();

          if (authResponse.ok && authResult.success) {
            const response = await fetch(`https://ceramic-back.achila.tech/api/customer/delete/${row.id}`, {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
                'authorization': token,
              },
            });

            if (response.ok) {
              // User deleted successfully
              const data = await response.json();
              const customerId = data.CustomerId;
              console.log('Customer Deleted:', customerId);
              Swal.fire({
                icon: 'success',
                title: 'Customer Deleted Successfully!',
                customClass: {
                  popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                },
                didOpen: () => {
                  document.querySelector('.swal2-container').style.zIndex = '9999'; 
                }
              }).then(() => {
                window.location.reload(); // Reload the page after successful deletion
              });
            }
          } else {
            if (authResponse.status === 401) {
              // If status is 401, set a specific error message
              Swal.fire({
                icon: 'error',
                title: 'Failed to Delete Customer',
                customClass: {
                  popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                },
                didOpen: () => {
                  document.querySelector('.swal2-container').style.zIndex = '9999'; 
                },
                text: 'Invalid username or password',
              });
            } else {
              // If authentication fails, show an error message
              Swal.fire('Error!', 'Username or password incorrect.', 'error');
            }
          }
        }
      }
    } catch (error) {
      // Handle network or unexpected errors
      console.error('Error Deleting user:', error);
      let errorMessage = 'An error occurred while Deleting the customer.';
      Swal.fire({
        icon: 'error',
        title: 'Customer Deleting Failed',
        text: errorMessage,
        customClass: {
          popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
        },
        didOpen: () => {
          document.querySelector('.swal2-container').style.zIndex = '9999'; 
        }
      });
    }
  };

  return (
    <React.Fragment>
      {!isOpen && (
        <TableRow>
          <TableCell component="th" scope="row" align="left">
            {row.id}
          </TableCell>
          <TableCell align="left">{row.firstName}</TableCell>
          <TableCell align="left">{row.lastName}</TableCell>
          <TableCell align="left">{row.phoneNumber}</TableCell>
          <TableCell align="left">{row.address}</TableCell>
          <TableCell align="left">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => onExpand(row.id)}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      )}
      {isOpen && (
        <TableRow>
          <TableCell colSpan={9}>
            <div className="border border-gray-300 py-4 px-4 rounded-lg flex flex-col bg-[#ffffff]">
              <div className=' flex justify-end'>
                <IconButton
                  aria-label="collapse row"
                  size="small"
                  onClick={() => onExpand(null)}
                >
                  <KeyboardArrowUpIcon />
                </IconButton>
              </div>
              <div className='px-[70px] pt-12 pb-6'>
                <div className="flex justify-between mb-10">
                  <TextField variant="standard" label="UserID" defaultValue={row.id} style={{ width: '75px' }} InputProps={{ readOnly: true, disableUnderline: true }} />
                  <TextField variant="standard" label="First Name" value={customerData.FirstName} name="FirstName" onChange={handleChange} error={Boolean(errors.FirstName)} helperText={errors.FirstName} />
                  <TextField variant="standard" label="Last Name" value={customerData.LastName} name="LastName" onChange={handleChange} error={Boolean(errors.LastName)} helperText={errors.LastName} />
                  <TextField variant="standard" label="NIC" value={customerData.NIC} style={{ width: '120px' }} name="NIC" onChange={handleChange} error={Boolean(errors.NIC)} helperText={errors.NIC} />
                  <TextField variant="standard" label="Phone Number" value={customerData.PhoneNumber} style={{ width: '120px' }} name="PhoneNumber" onChange={handleChange} error={Boolean(errors.PhoneNumber)} helperText={errors.PhoneNumber} />
                </div>
                <div className="flex justify-between mb-8">
                  <TextField variant="standard" label="Email" value={customerData.Email} style={{ width: '25%' }} name="Email" onChange={handleChange} error={Boolean(errors.Email)} helperText={errors.Email} />
                  <TextField variant="standard" label="Address" value={customerData.Address} style={{ width: '50%' }} name="Address" onChange={handleChange} error={Boolean(errors.Address)} helperText={errors.Address} />
                </div>
                <div className="flex justify-end gap-4">
                  <Button variant="contained" color="secondary" onClick={handleDelete} style={{ backgroundColor: '#F05756', width: '8%' }}>Delete</Button>
                  <Button variant="contained" color="primary" onClick={handleUpdate} style={{ backgroundColor: '#2D865B', width: '8%' }}>Update</Button>
                </div>
              </div>
            </div>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string,
    phoneNumber: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    nic: PropTypes.string.isRequired
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
};

export default function CollapsibleTable() {
  const [expandedRow, setExpandedRow] = useState(null);
  const [customerData, setCustomerData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1); // Reset page to 1 whenever rows per page changes
  };

  useEffect(() => {
    const filteredItems = customerData.filter(customer => {
      const id = customer.id.toString().toLowerCase();
      const Name = customer.firstName?.toString().toLowerCase() || '';
      const pn = customer.phoneNumber?.toString().toLowerCase() || '';
      const search = searchQuery.toLowerCase();
      const matchesSearchQuery = Name.includes(search) || Name === search || id.includes(search) || id === search || pn.includes(search) || pn === search;
      return matchesSearchQuery;
    });

    setFilteredItems(filteredItems);
  }, [searchQuery, customerData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://ceramic-back.achila.tech/api/customer/getcustomer', {
          headers: {
            'authorization': token,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const customerData = data.customers; // Access the users property
        if (Array.isArray(customerData)) {
          const transformedData = customerData.map(customer => createData(
            customer.CustomerID,
            customer.FirstName,
            customer.LastName,
            customer.Email,
            customer.PhoneNumber,
            customer.Address,
            customer.NIC,
          ));
          setCustomerData(transformedData);
        } else {
          throw new Error('Data received is not in the expected format');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, []);

  const handleRowExpand = (rowId) => {
    if (expandedRow === rowId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowId);
    }
  };

  return (
    <div>
      <div className='mb-5 w-[25%]'>
        <TextField
          fullWidth
          label="Search Name or ID or Phone Number"
          value={searchQuery}
          onChange={(e) => {
            const value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
            setSearchQuery(value);
          }}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchQuery && (
              <IconButton onClick={() => setSearchQuery('')}>
                <ClearIcon />
              </IconButton>
            ),
          }}
        />
      </div>
      <div className='mb-4 flex items-end justify-end'>
        <div className='items-center flex mr-5'>
          <div><span style={{ color: '#4E4E4E' }}>Rows per page: &nbsp;</span></div>
          <FormControl sx={{ minWidth: 60 }} size="small">
            <Select
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
            >
              {[10, 20, 50].map((rows) => (
                <MenuItem key={rows} value={rows}>
                  {rows}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Pagination
          count={Math.ceil(filteredItems.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          style={{ marginTop: '1rem' }}
        />
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow style={{ borderBottom: '2px solid black', background: '#F7F7F7' }}>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>Customer ID</TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>First Name</TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>Last Name</TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>Phone Number</TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>Address</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ background: '#F7F7F7' }}>
            {filteredItems.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((customer) => (
              <React.Fragment key={customer.id}>
                <Row
                  row={customer}
                  isOpen={expandedRow === customer.id}
                  onExpand={handleRowExpand}
                />
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
