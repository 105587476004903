import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton, Collapse, Box, TextField, MenuItem, Slider, InputAdornment, Button } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ClearIcon from '@mui/icons-material/Clear';
import Pagination from '@mui/material/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { jwtDecode } from 'jwt-decode';
import Swal from 'sweetalert2';


const OrderTable = ({ orderStatusFilter }) => {
  const [orderDetails, setOrderDetails] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [searchQuery, setSearchQuery] = useState('');
  const [FilteredOrders, setFilteredOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default to 10 items per page
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [amountRange, setAmountRange] = useState([0, 9999999]);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(9999999);
  const [token, setToken] = useState(null);
  const [userRole, setuserRole] = useState(null);
  const [warehouse, setwarehouse] = useState(null);


  useEffect(() => {
    const storedData = localStorage.getItem('token');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setToken(parsedData.token);
      try {
        const decodedToken = jwtDecode(parsedData.token);
        if (decodedToken) {
          setuserRole(decodedToken.role);
          if (decodedToken.role === 'Warehouse Staff' || decodedToken.role === 'Manager') {
            setwarehouse(decodedToken.WarehouseName);
          }
        }
      } catch (error) {
        setwarehouse(null);
      }
    }

    fetch('https://ceramic-back.achila.tech/api/order/orderDetails', {
      headers: {
        'Authorization': token, // Assuming token is a variable holding your authorization token
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        const formattedData = data.map(order => ({
          ...order,
          id: order.OrderID,
          OrderDate: new Date(order.OrderDate).toLocaleString(),
          TransactionAmount: order.TransactionAmount ? parseFloat(order.TransactionAmount).toFixed(2) : '0.00',
          items: order.items.map(item => ({
            ...item,
            unitPrice: item.unitPrice ? parseFloat(item.unitPrice).toFixed(2) : '0.00',
            discount: item.discount ? parseFloat(item.discount).toFixed(2) : '0.00'
          }))
        }));
        setOrderDetails(formattedData);
      })
      .catch(error => {
        console.error('Error fetching order details:', error);
      });
  }, [token]);

  const handleRowClick = (rowId) => {
    if (expandedRow === rowId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowId);
    }
  };

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    // Apply sorting to orderDetails array
    const sortedOrders = [...orderDetails].sort((a, b) => {
      if (key === 'TransactionAmount') {
        return direction === 'asc' ? a[key] - b[key] : b[key] - a[key];
      } else {
        return direction === 'asc' ? a[key] < b[key] ? -1 : 1 : a[key] > b[key] ? -1 : 1;
      }
    });

    setOrderDetails(sortedOrders);
  };



  const sortedItems = (items, key, direction) => {
    const sorted = [...items].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    return sorted;
  };

  useEffect(() => {
    const filteredOrders = orderDetails.filter(order => {
      const orderId = order.OrderID.toString().toLowerCase();
      const Customerpn = (order.PhoneNumber?.toString().toLowerCase()) ?? "";
      const search = searchQuery.toLowerCase();
      const orderDateString = order.OrderDate.replace(/,/g, ''); // remove the comma
      const orderDateParts = orderDateString.split('/'); // split the string into parts
      const orderDate = new Date(
        parseInt(orderDateParts[2], 10), // year
        parseInt(orderDateParts[1], 10) - 1, // month (zero-indexed)
        parseInt(orderDateParts[0], 10), // day
        order.OrderTime ? parseInt(order.OrderTime.split(':')[0], 10) : 0, // hours
        order.OrderTime ? parseInt(order.OrderTime.split(':')[1], 10) : 0, // minutes
        order.OrderTime ? parseInt(order.OrderTime.split(':')[2], 10) : 0  // seconds
      );

      // Check if the order matches the search query
      const matchesSearchQuery = orderId.includes(search) || orderId === search || Customerpn.includes(search) || Customerpn === search;

      // Check if the order date is within the selected date range or if no date range is selected
      const matchesDateRange = (!startDate && !endDate) ||
        (startDate && !endDate && orderDate >= startDate) ||
        (!startDate && endDate && orderDate <= endDate) ||
        (startDate && endDate && orderDate >= startDate && orderDate <= endDate);

      // Check if the order amount is within the selected amount range
      const matchesAmountRange = order.TransactionAmount >= minValue && order.TransactionAmount <= maxValue;
      const matchesStatusFilter = !orderStatusFilter || order.OrderStatus === orderStatusFilter;


      // Return true if all conditions are met
      return matchesSearchQuery && matchesDateRange && matchesAmountRange && matchesStatusFilter;
    });

    setFilteredOrders(filteredOrders);
  }, [searchQuery, orderDetails, startDate, endDate, minValue, maxValue]);


  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeItemsPerPage = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1); // Reset to the first page when changing items per page
  };

  // Calculate pagination range
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = FilteredOrders.slice(indexOfFirstItem, indexOfLastItem);

  const handleFilterClick = () => {
    setIsOpen(!isOpen);
  };

  const handleAmountChange = (event, newValue) => {
    setAmountRange(newValue);
    setMinValue(newValue[0]);
    setMaxValue(newValue[1]);
  };

  const handleMinInputChange = (event) => {
    let value = parseInt(event.target.value);
    if (isNaN(value) || value < 0) {
      value = 0;
    }
    if (value <= maxValue) {
      setMinValue(value);
      setAmountRange([value, maxValue]);
    }
  };

  const handleMaxInputChange = (event) => {
    let value = parseInt(event.target.value);
    if (isNaN(value) || value < minValue) {
      value = minValue;
    }
    if (value <= 9999999) {
      setMaxValue(value);
      setAmountRange([minValue, value]);
    }
  };

  const handleResetFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setAmountRange([0, 9999999]);
    setMinValue(0);
    setMaxValue(9999999);
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleCompleteRequest = (orderId) => {

    Swal.fire({
      title: 'Are you sure?',
      text: 'This action will mark the order as complete.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, complete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.isConfirmed) {
        // Make a POST request to your endpoint
        fetch('https://ceramic-back.achila.tech/api/order/complete', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'authorization': token,
          },
          body: JSON.stringify({ orderId }),
        })
          .then(response => {
            // Check if the request was successful
            if (!response.ok) {
              throw new Error('Failed to complete Order');
            }
            // Handle success response
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: 'Order Complete successfully',
            }).then(() => {
              window.location.reload();
            });
          })
          .catch(error => {
            // Handle error
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Error complete order: ' + error.message,
            });
          });
      }
    });
  };


  return (
    <div className="flex ">
      <div className="flex w-full ">
        <div className='flex-col w-full'>
          <div className='mb-5 mt-10 px-10'>
            <TextField
              label="Search Order ID or Customer Phone Number"
              value={searchQuery}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, '').substring(0, 10);
                setSearchQuery(value);
              }}
              variant="outlined"
              style={{ width: "23%" }}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*', // Only allows numbers
                maxLength: 10, // Limit to 6 characters
              }}
              InputProps={{
                endAdornment: searchQuery && (
                  <IconButton onClick={() => setSearchQuery('')}>
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
          </div>
          <div className='flex-col px-10'>
            <div>
              <button
                onClick={handleFilterClick}
                className="bg-[#000000] hover:bg-[#000000c1] text-white font-bold py-2 px-4 rounded-lg inline-flex items-center"
              >
                <FontAwesomeIcon icon={faFilter} className="mr-2" />
                Filter
              </button>
            </div>
            {isOpen && (
              <div className="bg-gray-200 p-5 rounded-lg inline-block  relative">
                <div className="gap-2 flex items-center">
                  <p>Date Range:</p>
                  <div className=''>
                    <DatePicker
                      className="border border-gray-300 rounded px-2 py-1 focus:outline-none focus:border-blue-500"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText="Start Date"
                    />
                  </div>
                  <div className=''>
                    <DatePicker
                      className="border border-gray-300 rounded px-2 py-1 focus:outline-none focus:border-blue-500"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText="End Date"
                    />
                  </div>
                </div>
                <div className="mt-5">
                  <Typography id="range-slider" gutterBottom>
                    Transaction Amount
                  </Typography>
                  <Box width={400} className="mx-auto">
                    <Slider
                      value={amountRange}
                      onChange={handleAmountChange}
                      valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      min={0}
                      max={9999999}
                      step={100000}
                    />
                  </Box>
                  <div className="flex justify-between mt-4">
                    <TextField
                      label="Min"
                      type="number"
                      value={minValue}
                      onChange={handleMinInputChange}
                      variant="outlined"
                      size="small"
                      className="w-[150px]"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">LKR</InputAdornment>,
                        inputProps: { min: 0, max: maxValue }
                      }}
                    />
                    <TextField
                      label="Max"
                      type="number"
                      value={maxValue}
                      onChange={handleMaxInputChange}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">LKR</InputAdornment>,
                        inputProps: { min: minValue, max: 9999999 }
                      }}
                      variant="outlined"
                      size="small"
                      className="w-[150px]"
                    />
                  </div>
                  <button
                    onClick={handleResetFilters}
                    className="bg-[#af4242] text-white font-bold py-2 px-4 rounded inline-flex items-center mt-5"
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
                    Reset Filters
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className='mb-4 flex items-end justify-end px-10'>
            <div className='flex items-center justify-end mr-5'>
              <span style={{ color: '#4E4E4E' }}>Rows per page: </span>
              <TextField sx={{ minWidth: 60 }} size="small"
                select
                value={itemsPerPage}
                onChange={handleChangeItemsPerPage}
                variant="outlined"
                className="w-18 left-2"
              >
                {[10, 25, 50, 100].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="flex items-end justify-end">
              <Pagination
                count={Math.ceil(FilteredOrders.length / itemsPerPage)}
                page={currentPage}
                onChange={handleChangePage}
                variant="outlined"
                shape="rounded"
              />
            </div>
          </div>
          <div className='px-10 pb-10'>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell onClick={() => requestSort('OrderID')} className="cursor-pointer">
                      <b>Order ID</b>
                      {sortConfig.key === 'OrderID' && (
                        <span className="ml-1">
                          {sortConfig.direction === 'asc' ? (
                            <ArrowUpwardIcon />
                          ) : (
                            <ArrowDownwardIcon />
                          )}
                        </span>
                      )}
                    </TableCell>
                    <TableCell onClick={() => requestSort('OrderDate')} className="cursor-pointer">
                      <b>Order Date</b>
                      {sortConfig.key === 'OrderDate' && (
                        <span className="ml-1">
                          {sortConfig.direction === 'asc' ? (
                            <ArrowUpwardIcon />
                          ) : (
                            <ArrowDownwardIcon />
                          )}
                        </span>
                      )}
                    </TableCell>
                    {/* Repeat similar structure for other headers */}
                    <TableCell><b>Cashier Name</b></TableCell>
                    <TableCell><b>Customer Name</b></TableCell>
                    <TableCell onClick={() => requestSort('TransactionAmount')} className="cursor-pointer">
                      <b>Transaction Amount (LKR)</b>
                      {sortConfig.key === 'TransactionAmount' && (
                        <span className="ml-1">
                          {sortConfig.direction === 'asc' ? (
                            <ArrowUpwardIcon />
                          ) : (
                            <ArrowDownwardIcon />
                          )}
                        </span>
                      )}
                    </TableCell>
                    <TableCell><b>Order Status</b></TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentItems.map((order) => (
                    <React.Fragment key={order.id}>
                      <TableRow onClick={() => handleRowClick(order.id)}>
                        <TableCell>{order.OrderID}</TableCell>
                        <TableCell>{order.OrderDate}</TableCell>
                        <TableCell>{order.CashierName}</TableCell>
                        <TableCell>{order.FirstName}</TableCell>
                        <TableCell>{numberWithCommas(order.TransactionAmount)}</TableCell>
                        <TableCell>{order.OrderStatus}</TableCell>
                        <TableCell>
                          <IconButton aria-label="expand row" size="small" onClick={() => handleRowClick(order.id)}>
                            {expandedRow === order.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          {order.OrderStatus === 'Ordered' && ((userRole === 'Manager' && warehouse === 'Kandy') || (userRole === 'Warehouse Staff' && warehouse === 'Kandy')) &&
                            <Button variant="contained" color="success" style={{ backgroundColor: '#003f63', width: '70%' }} onClick={() => handleCompleteRequest(order.OrderID)}>Complete Order</Button>
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                          <Collapse in={expandedRow === order.id} timeout="auto" unmountOnExit>
                            <Box margin={1} style={{ backgroundColor: '#F0F0F0', padding: '10px', borderRadius: '10px' }}>
                              <Typography variant="h6" gutterBottom component="div">
                                Products
                              </Typography>
                              <Table size="small" aria-label="purchases">
                                <TableHead>
                                  <TableRow>
                                    <TableCell><b>Item Name</b></TableCell>
                                    <TableCell><b>Item Code</b></TableCell>
                                    <TableCell><b>Quantity</b></TableCell>
                                    <TableCell><b>Unit Price</b></TableCell>
                                    <TableCell><b>Discounts(Full Quantity)</b></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {sortedItems(order.items, sortConfig.key, sortConfig.direction).map((item, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{item.itemName}</TableCell>
                                      <TableCell>{item.itemCode}</TableCell>
                                      <TableCell>{item.quantity}</TableCell>
                                      <TableCell>{item.unitPrice}</TableCell>
                                      <TableCell>{item.discount}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div >
    </div>
  );
};

export default OrderTable;