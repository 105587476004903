import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Select, MenuItem, FormControl, TextField, Pagination } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Swal from 'sweetalert2';
import { jwtDecode } from 'jwt-decode';

const storedData = localStorage.getItem('token');
let token;

if (storedData) {
  const parsedData = JSON.parse(storedData);
  token = parsedData.token;
} else {// Handle the case where the token is not stored
  token = null;
}


function createData(id, name, description) {
  return {
    id,
    name,
    description
  };
}

function Row({ row, isOpen, onExpand }) {
  const [errors, setErrors] = useState({});
  const [userRole, setuserRole] = useState();
  const [warehouse, setwarehouse] = useState(null);
  const [categoryData, setCategoryData] = useState({
    Name: row.name,
    Description: row.description
  });

  const checkUserRole = async () => {
    try {
      const storedData = localStorage.getItem('token');
      let token;

      if (storedData) {
        const parsedData = JSON.parse(storedData);
        token = parsedData.token;
      } else {// Handle the case where the token is not stored
        token = null;
      }
      const decodedToken = jwtDecode(token);
      if (decodedToken) {
        setuserRole(decodedToken.role);
        if (decodedToken.role === 'Warehouse Staff') {
          setwarehouse(decodedToken.WarehouseID);
        }
      }
    } catch (error) {
      setuserRole(null);
    }
  };

  useEffect(() => {
    checkUserRole();
  }, []);

  useEffect(() => { //when the expand is open do these things
    setCategoryData({
      Name: row.name,
      Description: row.description
    });
    setErrors({});
  }, [row, isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === 'Name') {
      newValue = newValue.slice(0, 20); // Limit to 20 characters for Address
      newValue = newValue.replace(/[0-9]/g, ''); // Allow only alphabets
    } else if (name === 'Description') {
      newValue = newValue.slice(0, 50);
      newValue = newValue.replace(/[0-9]/g, ''); // Allow only alphabets
    }
    setCategoryData({ ...categoryData, [name]: newValue });
    setErrors({ ...errors, [name]: '' }); // Clear the error when input changes
  };

  const validateForm = () => {
    const errors = {};
    if (!categoryData.Name.trim()) {
      errors.Name = 'Category Name is required';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0; // Return true if there are no errors
  };

  const handleUpdate = async () => {
    if (validateForm()) {
      try {
        if (userRole === 'Admin' || userRole === 'Manager') {
          const confirmed = await Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: 'You are about to update this category. This action cannot be undone.',
            showCancelButton: true,
            confirmButtonText: 'Yes, update it!',
            cancelButtonText: 'Cancel',
          });

          if (confirmed.isConfirmed) {
            const response = await fetch(`https://ceramic-back.achila.tech/api/category/update/${row.id}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'authorization': token,
              },
              body: JSON.stringify(categoryData),
            });

            if (response.ok) {
              // category updated successfully
              const data = await response.json();
              const categoryId = data.CategoryId;
              console.log('Category Updated:', categoryId);
              Swal.fire({
                icon: 'success',
                title: 'Category Updated Successfully!',
                customClass: {
                  popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                },
                didOpen: () => {
                  document.querySelector('.swal2-container').style.zIndex = '9999'; 
                }
              }).then(() => {
                window.location.reload(); // Reload the page after successful deletion
              });
            } else if (response.status === 400) {// Error due to existing fields
              const { errors } = await response.json();
              // Handle specific error cases
              if (errors.Name) {
                Swal.fire({
                  icon: 'error',
                  title: 'Category Updating Failed',
                  text: errors.Name,
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  }
                });
              }
            } else {
              // Other server-side error
              console.error('Failed to Update category');
              Swal.fire({
                icon: 'error',
                title: 'category Updating Failed',
                text: 'An error occurred while Updating the category.',
                customClass: {
                  popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                },
                didOpen: () => {
                  document.querySelector('.swal2-container').style.zIndex = '9999'; 
                }
              });
            }
          }
        } else if (userRole === 'Warehouse Staff') {
          const { value: formValues } = await Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: 'You are about to update this customer. This action cannot be undone.',
            showCancelButton: true,
            confirmButtonText: 'Yes, update it!',
            cancelButtonText: 'Cancel',
            html: `
          <input type="text" id="swal-username" class="swal2-input" placeholder="Username">
          <input type="password" id="swal-password" class="swal2-input" placeholder="Password">
        `,
            focusConfirm: false,
            preConfirm: () => {
              const username = document.getElementById('swal-username').value;
              const password = document.getElementById('swal-password').value;

              if (!username || !password) {
                Swal.showValidationMessage('Please enter both username and password');
                return false;
              }

              return {
                username,
                password
              };
            }
          });

          if (formValues) {
            // Call the authentication endpoint to check managers credential
            const authResponse = await fetch('https://ceramic-back.achila.tech/api/auth/managerlogin', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'authorization': token
              },
              body: JSON.stringify({
                username: formValues.username,
                password: formValues.password,
                warehouse: warehouse
              })
            });

            const authResult = await authResponse.json();

            if (authResponse.ok && authResult.success) {
              const response = await fetch(`https://ceramic-back.achila.tech/api/category/update/${row.id}`, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                  'authorization': token,
                },
                body: JSON.stringify(categoryData),
              });

              if (response.ok) {
                // category updated successfully
                const data = await response.json();
                const categoryId = data.CategoryId;
                console.log('Category Updated:', categoryId);
                Swal.fire({
                  icon: 'success',
                  title: 'Category Updated Successfully!',
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  }
                }).then(() => {
                  window.location.reload(); // Reload the page after successful deletion
                });
              } else if (response.status === 400) {// Error due to existing fields
                const { errors } = await response.json();
                // Handle specific error cases
                if (errors.Name) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Category Updating Failed',
                    text: errors.Name,
                    customClass: {
                      popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                    },
                    didOpen: () => {
                      document.querySelector('.swal2-container').style.zIndex = '9999'; 
                    }
                  });
                }
              } else {
                // Other server-side error
                console.error('Failed to Update category');
                Swal.fire({
                  icon: 'error',
                  title: 'category Updating Failed',
                  text: 'An error occurred while Updating the category.',
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  }
                });
              }
            } else {
              if (authResponse.status === 401) {
                // If status is 401, set a specific error message
                Swal.fire({
                  icon: 'error',
                  title: 'Failed to Update Category',
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  },
                  text: 'Invalid username or password',
                });
              } else {
                Swal.fire('Error!', 'Username or password incorrect.', 'error');
              }
            }
          }
        }
      } catch (error) {
        // Handle network or unexpected errors
        console.error('Error Updating category:', error);
        let errorMessage = 'An error occurred while Updating the category.';
        Swal.fire({
          icon: 'error',
          title: 'category Updating Failed',
          text: errorMessage,
          customClass: {
            popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
          },
          didOpen: () => {
            document.querySelector('.swal2-container').style.zIndex = '9999'; 
          }
        });
      }
    }
  };

  const handleDelete = async () => {
    try {
      if (userRole === 'Admin' || userRole === 'Manager') {
        const confirmed = await Swal.fire({
          icon: 'warning',
          title: 'Are you sure?',
          text: 'You are about to delete this category. This action cannot be undone.',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'Cancel',

        });

        if (confirmed.isConfirmed) {
          const response = await fetch(`https://ceramic-back.achila.tech/api/category/delete/${row.id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'authorization': token,
            },
          });

          if (response.ok) {
            // category deleted successfully
            const data = await response.json();
            const categoryId = data.CategoryId;
            console.log('category Deleted:', categoryId);
            Swal.fire({
              icon: 'success',
              title: 'Category Deleted Successfully!',
              customClass: {
                popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
              },
              didOpen: () => {
                document.querySelector('.swal2-container').style.zIndex = '9999'; 
              }
            }).then(() => {
              window.location.reload(); // Reload the page after successful deletion
            });
          }
        } else {
          Swal.close();
        }
      } else if (userRole === 'Warehouse Staff') {
        const { value: formValues } = await Swal.fire({
          icon: 'warning',
          title: 'Are you sure?',
          text: 'You are about to update this customer. This action cannot be undone.',
          showCancelButton: true,
          confirmButtonText: 'Yes, update it!',
          cancelButtonText: 'Cancel',
          html: `
          <input type="text" id="swal-username" class="swal2-input" placeholder="Username">
          <input type="password" id="swal-password" class="swal2-input" placeholder="Password">
        `,
          focusConfirm: false,
          preConfirm: () => {
            const username = document.getElementById('swal-username').value;
            const password = document.getElementById('swal-password').value;

            if (!username || !password) {
              Swal.showValidationMessage('Please enter both username and password');
              return false;
            }

            return {
              username,
              password
            };
          }
        });

        if (formValues) {
          // Call the authentication endpoint
          const authResponse = await fetch('https://ceramic-back.achila.tech/api/auth/managerlogin', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'authorization': token
            },
            body: JSON.stringify({
              username: formValues.username,
              password: formValues.password,
              warehouse: warehouse
            })
          });

          const authResult = await authResponse.json();

          if (authResponse.ok && authResult.success) {
            const response = await fetch(`https://ceramic-back.achila.tech/api/category/delete/${row.id}`, {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
                'authorization': token,
              },
            });

            if (response.ok) {
              // category deleted successfully
              const data = await response.json();
              const categoryId = data.CategoryId;
              console.log('category Deleted:', categoryId);
              Swal.fire({
                icon: 'success',
                title: 'Category Deleted Successfully!',
                customClass: {
                  popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                },
                didOpen: () => {
                  document.querySelector('.swal2-container').style.zIndex = '9999'; 
                }
              }).then(() => {
                window.location.reload(); // Reload the page after successful deletion
              });
            }
          } else {
            if (authResponse.status === 401) {
              // If status is 401, set a specific error message
              Swal.fire({
                icon: 'error',
                title: 'Failed to Update Product',
                customClass: {
                  popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                },
                didOpen: () => {
                  document.querySelector('.swal2-container').style.zIndex = '9999'; 
                },
                text: 'Invalid username or password',
              });
            } else {
              // If authentication fails, show an error message
              Swal.fire('Error!', 'Username or password incorrect.', 'error');
            }
          }
        }
      }
    } catch (error) {
      // Handle network or unexpected errors
      console.error('Error Deleting category:', error);
      let errorMessage = 'An error occurred while Deleting the category.';
      Swal.fire({
        icon: 'error',
        title: 'category Deleting Failed',
        text: errorMessage,
        customClass: {
          popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
        },
        didOpen: () => {
          document.querySelector('.swal2-container').style.zIndex = '9999'; 
        }
      });
    }
  };

  return (
    <React.Fragment>
      {!isOpen && (
        <TableRow>
          <TableCell component="th" scope="row" align="left">
            {row.id}
          </TableCell>
          <TableCell align="left">{row.name}</TableCell>
          <TableCell align="left">{row.description}</TableCell>
          <TableCell align="left">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => onExpand(row.id)}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      )}
      {isOpen && (
        <TableRow>
          <TableCell colSpan={9}>
            <div className="border border-gray-300 py-4 px-4 rounded-lg flex flex-col bg-[#ffffff]">
              <div className=' flex justify-end'>
                <IconButton
                  aria-label="collapse row"
                  size="small"
                  onClick={() => onExpand(null)}
                >
                  <KeyboardArrowUpIcon />
                </IconButton>
              </div>
              <div className='px-[70px] pt-12 pb-6'>
                <div className="flex justify-between mb-10">
                  <TextField variant="standard" label="CategoryID" defaultValue={row.id} style={{ width: '75px' }} InputProps={{ readOnly: true, disableUnderline: true }} />
                  <TextField variant="standard" label="Name" value={categoryData.Name} style={{ width: '25%' }} name="Name" onChange={handleChange} error={Boolean(errors.Name)} helperText={errors.Name} />
                  <TextField variant="standard" label="Description" value={categoryData.Description} style={{ width: '50%' }} name="Description" onChange={handleChange} error={Boolean(errors.Description)} helperText={errors.Description} />
                </div>
                <div className="flex justify-end gap-4">
                  <Button variant="contained" color="secondary" onClick={handleDelete} style={{ backgroundColor: '#F05756', width: '8%' }}>Delete</Button>
                  <Button variant="contained" color="primary" onClick={handleUpdate} style={{ backgroundColor: '#2D865B', width: '8%' }}>Update</Button>
                </div>
              </div>
            </div>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
};

export default function CategoryTable() {
  const [expandedRow, setExpandedRow] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1); // Reset page to 1 whenever rows per page changes
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://ceramic-back.achila.tech/api/category/getcategory', {
          headers: {
            'authorization': token,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const CategoryData = data.categories; // Access the users property
        console.log('Response:', CategoryData);
        if (Array.isArray(CategoryData)) {
          const transformedData = CategoryData.map(category => createData(
            category.CategoryID,
            category.Name,
            category.Description,
          ));
          setCategoryData(transformedData);
        } else {
          throw new Error('Data received is not in the expected format');
        }
      } catch (error) {
        console.error('Error fetching category data:', error);
      }
    };

    fetchData();
  }, []);

  const handleRowExpand = (rowId) => {
    if (expandedRow === rowId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowId);
    }
  };

  return (
    <div>
      <div className='mb-4 flex items-end justify-end'>
        <div className='items-center flex mr-5'>
          <div><span style={{ color: '#4E4E4E' }}>Rows per page: &nbsp;</span></div>
          <FormControl sx={{ minWidth: 60 }} size="small">
            <Select
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
            >
              {[10, 20, 50].map((rows) => (
                <MenuItem key={rows} value={rows}>
                  {rows}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Pagination
          count={Math.ceil(categoryData.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          style={{ marginTop: '1rem' }}
        />
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow style={{ borderBottom: '2px solid black', background: '#F7F7F7' }}>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>Category ID</TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>Name</TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>Description</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ background: '#F7F7F7' }}>
            {categoryData.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((category) => (
              <React.Fragment key={category.id}>
                <Row
                  row={category}
                  isOpen={expandedRow === category.id}
                  onExpand={handleRowExpand}
                />
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
