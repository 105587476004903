import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dashicon from '../../Assets/icons/select/pos.png';
import logouticon from '../../Assets/icons/logout.png';
import usericon from '../../Assets/icons/user.png';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import { jwtDecode } from 'jwt-decode';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import SendAndArchiveOutlinedIcon from '@mui/icons-material/SendAndArchiveOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';

const AdminNavbar = ({ activeItem }) => {
  const [activeLink, setActiveLink] = useState(localStorage.getItem('activeLink') || activeItem);
  const [expanded, setExpanded] = useState(false); // State to track if navbar is expanded on hover
  const [userName, setUserName] = useState(null);
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setExpanded(true);
  };

  const handleMouseLeave = () => {
    setExpanded(false);
  };

  const handleLinkClick = (link) => (event) => {
    event.preventDefault(); // Prevent default navigation behavior
    setActiveLink(link); // Update active link
    localStorage.setItem('activeLink', link);
    if (link === 'logout') {
      localStorage.removeItem("token");
      localStorage.removeItem("activeLink");
    }
    const targetLink = link === 'logout' ? 'login' : link === 'dashboard' ? '' : link;
    navigate(targetLink === 'login' ? '/login' : `/admin-dashboard/${targetLink}`);
  };

  const checkUsername = async () => {
    const storedData = localStorage.getItem('token');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const token = parsedData.token;
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken) {
          setUserName(decodedToken.FirstName);
        }
      } catch (error) {
        setUserName(null);
      }
    } else {
      setUserName(null);
    }
  };

  useEffect(() => {
    checkUsername();
  }, []);


  return (
    <nav className='h-screen flex z-10 absolute'>
      <div className={`h-screen bg-white text-black ${expanded ? 'w-[180px] items-start pl-3' : 'w-20 items-center'} flex flex-col justify-between transition-all duration-500 ease-in-out`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <div>
          <div className='flex'>
            <img src={usericon} alt="User" className={`p-3 mt-8 w-20`} />
            <span className={`flex-grow transition-opacity p-3 mt-8 ${expanded ? 'duration-[400ms] opacity-100 max-w-full' : 'duration-0 opacity-0 max-w-0'}`}><p><b>Hi, {userName}</b></p>
            </span>
          </div>
          <div className={`p-3 mb-10`}></div>
          <div className={`flex flex-col ${expanded ? 'items-start overflow-y-auto' : 'items-center overflow-hidden'} h-[calc(100vh-300px)]`}>

            <div className='mb-5'>
              <button onClick={handleLinkClick('dashboard')} >
                <div className={`flex p-3 ${activeLink === "dashboard" ? 'bg-[#FFEEEF]' : 'hover:bg-[#FFEEEF]'} rounded-lg ${expanded ? 'w-[155px]' : 'w-[50px] border-[1px] border-transparent'} ${expanded && activeLink !== "dashboard" ? 'border-[1px] border-gray-200' : ''} transition-all duration-500 ease-in-out`}>
                  <img src={dashicon} alt="Dashboard" />
                  <span className={`flex-grow text-center transition-opacity ${expanded ? 'duration-[400ms] opacity-100 max-w-full' : 'duration-0 opacity-0 max-w-0'}`}>Dashboard</span>
                </div>
              </button>
            </div>

            <div className='mb-5'>
              <button onClick={handleLinkClick('inventory')}>
                <div className={`flex p-3 ${activeLink === "inventory" ? 'bg-[#FFEEEF]' : 'hover:bg-[#FFEEEF] '} rounded-lg ${expanded ? 'w-[155px]' : 'w-[50px] border-[1px] border-transparent'} ${expanded && activeLink !== "inventory" ? ' border-[1px] border-gray-200' : ''} transition-all duration-500 ease-in-out`}>
                  <InventoryOutlinedIcon />
                  <span className={`flex-grow text-center transition-opacity ${expanded ? 'duration-[400ms] opacity-100 max-w-full' : 'duration-0 opacity-0 max-w-0'}`}>Inventory</span>
                </div>
              </button>
            </div>

            <div className='mb-5'>
              <button onClick={handleLinkClick('orders')}>
                <div className={`flex p-3 ${activeLink === "orders" ? 'bg-[#FFEEEF]' : 'hover:bg-[#FFEEEF] '} rounded-lg ${expanded ? 'w-[155px]' : 'w-[50px] border-[1px] border-transparent'} ${expanded && activeLink !== "orders" ? ' border-[1px] border-gray-200' : ''} transition-all duration-500 ease-in-out`}>
                  <ReceiptLongOutlinedIcon/>
                  <span className={`flex-grow text-center transition-opacity ${expanded ? 'duration-[400ms] opacity-100 max-w-full' : 'duration-0 opacity-0 max-w-0'}`}>Orders</span>
                </div>
              </button>
            </div>

            <div className='mb-5'>
              <button onClick={handleLinkClick('users')}>
                <div className={`flex p-3 ${activeLink === "users" ? 'bg-[#FFEEEF]' : 'hover:bg-[#FFEEEF] '} rounded-lg ${expanded ? 'w-[155px]' : 'w-[50px] border-[1px] border-transparent'} ${expanded && activeLink !== "users" ? ' border-[1px] border-gray-200' : ''} transition-all duration-500 ease-in-out`}>
                  <AssignmentIndOutlinedIcon />
                  <span className={`flex-grow text-center transition-opacity ${expanded ? 'duration-[400ms] opacity-100 max-w-full' : 'duration-0 opacity-0 max-w-0'}`}>Users</span>
                </div>
              </button>
            </div>

            <div className='mb-5'>
              <button onClick={handleLinkClick('products')}>
                <div className={`flex p-3 ${activeLink === "products" ? 'bg-[#FFEEEF]' : 'hover:bg-[#FFEEEF] '} rounded-lg ${expanded ? 'w-[155px]' : 'w-[50px] border-[1px] border-transparent'} ${expanded && activeLink !== "products" ? ' border-[1px] border-gray-200' : ''} transition-all duration-500 ease-in-out`}>
                  <ListAltOutlinedIcon />
                  <span className={`flex-grow text-center transition-opacity ${expanded ? 'duration-[400ms] opacity-100 max-w-full' : 'duration-0 opacity-0 max-w-0'}`}>Products</span>
                </div>
              </button>
            </div>

            <div className='mb-5'>
              <button onClick={handleLinkClick('LowStockalerts')}>
                <div className={`flex items-center p-3 ${activeLink === "LowStockalerts" ? 'bg-[#FFEEEF]' : 'hover:bg-[#FFEEEF]'} rounded-lg ${expanded ? 'w-[155px]' : 'w-[50px] h-[50px] border-[1px] border-transparent'} ${expanded && activeLink !== "LowStockalerts" ? 'border-[1px] border-gray-200' : ''} transition-all duration-500 ease-in-out`}>
                  <NotificationsActiveOutlinedIcon/>
                  <span className={`flex-grow text-center transition-opacity ${expanded ? 'duration-[400ms] opacity-100 max-w-full' : 'duration-0 opacity-0 max-w-0'}`}>Low Stock Alerts</span>
                </div>
              </button>
            </div>

            <div className='mb-5'>
              <button onClick={handleLinkClick('customers')}>
                <div className={`flex p-3 ${activeLink === "customers" ? 'bg-[#FFEEEF]' : 'hover:bg-[#FFEEEF] '} rounded-lg ${expanded ? 'w-[155px]' : 'w-[50px] border-[1px] border-transparent'} ${expanded && activeLink !== "customers" ? ' border-[1px] border-gray-200' : ''} transition-all duration-500 ease-in-out`}>
                  <PeopleOutlinedIcon />
                  <span className={`flex-grow text-center transition-opacity ${expanded ? 'duration-[400ms] opacity-100 max-w-full' : 'duration-0 opacity-0 max-w-0'}`}>Customers</span>
                </div>
              </button>
            </div>

            <div className='mb-5'>
              <button onClick={handleLinkClick('dispatch')}>
                <div className={`flex items-center p-3 ${activeLink === "dispatch" ? 'bg-[#FFEEEF]' : 'hover:bg-[#FFEEEF]'} rounded-lg ${expanded ? 'w-[155px]' : 'w-[50px] h-[50px] border-[1px] border-transparent'} ${expanded && activeLink !== "dispatch" ? 'border-[1px] border-gray-200' : ''} transition-all duration-500 ease-in-out`}>
                  <SendAndArchiveOutlinedIcon />
                  <span className={`flex-grow text-center transition-opacity ${expanded ? 'duration-[400ms] opacity-100 max-w-full' : 'duration-0 opacity-0 max-w-0'}`}>Dispatch Requests</span>
                </div>
              </button>
            </div>

            <div className='mb-5'>
              <button onClick={handleLinkClick('requestsupply')}>
                <div className={`flex items-center p-3 ${activeLink === "requestsupply" ? 'bg-[#FFEEEF]' : 'hover:bg-[#FFEEEF]'} rounded-lg ${expanded ? 'w-[155px]' : 'w-[50px] h-[50px] border-[1px] border-transparent'} ${expanded && activeLink !== "requestsupply" ? 'border-[1px] border-gray-200' : ''} transition-all duration-500 ease-in-out`}>
                  <SupportAgentOutlinedIcon />
                  <span className={`flex-grow text-center transition-opacity ${expanded ? 'duration-[400ms] opacity-100 max-w-full' : 'duration-0 opacity-0 max-w-0'}`}>Request Supply</span>
                </div>
              </button>
            </div>

            <div className='mb-5'>
              <button onClick={handleLinkClick('return')}>
                <div className={`flex p-3 ${activeLink === "return" ? 'bg-[#FFEEEF]' : 'hover:bg-[#FFEEEF] '} rounded-lg ${expanded ? 'w-[155px]' : 'w-[50px] border-[1px] border-transparent'} ${expanded && activeLink !== "return" ? ' border-[1px] border-gray-200' : ''} transition-all duration-500 ease-in-out`}>
                  <ReplyOutlinedIcon />
                  <span className={`flex-grow text-center transition-opacity ${expanded ? 'duration-[400ms] opacity-100 max-w-full' : 'duration-0 opacity-0 max-w-0'}`}>Return</span>
                </div>
              </button>
            </div>

            {/* <div className='mb-5'>
              <button onClick={handleLinkClick('settings')}>
                <div className={`flex p-3 ${activeLink === "settings" ? 'bg-[#FFEEEF]' : 'hover:bg-[#FFEEEF] '} rounded-lg ${expanded ? 'w-[155px]' : 'w-[50px] border-[1px] border-transparent'} ${expanded && activeLink !== "settings" ? ' border-[1px] border-gray-200' : ''} transition-all duration-500 ease-in-out`}>
                  <SettingsOutlinedIcon />
                  <span className={`flex-grow text-center transition-opacity ${expanded ? 'duration-[400ms] opacity-100 max-w-full' : 'duration-0 opacity-0 max-w-0'}`}>Settings</span>
                </div>
              </button>
            </div> */}
          </div>
        </div>
        <div className="mb-5">
          <button onClick={handleLinkClick('logout')}>
            <div className={`flex p-3 hover:bg-[#842025] bg-[#820008] rounded-lg ${expanded ? 'w-[155px]' : 'w-12'} transition-all duration-500 ease-in-out`}>
              <img src={logouticon} alt="Logout" className='w-6 h-6' />
              <span className={`flex-grow text-center text-white transition-opacity ${expanded ? 'duration-[400ms] opacity-100 max-w-full' : 'duration-0 opacity-0 max-w-0'}`}>Logout</span>
            </div>
          </button>
        </div>
      </div>
      <div className='w-[2px] bg-[#dc4e4e7e] h-screen'></div>
    </nav>
  );
};

export default AdminNavbar;
