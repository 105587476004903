import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import LoginPage from './Pages/Common pages/login';
import ShowroomDashbaord from './Pages/showroom/Showroom_inventory';
import AdminDashbaord from './Pages/admin/AdminDashbaord';
import SupplierDashbaord from './Pages/supplier/SupplierDashbaord';
import SupplierWelcomePage from './Pages/supplier/Welcome';
import PreviousOrders from './Pages/supplier/PreviousOrders';
import PendingOrders from './Pages/supplier/PendingOrders';
import NotFoundPage from './Pages/Common pages/PageNotFound';
import HomePage from './Pages/Common pages/HomePage';
import Product from './Pages/Common pages/Product';

import ShowroomOrders from './Pages/showroom/Showroom_orders';
import ShowroomCustomers from './Pages/showroom/Showroom_customers';
import ShowroomBilling from './Pages/showroom/Showroom_billing';
import ShowroomSettings from './Pages/showroom/Showroom_settings';
import Dispatch from './Pages/showroom/Dispatch';
import Return from './Pages/showroom/ReturnProducts';

import AdminUsers from './Pages/admin/Users';
import AdminTab from './Pages/admin/TabbedView';
import AdminStock from './Pages/admin/Stock';
import AdminSupplier from './Pages/admin/SupplierProduct';
import AdminLowStock from './Pages/admin/LowStock';
import AdminSupplyReq from './Pages/admin/SupplyRequest';

import ShowroomNavbar from './Components/showroom/showroom_navbar';
import AdminNavbar from './Components/admin/Admin_navbar';
import WarehouseNavbar from './Components/warehouse/warehouse_navbar';
import ManagerNavbar from './Components/manager/Manager_navbar';
import SupplierNavbar from './Components/supplier/SupplierNavbar';

import SalesReport from './Components/admin/SalesReport';

import { jwtDecode } from 'jwt-decode';
import ProtectedRoutes from './ProtectedRoutes';

const publicRoutes = ['/', '/login', '/*'];



function App() {
  const [userRole, setUserRole] = useState(null);
  const [warehouse, setwarehouse] = useState(null);
  const [userStatus, setUserStatus] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkTokenValidity = async () => {
    const storedData = localStorage.getItem('token');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const token = parsedData.token;
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken) {
          setUserRole(decodedToken.role);
          setUserStatus(decodedToken.Status);
          if (decodedToken.role === 'Warehouse Staff' || decodedToken.role === 'Manager') {
            setwarehouse(decodedToken.WarehouseName);
          }
          setIsAuthenticated(true);
        } else {
          localStorage.removeItem('token');
          setIsAuthenticated(false);
          setUserRole(null);
          setUserStatus(null);
        }
      } catch (error) {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        setUserRole(null);
        setUserStatus(null);
      }
    } else {
      setIsAuthenticated(false);
      setUserRole(null);
      setUserStatus(null);
    }
    setIsLoading(false); // Set loading state to false once authentication check is done
  };

  useEffect(() => {
    checkTokenValidity();

    // window.onload = () => {
    //   checkTokenValidity();
    // };

    const interval = setInterval(() => {
      checkTokenValidity();
    }, 1000); // 5 minutes interval

    return () => {
      clearInterval(interval);
    };
  }, [userRole]);

  useEffect(() => {
    // Trigger re-render when userRole changes
    RenderProtectedRoutes(userRole, isAuthenticated);
  }, [userRole, isAuthenticated]);

  if (isLoading) {
    return <div>Loading...</div>; // Render loading indicator until authentication status is determined
  }

  return (
    <Router>
      <div>
        <ConditionalSideBar userRole={userRole} isAuthenticated={isAuthenticated} userStatus={userStatus} warehouse={warehouse} />
        <div>
          <Routes>
            {RenderProtectedRoutes(userRole, isAuthenticated, userStatus, warehouse)}
            <Route exact path="/login" element={<LoginPage />} />
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/products" element={<Product />} />
            <Route exact path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}


// Function to render protected routes based on user role
function RenderProtectedRoutes(userRole, isAuthenticated, userStatus, warehouse) {

  if (!isAuthenticated && !publicRoutes.includes(window.location.pathname)) {
    return <Route path="*" element={<Navigate to="/login" replace />} />;
  }

  return (
    <Route element={<ProtectedRoutes isAuthenticated={isAuthenticated} />}>
      {userRole && (
        <>

          {userRole === 'Admin' && (
            <>
              <Route exact path="/admin-dashboard" element={<AdminDashbaord />} />
              <Route exact path="/admin-dashboard/users" element={<AdminUsers />} />
              <Route path="/admin-dashboard/products/*" element={<AdminTab />} />
              <Route exact path="/admin-dashboard/customers" element={<ShowroomCustomers />} />
              <Route exact path="/admin-dashboard/inventory" element={<ShowroomDashbaord />} />
              <Route exact path="/admin-dashboard/orders" element={<ShowroomOrders />} />
              <Route exact path="/admin-dashboard/stock" element={<AdminStock />} />
              <Route exact path="/admin-dashboard/supplier" element={<AdminSupplier />} />
              <Route exact path="/admin-dashboard/LowStockalerts" element={<AdminLowStock />} />
              <Route exact path="/admin-dashboard/dispatch" element={<Dispatch />} />
              <Route exact path="/admin-dashboard/requestsupply" element={<AdminSupplyReq />} />
              <Route exact path="/admin-dashboard/return" element={<Return />} />
              <Route exact path="/admin-dashboard/sales" element={<SalesReport />} />
            </>
          )}
          {userRole === 'Showroom Staff' && (
            <>
              <Route exact path="/showroom-dashboard" element={<ShowroomDashbaord />} />
              <Route exact path="/showroom-dashboard/orders" element={<ShowroomOrders />} />
              <Route exact path="/showroom-dashboard/billing" element={<ShowroomBilling />} />
              <Route exact path="/showroom-dashboard/customers" element={<ShowroomCustomers />} />
              <Route exact path="/showroom-dashboard/settings" element={<ShowroomSettings />} />
              <Route exact path="/showroom-dashboard/dispatch" element={<Dispatch />} />
              <Route exact path="/showroom-dashboard/return" element={<Return />} />
            </>
          )}
          {userRole === 'Warehouse Staff' && warehouse === 'Kandy' && (
            <>
              <Route exact path="/warehouse-dashboard" element={<ShowroomDashbaord />} />
              <Route path="/warehouse-dashboard/products/*" element={<AdminTab />} />
              <Route exact path="/warehouse-dashboard/stock" element={<AdminStock />} />
              <Route exact path="/warehouse-dashboard/LowStockalerts" element={<AdminLowStock />} />
              <Route exact path="/warehouse-dashboard/dispatch" element={<Dispatch />} />
              <Route exact path="/warehouse-dashboard/orders" element={<ShowroomOrders />} />
            </>
          )}

          {userRole === 'Warehouse Staff' && warehouse === 'Gurudeniya' && (
            <>
              <Route exact path="/warehouse-dashboard" element={<ShowroomDashbaord />} />
              <Route path="/warehouse-dashboard/products/*" element={<AdminTab />} />
              <Route exact path="/warehouse-dashboard/stock" element={<AdminStock />} />
              <Route exact path="/warehouse-dashboard/LowStockalerts" element={<AdminLowStock />} />
              <Route exact path="/warehouse-dashboard/dispatch" element={<Dispatch />} />
            </>
          )}
          {userRole === 'Manager' && (
            <>
              <Route exact path="/manager-dashboard" element={<ShowroomDashbaord />} />
              <Route path="/manager-dashboard/products/*" element={<AdminTab />} />
              <Route exact path="/manager-dashboard/stock" element={<AdminStock />} />
              <Route exact path="/manager-dashboard/supplier" element={<AdminSupplier />} />
              <Route exact path="/manager-dashboard/LowStockalerts" element={<AdminLowStock />} />
              <Route exact path="/manager-dashboard/dispatch" element={<Dispatch />} />
              <Route exact path="/manager-dashboard/requestsupply" element={<AdminSupplyReq />} />
              {warehouse === 'Kandy' && (
                <> 
                <Route exact path="/manager-dashboard/orders" element={<ShowroomOrders />} />
                <Route exact path="/manager-dashboard/return" element={<Return />} />
                <Route exact path="/manager-dashboard/billing" element={<ShowroomBilling />} />
                <Route exact path="/manager-dashboard/customers" element={<ShowroomCustomers />} />
                </> 
              )}
            </>
          )}

          {userRole === 'Supplier' && userStatus === 'Active' && (
            <>
              <Route exact path="/supplier-dashboard" element={<SupplierDashbaord />} />
              <Route exact path="/supplier-dashboard/previousorders" element={<PreviousOrders />} />
              <Route exact path="/supplier-dashboard/pendingorders" element={<PendingOrders />} />
            </>
          )}
          {userRole === 'Supplier' && userStatus === 'Created' && (
            <>
              <Route exact path="/supplier" element={<SupplierWelcomePage />} />
            </>
          )}
        </>
      )}
    </Route>
  );
}

function ConditionalSideBar({ userRole, isAuthenticated, userStatus }) {
  const location = useLocation();

  if (!isAuthenticated || !userRole) {
    return null;
  }
  // Render Sidebar only if the current location is not the root path ("/") 
  if (publicRoutes.includes(location.pathname)) {
    return null;
  } else if (location.pathname.includes('admin-dashboard') && userRole === 'Admin') {
    return <AdminNavbar />; // Render AdminNavbar for admin-dashboard route
  } else if (location.pathname.includes('showroom-dashboard') && userRole === 'Showroom Staff') {
    return <ShowroomNavbar />; // Render ShowroomNavbar for showroom-dashboard routes
  } else if (location.pathname.includes('warehouse-dashboard') && (userRole === 'Warehouse Staff')) {
    return <WarehouseNavbar />; // Render WarehouseNavbar for warehouse-dashboard routes
  } else if (location.pathname.includes('manager-dashboard') && (userRole === 'Manager')) {
    return <ManagerNavbar />; // Render ManagerNavbar for manager-dashboard routes
  } else if (location.pathname.includes('supplier-dashboard') && userRole === 'Supplier' && userStatus === 'Active') {
    return <SupplierNavbar />; // Render SupplierNavbar for supplier-dashboard routes
  }
}

export default App;

