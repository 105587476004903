import React, { useState, useEffect } from 'react';
import Table from '../../Components/admin/ProductTable';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Swal from 'sweetalert2';
import { jwtDecode } from 'jwt-decode';

function createData(id, name) {
  return {
    id,
    name
  };
}

const Products = () => {
  const [open, setOpen] = useState(false);
  const [userRole, setuserRole] = useState();
  const [token, settoken] = useState();
  const [itemData, setItemData] = useState({
    ItemCode: '',
    Name: '',
    Description: '',
    Price: '',
    Category: '',
  });

  useEffect(() => {
    const checkUserRole = async () => {
      const storedData = localStorage.getItem('token');
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        const token = parsedData.token;
        settoken(token);

        try {
          const decodedToken = jwtDecode(token);
          if (decodedToken) {
            setuserRole(decodedToken.role);
          }
        } catch (error) {
          setuserRole(null);
        }
      } else {
        setuserRole(null);
      }
    };

    checkUserRole();
  }, []);


  const handleClick = () => {
    if (userRole === 'Admin') {
      window.location.href = '/admin-dashboard/supplier';
    } else if (userRole === 'Manager') {
      window.location.href = '/manager-dashboard/supplier';
    }
  };

  console.log(userRole);

  const [errors, setErrors] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [categoryList, setCategoryList] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    // Fetch category data from your API when component mounts
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://ceramic-back.achila.tech/api/category/get', {
          headers: {
            'authorization': token,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch categories');
        }
        const data = await response.json();

        const CategoryData = data.categories;
        if (Array.isArray(CategoryData)) {
          const transformedData = CategoryData.map(category => createData(
            category.CategoryID,
            category.Name,
          ));
          setCategoryList(transformedData);
        } else {
          throw new Error('Data received is not in the expected format');
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, [token]);

  const handleClose = () => {
    setOpen(false);
    setItemData({
      ItemCode: '',
      Name: '',
      Description: '',
      Price: '',
      Category: '',
    });
    setSelectedImage(null);
    setErrors({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === 'ItemCode') {
      newValue = newValue.slice(0, 10); // Limit to 10 characters for Address
      newValue = newValue.replace(/[^a-zA-Z0-9]/g, ''); // Allow only alphabets and numbers
      newValue = newValue.replace(/\s/g, '');
    } else if (name === 'Name') {
      newValue = newValue.slice(0, 20).toUpperCase();
    } else if (name === 'Price') {
      newValue = newValue.slice(0, 15);
      newValue = newValue.replace(/[^0-9.]/g, ''); // Allow only numbers and dots
      newValue = newValue.replace(/^(\d*\.\d{0,2}).*/, '$1'); // Allow only up to 2 decimal places
    }
    setItemData({ ...itemData, [name]: newValue });
    setErrors({ ...errors, [name]: '' }); // Clear the error when input changes
  };

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    setSelectedImage(imageFile);
  };


  const validateForm = () => {
    const errors = {};
    if (!itemData.ItemCode.trim()) {
      errors.ItemCode = 'Item Code is required';
    }
    if (!itemData.Name.trim()) {
      errors.Name = 'Item Name is required';
    }
    if (!itemData.Description.trim()) {
      errors.Description = 'Description is required';
    }
    if (!itemData.Price.trim()) {
      errors.Price = 'Price is required';
    }
    if (!itemData.Category) {
      errors.Category = 'Category is required';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0; // Return true if there are no errors
  };

  //Add new product
  const handleAdd = async () => {
    if (validateForm()) {
      try {
        if (selectedImage != null) {
          const response = await fetch('https://ceramic-back.achila.tech/api/product/exists', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'authorization': token,
            },
            body: JSON.stringify(itemData),
          });
          if (response.ok) {
            const data = await response.json();
            console.log(data);
            addProduct();
          } else if (response.status === 400) {// Error due to existing fields
            const { errors } = await response.json();
            // Handle specific error cases
            if (errors.ItemCode) {
              Swal.fire({
                icon: 'error',
                title: 'Product Addition Failed',
                text: errors.ItemCode,
                customClass: {
                  popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                },
                didOpen: () => {
                  document.querySelector('.swal2-container').style.zIndex = '9999'; 
                }
              });
            } else if (errors.Name) {
              Swal.fire({
                icon: 'error',
                title: 'Product Addition Failed',
                text: errors.Name,
                customClass: {
                  popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                },
                didOpen: () => {
                  document.querySelector('.swal2-container').style.zIndex = '9999'; 
                }
              });
            } else {
              // Handle other validation errors
              Swal.fire({
                icon: 'error',
                title: 'Product Addition Failed',
                text: '',
                html: Object.values(errors).map(error => `<div>${error}</div>`).join(''),
                customClass: {
                  popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                },
                didOpen: () => {
                  document.querySelector('.swal2-container').style.zIndex = '9999'; 
                }
              });
            }
          } else {
            // Handle network or unexpected errors
            let errorMessage = 'An error occurred while adding the Product.';
            Swal.fire({
              icon: 'error',
              title: 'Product Addition Failed',
              text: errorMessage,
              customClass: {
                popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
              },
              didOpen: () => {
                document.querySelector('.swal2-container').style.zIndex = '9999'; 
              }
            });
          }

        } else {
          throw new Error('Select an Image to Upload');
        }
      } catch (error) {
        if (error.message === 'Select an Image to Upload') {
          console.error('eror');
          Swal.fire({
            icon: 'error',
            title: 'Empty Image',
            text: "Please Select an Image to Upload",
            customClass: {
              popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
            },
            didOpen: () => {
              document.querySelector('.swal2-container').style.zIndex = '9999'; 
            }
          });
        } else {
          // Handle network or unexpected errors
          console.error('Error adding Product:', error);
          let errorMessage = 'An error occurred while adding the Product.';
          Swal.fire({
            icon: 'error',
            title: 'Product Addition Failed',
            text: errorMessage,
            customClass: {
              popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
            },
            didOpen: () => {
              document.querySelector('.swal2-container').style.zIndex = '9999'; 
            }
          });
        }
      };
    }
  };

  const addProduct = async () => {
    try {
      const formData = new FormData();
      formData.append('image', selectedImage);
      formData.append('ItemCode', itemData.ItemCode);
      formData.append('Name', itemData.Name);
      formData.append('Description', itemData.Description);
      formData.append('Price', itemData.Price);
      formData.append('Category', itemData.Category);


      const response = await fetch('https://ceramic-back.achila.tech/api/product/add', {
        method: 'POST',
        headers: {
          'authorization': token,
        },
        body: formData,
      });
      if (response.ok) {
        // Product added successfully
        const data = await response.json();
        console.log('Product added:', data);
        Swal.fire({
          icon: 'success',
          title: 'Product Added Successfully!',
          customClass: {
            popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
          },
          didOpen: () => {
            document.querySelector('.swal2-container').style.zIndex = '9999'; 
          }
        }).then(() => {
          handleClose();
          window.location.reload();
        });
      }
    } catch (error) {
      // Handle network or unexpected errors
      console.error('Error adding Product:', error);
      let errorMessage = 'An error occurred while adding the Product.';
      Swal.fire({
        icon: 'error',
        title: 'Product Addition Failed',
        text: errorMessage,
        customClass: {
          popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
        },
        didOpen: () => {
          document.querySelector('.swal2-container').style.zIndex = '9999'; 
        }
      });
    }
  };

  return (
    <div className='flex-col w-full h-screen bg-[#F7F7F7]'>
      <div className='flex justify-end mt-10 mr-10'>
        <div className='mr-4'>
          {(userRole === 'Admin' || userRole === 'Manager') && (
            <Button variant="contained" color="success" style={{ backgroundColor: '#122f4b' }} onClick={handleClick}>
              Allocate Products to Suppliers
            </Button>
          )}
        </div>
        <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={handleClickOpen}>Add Product</Button>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          disableEscapeKeyDown={true}
          BackdropProps={{
            style: { backdropFilter: 'blur(5px)' },
            invisible: true // This will prevent backdrop click
          }}
        >
          <DialogTitle id="form-dialog-title" className='text-center font-extrabold'>Add Product</DialogTitle>
          <div className='mb-3'>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Item Code"
                    name="ItemCode"
                    value={itemData.ItemCode}
                    onChange={handleChange}
                    fullWidth
                    error={Boolean(errors.ItemCode)}
                    helperText={errors.ItemCode}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Name"
                    name="Name"
                    value={itemData.Name}
                    onChange={handleChange}
                    fullWidth
                    error={Boolean(errors.Name)}
                    helperText={errors.Name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    name="Description"
                    value={itemData.Description}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={6}
                    error={Boolean(errors.Description)}
                    helperText={errors.Description}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Price"
                    name="Price"
                    value={itemData.Price}
                    onChange={handleChange}
                    fullWidth
                    error={Boolean(errors.Price)}
                    helperText={errors.Price}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth error={Boolean(errors.Category)}>
                    <InputLabel>Category</InputLabel>
                    <Select
                      value={itemData.Category}
                      onChange={handleChange}
                      name="Category"
                    >
                      {categoryList.map((category) => (
                        <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errors.Category && <div style={{ color: 'red' }}>{errors.Category}</div>}
                </Grid>
                <Grid item xs={12}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: 'none' }} // Hide the default file input UI
                    id="imageInput"
                  />
                  <label htmlFor="imageInput">
                    <Button variant="outlined" component="span">Choose Photo</Button>
                  </label>
                  {selectedImage && (
                    <div>
                      <p>Selected Image:</p>
                      <img src={URL.createObjectURL(selectedImage)} alt="Selected" style={{ maxWidth: '200px' }} />
                    </div>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
          </div>
          <DialogActions className="mx-4 mb-4">
            <div className='w-full space-y-2'>
              <Button onClick={handleAdd} color="success" variant="contained" fullWidth >
                Add Product
              </Button>
              <Button onClick={handleClose} color="error" variant="contained" fullWidth >
                Cancel
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
      <div className='p-10'>
        <Table />
      </div>
    </div>
  );
};

export default Products;