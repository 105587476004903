import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Select, MenuItem, FormControl, TextField, Pagination, InputAdornment, Autocomplete, Box, Typography, Slider } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

function createData(ItemCode, Name, WarehouseID, warehouse, quanity, category) {
  return {
    ItemCode,
    Name,
    WarehouseID,
    warehouse,
    quanity,
    category
  };
}

function Row({ row }) {
  return (
    <React.Fragment>
      <TableRow>
        <TableCell component="th" scope="row" align="left">
          {row.ItemCode}
        </TableCell>
        <TableCell align="left">{row.Name}</TableCell>
        <TableCell align="left">{row.category}</TableCell>
        <TableCell align="left">{row.warehouse}</TableCell>
        <TableCell align="left">{row.quanity}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable() {
  const [productData, setProductData] = useState([]);
  const [token, setToken] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [categoryfilter, setcategoryFilter] = useState([]);
  const [warehousefilter, setwarehouseFilter] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [amountRange, setAmountRange] = useState([0, 999999]);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(999999);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleFilterClick = () => {
    setIsOpen(!isOpen);
  };

  const handleAmountChange = (event, newValue) => {
    setAmountRange(newValue);
    setMinValue(newValue[0]);
    setMaxValue(newValue[1]);
  };

  const handleMinInputChange = (event) => {
    let value = parseInt(event.target.value);
    if (isNaN(value) || value < 0) {
      value = 0;
    }
    if (value <= maxValue) {
      setMinValue(value);
      setAmountRange([value, maxValue]);
    }
  };

  const handleMaxInputChange = (event) => {
    let value = parseInt(event.target.value);
    if (isNaN(value) || value < minValue) {
      value = minValue;
    }
    if (value <= 999999) {
      setMaxValue(value);
      setAmountRange([minValue, value]);
    }
  };

  const handleResetFilters = () => {
    setcategoryFilter([]);
    setwarehouseFilter([]);
    setAmountRange([0, 999999]);
    setMinValue(0);
    setMaxValue(999999);
  };

  useEffect(() => {
    const filteredItems = productData.filter(item => {
      const ItemCode = item.ItemCode.toString().toLowerCase();
      const Name = item.Name?.toString().toLowerCase() || '';
      const search = searchQuery.toLowerCase();
      const matchesSearchQuery = Name.includes(search) || Name === search || ItemCode.includes(search) || ItemCode === search;
      const matchesCategories = categoryfilter.length === 0 || categoryfilter.some(category => category.title === item.category);
      const matchesWarehouses = warehousefilter.length === 0 || warehousefilter.some(warehouse => warehouse.title === item.warehouse);
      const matchesAmountRange = item.quanity >= minValue && item.quanity <= maxValue;
      return matchesSearchQuery && matchesCategories && matchesAmountRange && matchesWarehouses;
    });

    setFilteredItems(filteredItems);
  }, [searchQuery, productData, categoryfilter, warehousefilter, minValue, maxValue]);

  const handleFilterChange = (event, newValue) => {
    setcategoryFilter(newValue);
  };
  const handlewarehouseFilterChange = (event, newValue) => {
    setwarehouseFilter(newValue);
  };

  useEffect(() => {
    const storedData = localStorage.getItem('token');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setToken(parsedData.token);
    }

    const fetchCategories = async () => {
      try {
        const response = await fetch('https://ceramic-back.achila.tech/api/category/get', {
          headers: {
            'authorization': token,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const categoryData = data.categories;
        if (Array.isArray(categoryData)) {
          const transformedData = categoryData.map(category => ({
            title: category.Name,
            CategoryID: category.CategoryID,
          }));
          setCategoryData(transformedData);
        } else {
          throw new Error('Data received is not in the expected format');
        }
      } catch (error) {
        console.error('Error fetching category data:', error);
      }
    };

    const fetchData = async () => {
      try {
        const response = await fetch('https://ceramic-back.achila.tech/api/product/getstock', {
          headers: {
            'authorization': token,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const productData = data.products;
        if (Array.isArray(productData)) {
          const transformedData = productData.map((product, index) => createData(
            product.ItemCode, 
            product.Name,
            product.WarehouseID,
            product.WarehouseLocation,
            product.StockQuantity,
            product.CName,
          ));
          setProductData(transformedData);
        } else {
          throw new Error('Data received is not in the expected format');
        }
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };

    if (token) {
      fetchData();
      fetchCategories();
    }
  }, [token]);

  return (
    <div>
      <div className='mb-5 w-[20%]'>
        <TextField
          fullWidth
          label="Search Name or ItemCode"
          value={searchQuery}
          onChange={(e) => {
            const value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
            setSearchQuery(value);
          }}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchQuery && (
              <IconButton onClick={() => setSearchQuery('')}>
                <ClearIcon />
              </IconButton>
            ),
          }}
        />
      </div>
      <div>
        <button
          onClick={handleFilterClick}
          className="bg-[#000000] hover:bg-[#000000c1] text-white font-bold py-2 px-4 rounded-lg inline-flex items-center"
        >
          <FontAwesomeIcon icon={faFilter} className="mr-2" />
          Filter
        </button>
      </div>
      {isOpen && (
        <div className="bg-gray-200 p-5 rounded-lg inline-block relative" >
          <div className='w-[500px]'>
            <Autocomplete
              multiple
              id="filter-category"
              options={categoryData}
              getOptionLabel={(option) => option.title}
              value={categoryfilter}
              isOptionEqualToValue={(option, value) => option.title === value.title}
              onChange={handleFilterChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Filter Categories"
                  placeholder="Category"
                />
              )}
            />
            <div className='mt-3'>
              <Autocomplete
                multiple
                id="filter-warehouse"
                options={[
                  { title: 'Gurudeniya', WarehouseID: 1 },
                  { title: 'Kandy', WarehouseID: 2 }
                ]}
                getOptionLabel={(option) => option.title}
                value={warehousefilter}
                isOptionEqualToValue={(option, value) => option.title === value.title}
                onChange={handlewarehouseFilterChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter Warehouses"
                    placeholder="Warehouse"
                  />
                )}
              />
            </div>
          </div>
          <div className="mt-5">
            <Typography id="range-slider" gutterBottom>
              Product Quantity
            </Typography>
            <Box width={400} className="mx-auto">
              <Slider
                value={amountRange}
                onChange={handleAmountChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                min={0}
                max={999999}
                step={100}
              />
            </Box>
            <div className="flex justify-between mt-4">
              <TextField
                label="Min"
                type="number"
                value={minValue}
                onChange={handleMinInputChange}
                variant="outlined"
                size="small"
                className="w-[150px]"
                InputProps={{
                  startAdornment: <InputAdornment position="start">LKR</InputAdornment>,
                  inputProps: { min: 0, max: maxValue }
                }}
              />
              <TextField
                label="Max"
                type="number"
                value={maxValue}
                onChange={handleMaxInputChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start">LKR</InputAdornment>,
                  inputProps: { min: minValue, max: 9999999 }
                }}
                variant="outlined"
                size="small"
                className="w-[150px]"
              />
            </div>
          </div>

          <button
            onClick={handleResetFilters}
            className="bg-[#af4242] text-white font-bold py-2 px-4 rounded inline-flex items-center mt-5"
          >
            <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
            Reset Filters
          </button>
        </div>
      )}
      <div className='mb-4 flex items-end justify-end'>
        <div className='items-center flex mr-5'>
          <div><span style={{ color: '#4E4E4E' }}>Rows per page: &nbsp;</span></div>
          <FormControl sx={{ minWidth: 60 }} size="small">
            <Select
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
            >
              {[10, 20, 50].map((rows) => (
                <MenuItem key={rows} value={rows}>
                  {rows}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Pagination
          count={Math.ceil(filteredItems.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          style={{ marginTop: '1rem' }}
        />
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow style={{ borderBottom: '2px solid black', background: '#F7F7F7' }}>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>Product ID</TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>Product Name</TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>Category Name</TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>Warehouse</TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ background: '#F7F7F7' }}>
            {filteredItems.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((product, index) => (
              <React.Fragment key={`${index}`}>
                <Row row={product} />
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
