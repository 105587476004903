import React, { useState, useRef, useEffect } from 'react';
import { Container, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Box } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import Autocomplete from '@mui/material/Autocomplete';

// Register the necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SalesReport = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [reportData, setReportData] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const chartRef = useRef(null);
    const [errors, setErrors] = useState({ startDate: '', endDate: '' });
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [products, setProducts] = useState([]);
    const [selectedProductDetails, setSelectedProductDetails] = useState(null);

    const fetchProductDetails = async () => {
        if (selectedProduct) {
            try {
                const response = await fetch(`https://ceramic-back.achila.tech/api/report/product/${selectedProduct}`);
                const data = await response.json();
                setSelectedProductDetails(data); // Assuming data contains { productName, productId }
            } catch (error) {
                console.error('Error fetching product details:', error);
            }
        }
    };

    useEffect(() => {
        if (dialogOpen) {
            fetchProductDetails();
        }
    }, [dialogOpen]);




    useEffect(() => {
        // Fetch products for the filter
        const fetchProducts = async () => {
            const response = await fetch('https://ceramic-back.achila.tech/api/report/products');
            const data = await response.json();
            setProducts(data);
        };

        fetchProducts();
    }, []);

    const handleGenerateReport = async () => {
        // Validate dates
        if (!startDate) {
            setErrors(prevErrors => ({ ...prevErrors, startDate: 'Please select a start date' }));
            return;
        } else {
            setErrors(prevErrors => ({ ...prevErrors, startDate: '' }));
        }

        if (!endDate) {
            setErrors(prevErrors => ({ ...prevErrors, endDate: 'Please select an end date' }));
            return;
        } else {
            setErrors(prevErrors => ({ ...prevErrors, endDate: '' }));
        }

        let apiUrl = `https://ceramic-back.achila.tech/api/report/sales-report?start=${startDate}&end=${endDate}`;

        if (selectedProduct) {
            apiUrl += `&itemCode=${selectedProduct}`;
        }

        const response = await fetch(apiUrl);
        const data = await response.json();
        setReportData(data);
        setDialogOpen(true);
    };


    const handleExportPDF = async () => {
        const doc = new jsPDF();
        
        const reportTitle = 'Sales Report';
        const companyDetails = 'Ramitha Ceramic \n 49, King Street, Kandy \n Tel: 081-2222494';
        const dateRange = `Date Range: ${startDate} to ${endDate}`;
        let productDetails;
        if(selectedProductDetails){
            productDetails = `Product: ${selectedProductDetails.productName} (ID: ${selectedProductDetails.productId})`;
        }
    
    
        // Header
        doc.setFontSize(18);
        doc.setFont('helvetica', 'bold');
        doc.setTextColor(0, 0, 0); // Black color
        doc.text(reportTitle, doc.internal.pageSize.width / 2, 15, { align: 'center' });
        doc.setFont('helvetica', 'normal');
    
        // Company details
        doc.setFontSize(14);
        doc.setTextColor(100);
        doc.setTextColor(0, 0, 0);
        doc.text(companyDetails, doc.internal.pageSize.width / 2, 25, { align: 'center' });
    
        // Date range
        doc.setFontSize(12);
        doc.text(dateRange, 15, 55);
    
        // Product details (if selected)
        if (productDetails) {
            doc.text(productDetails, 15, 70);
        }
    
        // Table
        if (reportData) {
            const tableColumn = ["Date", "Sale Amount"];
            const tableRows = reportData.map(item => [
                item.Date,
                `Rs. ${item.SaleAmount.toLocaleString()}`
            ]);
    
            doc.autoTable({
                head: [tableColumn],
                body: tableRows,
                startY: 80,
                theme: 'grid',
                styles: {
                    valign: 'middle',
                    halign: 'center',
                    fontSize: 10,
                },
                columnStyles: {
                    0: { cellWidth: 50 },
                    1: { cellWidth: 50, halign: 'right' },
                }
            });
        }
    
        // Chart
        setTimeout(() => {
            if (chartRef.current) {
                const chart = chartRef.current;
                const chartBase64 = chart.toBase64Image();
                doc.addImage(chartBase64, 'PNG', 15, doc.previousAutoTable.finalY + 30, 180, 80);
            }
    
            doc.save('sales_report.pdf');
        }, 500);
    };
    


    const barChartData = {
        labels: reportData ? reportData.map(data => data.Date) : [],
        datasets: [
            {
                label: 'Sales Amount',
                data: reportData ? reportData.map(data => data.SaleAmount) : [],
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
            },
        ],
    };

    return (
        <Container>
            <div className='flex justify-between'>
                <TextField
                    label="Start Date"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    sx={{ marginRight: 2 }}
                />
                <TextField
                    label="End Date"
                    type="date"
                    value={endDate}
                    disabled={!startDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        inputProps: { min: startDate },
                    }}
                />
            </div>
            {errors.startDate && (
                <Typography color="error" sx={{ marginTop: 1 }}>
                    {errors.startDate}
                </Typography>
            )}
            {errors.endDate && (
                <Typography color="error" sx={{ marginTop: 1 }}>
                    {errors.endDate}
                </Typography>
            )}
            <div className='mt-3'>
                <p className='mb-2'>Filter by: (optional)</p>
                <div className='mb-4'>
                    <Autocomplete
                        autoHighlight
                        options={products}
                        getOptionLabel={(option) => `${option.ItemCode} - ${option.Name}`}
                        filterOptions={(options, { inputValue }) => options.filter(item => item.Name.includes(inputValue) || item.ItemCode.toString().includes(inputValue))}
                        onChange={(e, newValue) => {
                            const selectedItemCode = newValue ? newValue.ItemCode : ''; // Extract ItemCode from newValue
                            setSelectedProduct(selectedItemCode); // Pass only the ItemCode
                        }}
                        renderOption={(props, option) => (
                            <Box {...props}>
                                {option.ItemCode} - {option.Name}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select Product"
                                error={Boolean(errors[`item`])}
                                helperText={errors[`item`]}
                                inputProps={{
                                    ...params.inputProps,
                                    onInput: (event) => {
                                        event.target.value = event.target.value.toUpperCase().replace(/[^A-Z0-9\s]/g, ''); // Convert to uppercase and remove non-uppercase characters
                                    }
                                }}
                            />
                        )}
                    />
                </div>
            </div>
            <div className='mt-6'>
                <Button variant="contained" color="success" style={{ backgroundColor: '#122f4b' }} onClick={handleGenerateReport}>
                    Generate Report
                </Button>
            </div>

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth="md">
                <DialogTitle>
                    <Typography variant="h6" component="div" align="center" sx={{ fontWeight: 'bold' }}>
                        Sales Report
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {selectedProductDetails && (
                        <div style={{ marginTop: '20px' }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                Selected Product:
                            </Typography>
                            <Typography variant="body1">
                                ID: {selectedProductDetails.productId}
                            </Typography>
                            <Typography variant="body1">
                                Name: {selectedProductDetails.productName}
                            </Typography>
                        </div>
                    )}
                    {reportData && (
                        <div>
                            <Bar
                                ref={chartRef}
                                data={barChartData}
                                options={{
                                    scales: {
                                        x: { title: { display: true, text: 'Date' } },
                                        y: { title: { display: true, text: 'Sales Amount' } }
                                    }
                                }}
                            />
                            <table style={{ margin: 'auto', textAlign: 'center', width: '60%', marginTop: '20px' }}>
                                <thead style={{ backgroundColor: '#f0f0f0' }}>
                                    <tr>
                                        <th>Date</th>
                                        <th>Sale Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reportData.map((data, index) => (
                                        <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#f9f9f9' }}>
                                            <td style={{ textAlign: 'left', paddingLeft: '20px' }}>{data.Date}</td>
                                            <td style={{ textAlign: 'right', paddingRight: '20px' }}>
                                                Rs. {data.SaleAmount.toLocaleString()}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)}>Close</Button>
                    <Button variant="contained" onClick={handleExportPDF}>
                        Export PDF
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default SalesReport;
