import React, { useState, useEffect } from 'react';
import Table from '../../Components/admin/SupplierProductTable';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, MenuItem, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import Autocomplete from '@mui/material/Autocomplete';
import { jwtDecode } from 'jwt-decode';

import Swal from 'sweetalert2';

const SupplierProduct = () => {
  const [open, setOpen] = useState(false);
  const [supplierProductDetails, setSupplierProductDetails] = useState([]);
  const [transopen, setTransOpen] = useState(false);
  const [stockEntries, setStockEntries] = useState([{ item: null }]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [productList, setProductList] = useState([]);
  const [supplierList, setsupplierList] = useState([]);
  const [supplierListexclude, setsupplierListexclude] = useState([]);
  const [errors, setErrors] = useState({});
  const [token, setToken] = useState(null);
  const [userRole, setuserRole] = useState(null);

  const formatSupplierName = (supplier) => `${supplier.FirstName} ${supplier.LastName} (UserID: ${supplier.UserID})`;

  useEffect(() => {
    const storedData = localStorage.getItem('token');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setToken(parsedData.token);

      try {
        const decodedToken = jwtDecode(parsedData.token);
        if (decodedToken) {
          setuserRole(decodedToken.role);
        }
      } catch (error) {
        setuserRole(null);
      }
    } else {
      setuserRole(null);
    }

    if (token) {
      fetchProductList();
      fetchsupplierListexclude();
      fetchsupplierList();
    }
  }, [token]);

  useEffect(() => {
    if (supplierList.length > 0 && supplierListexclude.length > 0) {
      const filteredSuppliers = supplierList.filter(supplier =>
        !supplierListexclude.some(special => special.UserID === supplier.UserID)
      );
      setsupplierList(filteredSuppliers);
    }
  }, [supplierList, supplierListexclude]);

  useEffect(() => {
    if (transopen && selectedSupplier) {
      fetchSupplierProductDetails(selectedSupplier.SupplierID);
    } else {
      setSupplierProductDetails([]);
    }
  }, [transopen, selectedSupplier]);


  const fetchsupplierListexclude = async () => {
    try {
      const response = await fetch('https://ceramic-back.achila.tech/api/user/getsupplierexclude', {
        headers: {
          'authorization': token,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setsupplierListexclude(data.users);
      } else {
        console.error('Failed to fetch supplier list');
      }
    } catch (error) {
      console.error('Error fetching supplier list:', error);
    }
  };


  const fetchSupplierProductDetails = (supplierID) => {
    fetch(`https://ceramic-back.achila.tech/api/supplier/supplierProduct/${supplierID}`, {
      headers: {
        'Authorization': token,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch supplier product details');
        }
        return response.json();
      })
      .then(data => {
        const list = data[0].products;
        setSupplierProductDetails(list.map(product => ({ item: product[0] })));
      })
      .catch(error => {
        console.error('Error fetching supplier product details:', error);
      });
  };

  const fetchProductList = async () => {
    try {
      const response = await fetch('https://ceramic-back.achila.tech/api/product/getproduct', {
        headers: {
          'authorization': token,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setProductList(data.products);
      } else {
        console.error('Failed to fetch product list');
      }
    } catch (error) {
      console.error('Error fetching product list:', error);
    }
  };

  const fetchsupplierList = async () => {
    try {
      const response = await fetch('https://ceramic-back.achila.tech/api/user/getsupplier', {
        headers: {
          'authorization': token,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setsupplierList(data.users);
      } else {
        console.error('Failed to fetch supplier list');
      }
    } catch (error) {
      console.error('Error fetching supplier list:', error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleTransOpen = () => {
    setTransOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setStockEntries([{ item: '' }]);
    setSelectedSupplier(null);
    setErrors({});
  };
  const handleTransClose = () => {
    setTransOpen(false);
    setErrors({});
    setSelectedSupplier(null);
    setSupplierProductDetails([]);
  };

  const handleStockEntryChange = (index, e) => {
    const { name, value } = e.target;
    const newStockEntries = [...stockEntries];
    newStockEntries[index][name] = value;
    setStockEntries(newStockEntries);
  };

  const handleEntryChange = (index, e) => {
    const { name, value } = e.target;
    const newStockEntries = [...supplierProductDetails];
    newStockEntries[index][name] = value;
    setSupplierProductDetails(newStockEntries);
  };

  const handleAddRow = () => {
    setStockEntries([...stockEntries, { item: '' }]);
  };

  const handleAddRowinchange = () => {
    setSupplierProductDetails([...supplierProductDetails, { item: '' }]);
  };

  const handleDeleteRow = (index) => {
    const newStockEntries = stockEntries.filter((_, i) => i !== index);
    setStockEntries(newStockEntries);
  };

  const handleDeleteRowinchange = (index) => {
    const newEntries = supplierProductDetails.filter((_, i) => i !== index);
    setSupplierProductDetails(newEntries);
  };

  const validateForm = () => {
    const errors = {};

    // Validate each entry in stockEntries
    stockEntries.forEach((entry, index) => {
      if (!entry.item || !entry.item.trim()) {
        errors[`item${index}`] = 'Item is required';
      }
    });

    if (!selectedSupplier) {
      errors[`supplier`] = 'Supplier is required';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0; // Return true if there are no errors
  };

  const validateForm1 = () => {
    const errors = {};

    // Validate each entry in stockEntries
    supplierProductDetails.forEach((entry, index) => {
      if (!entry.item || !entry.item.trim()) {
        errors[`item${index}`] = 'Item is required';
      }
    });

    if (!selectedSupplier) {
      errors[`supplier`] = 'Supplier is required';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0; // Return true if there are no errors
  };


  const handleAllocate = async () => {
    if (validateForm()) {
      try {
        const response = await fetch('https://ceramic-back.achila.tech/api/supplier/addproducts', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
          body: JSON.stringify({
            stockEntries: stockEntries, // Extract only item codes
            selectedSupplier: selectedSupplier, // Send the selectedSupplier object as it is
          }),
        });

        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Products Allocated Successfully!',
            customClass: {
              popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
            },
            didOpen: () => {
              document.querySelector('.swal2-container').style.zIndex = '9999';
            }
          }).then(() => {
            handleClose();
            window.location.reload();
          });
        } else {
          if (response.status === 400) {
            // If status is 400, set a specific error message
            Swal.fire({
              icon: 'error',
              title: 'Failed to Allocate Products',
              customClass: {
                popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
              },
              didOpen: () => {
                document.querySelector('.swal2-container').style.zIndex = '9999';
              },
              text: 'Products are allocated already for this supplier',
            });
          } else {
            const responseData = await response.json();
            // Handle error response
            Swal.fire({
              icon: 'error',
              title: 'Failed to Allocate Products',
              customClass: {
                popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
              },
              didOpen: () => {
                document.querySelector('.swal2-container').style.zIndex = '9999'; 
              },
              text: responseData.message || 'An error occurred while allocating products.',
            });
          }
        }
      } catch (error) {
        console.error('Error Allocate Products:', error);
        Swal.fire({
          icon: 'error',
          title: 'Failed to Allocate Products',
          customClass: {
            popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
          },
          didOpen: () => {
            document.querySelector('.swal2-container').style.zIndex = '9999';
          },
          text: 'An error occurred while allocating products.',
        });
      }
    }
  };


  const handleChange = async () => {
    if (validateForm1()) {
      try {
        const result = await Swal.fire({
          title: 'Are you sure?',
          text: 'Do you want to change?',
          icon: 'warning',
          didOpen: () => {
            document.querySelector('.swal2-container').style.zIndex = '9999'; 
          },
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        });
        if (result.isConfirmed) {
          const response = await fetch('https://ceramic-back.achila.tech/api/supplier/changeproducts', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
            body: JSON.stringify({ supplierProductDetails, selectedSupplier }),
          });
          if (response.ok) {
            Swal.fire({
              icon: 'success',
              title: 'Production Allocation Changed Successfully!',
              customClass: {
                popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
              },
              didOpen: () => {
                document.querySelector('.swal2-container').style.zIndex = '9999'; 
              }
            }).then(() => {
              handleClose();
              window.location.reload();
            });
          } else {
            const responseData = await response.json();
            // Handle error response
            Swal.fire({
              icon: 'error',
              title: 'Failed to Change Product Allocation',
              customClass: {
                popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
              },
              didOpen: () => {
                document.querySelector('.swal2-container').style.zIndex = '9999'; 
              },
              text: responseData.message || 'An error occurred while allocating products.',
            });
          }
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Failed to Change Product Allocation',
          customClass: {
            popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
          },
          didOpen: () => {
            document.querySelector('.swal2-container').style.zIndex = '9999'; 
          },
          text: 'An error occurred while allocating products.',
        });
      }
    }
  };

  return (
    <div className="h-screen flex bg-[#F7F7F7]">
      <div className='w-20 h-screen'></div>
      <div className="flex w-full overflow-y-auto overflow-x-hidden">
        <div className='flex-col w-full'>
          <div className='font-bold text-3xl px-[60px] mt-11 py-2 -m-5 bg-[#00000019]'>
            <h1>Manage Supplier Products</h1>
          </div>
          <div className='flex justify-end mt-10 mr-10'>
            <Button variant="contained" color="success" style={{ backgroundColor: '#122f4b', marginRight: '12px' }} startIcon={<MoveUpIcon />} onClick={handleTransOpen}>Change Products</Button>
            <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={handleClickOpen}>Allocate Products to suppliers</Button>
            <Dialog
              open={open}
              aria-labelledby="form-dialog-title"
              disableEscapeKeyDown={true}
              BackdropProps={{
                style: { backdropFilter: 'blur(5px)' },
                invisible: true // This will prevent backdrop click
              }}
              maxWidth="xs" // Ensure consistent width
              fullWidth
              disableAutoFocus
              disableEnforceFocus
            >
              <DialogTitle id="form-dialog-title" className='text-center font-extrabold'>Allocate Products to Supplier</DialogTitle>
              <div className='mb-3'>
                <DialogContent>
                  <div className='mb-10'>
                    <Grid item xs={9.5}>
                      <Autocomplete
                        autoHighlight
                        options={supplierList}
                        getOptionLabel={(option) => formatSupplierName(option)}
                        filterOptions={(options, { inputValue }) => options.filter(supplier =>
                          supplier.FirstName.toLowerCase().includes(inputValue.toLowerCase()) ||
                          supplier.Username.toLowerCase().includes(inputValue.toLowerCase()) ||
                          supplier.UserID.toString().includes(inputValue)
                        )}
                        onChange={(e, newValue) => {
                          setSelectedSupplier(newValue);
                        }}
                        renderOption={(props, option) => (
                          <Box {...props}>
                            {formatSupplierName(option)}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Supplier"
                            error={(Boolean(errors[`supplier`]))}
                            helperText={errors[`supplier`]}
                            inputProps={{
                              ...params.inputProps,
                              onInput: (event) => {
                                event.target.value = event.target.value.toUpperCase().replace(/[^A-Z0-9\s]/g, ''); // Convert to uppercase and remove non-uppercase characters
                              }
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </div>

                  <div className='mb-2'>Select Products:</div>

                  <Grid container spacing={2}>
                    {stockEntries.map((entry, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={9.5}>
                          <Autocomplete
                            autoHighlight
                            options={productList.filter(option =>
                              !stockEntries.some((entry, i) => i !== index && entry.item === option.ItemCode)
                            )}
                            getOptionLabel={(option) => `${option.ItemCode} - ${option.Name}`}
                            filterOptions={(options, { inputValue }) => options.filter(item => item.Name.includes(inputValue) || item.ItemCode.toString().includes(inputValue))}
                            onChange={(e, newValue) => {
                              const selectedItemCode = newValue ? newValue.ItemCode : ''; // Extract ItemCode from newValue
                              handleStockEntryChange(index, { target: { name: 'item', value: selectedItemCode } }); // Pass only the ItemCode
                            }}
                            renderOption={(props, option) => (
                              <Box {...props}>
                                {option.ItemCode} - {option.Name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Item"
                                error={Boolean(errors[`item${index}`])}
                                helperText={errors[`item${index}`]}
                                inputProps={{
                                  ...params.inputProps,
                                  onInput: (event) => {
                                    event.target.value = event.target.value.toUpperCase().replace(/[^A-Z0-9\s]/g, ''); // Convert to uppercase and remove non-uppercase characters
                                  }
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={1} className="flex items-center">
                          {index !== 0 && (
                            <Button onClick={() => handleDeleteRow(index)} color="error" variant="contained">
                              <DeleteIcon />
                            </Button>
                          )}
                        </Grid>
                      </React.Fragment>
                    ))}
                    <Grid item xs={3} className="flex">
                      <Button onClick={handleAddRow} color="primary" variant="contained" fullWidth>
                        <AddIcon /> Add
                      </Button>
                    </Grid>
                  </Grid>

                </DialogContent>
              </div>
              <DialogActions className="mx-4 mb-4">
                <div className='w-full space-y-2'>
                  <Button onClick={handleAllocate} color="success" variant="contained" fullWidth >
                    Allocate
                  </Button>
                  <Button onClick={handleClose} color="error" variant="contained" fullWidth >
                    Cancel
                  </Button>
                </div>
              </DialogActions>
            </Dialog>

            <Dialog
              open={transopen}
              aria-labelledby="form-dialog-title"
              disableEscapeKeyDown={true}
              BackdropProps={{
                style: { backdropFilter: 'blur(5px)' },
                invisible: true // This will prevent backdrop click
              }}
              maxWidth="xs" // Ensure consistent width
              fullWidth
              disableAutoFocus
              disableEnforceFocus
            >
              <DialogTitle id="form-dialog-title" className='text-center font-extrabold'>Change Products for Supplier</DialogTitle>
              <div className='mb-3'>
                <DialogContent>
                  {/* Select Supplier */}
                  <Grid item xs={9.5}>
                    <Autocomplete
                      autoHighlight
                      options={supplierListexclude}
                      getOptionLabel={(option) => formatSupplierName(option)}
                      filterOptions={(options, { inputValue }) => options.filter(supplier =>
                        supplier.FirstName.toLowerCase().includes(inputValue.toLowerCase()) ||
                        supplier.Username.toLowerCase().includes(inputValue.toLowerCase()) ||
                        supplier.UserID.toString().includes(inputValue)
                      )}
                      onChange={(e, newValue) => {
                        setSelectedSupplier(newValue);
                      }}
                      renderOption={(props, option) => (
                        <Box {...props}>
                          {formatSupplierName(option)}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Supplier"
                          error={(Boolean(errors[`supplier`]))}
                          helperText={errors[`supplier`]}
                          inputProps={{
                            ...params.inputProps,
                            onInput: (event) => {
                              event.target.value = event.target.value.toUpperCase().replace(/[^A-Z0-9\s]/g, ''); // Convert to uppercase and remove non-uppercase characters
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Select Products */}
                  {supplierProductDetails.length > 0 && (
                    <div className='my-2'>Select Products:</div>
                  )}
                  <Grid container spacing={2}>
                    {supplierProductDetails.map((product, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={9.5}>
                          <Autocomplete
                            autoHighlight
                            value={productList.find(p => p.ItemCode === product.item) || null}
                            options={productList}
                            getOptionLabel={(option) => `${option.ItemCode} - ${option.Name}`}
                            filterOptions={(options, { inputValue }) => options.filter(item => item.Name.includes(inputValue) || item.ItemCode.toString().includes(inputValue))}
                            onChange={(e, newValue) => {
                              const selectedItemCode = newValue ? newValue.ItemCode : ''; // Extract ItemCode from newValue
                              handleEntryChange(index, { target: { name: 'item', value: selectedItemCode } }); // Pass only the ItemCode
                            }}
                            renderOption={(props, option) => (
                              <Box {...props}>
                                {option.ItemCode} - {option.Name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Item"
                                error={Boolean(errors[`item${index}`])}
                                helperText={errors[`item${index}`]}
                                inputProps={{
                                  ...params.inputProps,
                                  onInput: (event) => {
                                    event.target.value = event.target.value.toUpperCase().replace(/[^A-Z0-9\s]/g, ''); // Convert to uppercase and remove non-uppercase characters
                                  }
                                }}
                              />
                            )}
                            isOptionEqualToValue={(option, value) => {
                              return option.ItemCode === value.ItemCode;
                            }}

                          />
                        </Grid>
                        <Grid item xs={1} className="flex items-center">
                          <Button onClick={() => handleDeleteRowinchange(index)} color="error" variant="contained">
                            <DeleteIcon />
                          </Button>
                        </Grid>
                      </React.Fragment>
                    ))}
                    <Grid item xs={3} className="flex">
                      {selectedSupplier && (
                        <Button onClick={handleAddRowinchange} color="primary" variant="contained" fullWidth>
                          <AddIcon /> Add
                        </Button>
                      )}

                    </Grid>
                  </Grid>
                </DialogContent>
              </div>
              {/* Dialog Actions */}
              <DialogActions className="mx-4 mb-4">
                <div className='w-full space-y-2'>
                  <Button onClick={handleChange} color="success" variant="contained" fullWidth >
                    Change
                  </Button>
                  <Button onClick={handleTransClose} color="error" variant="contained" fullWidth >
                    Cancel
                  </Button>
                </div>
              </DialogActions>
            </Dialog>


          </div>
          <div className='p-10'>
            <Table />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierProduct;

