import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton, Collapse, Box, TextField, MenuItem, InputAdornment } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import Pagination from '@mui/material/Pagination';
import SearchIcon from '@mui/icons-material/Search';


const CompleteDispatchTable = () => {
    const [dispatchDetails, setDispatchDetails] = useState([]);
    const [expandedRow, setExpandedRow] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [FilteredDispatches, setFilteredDispatches] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Default to 10 items per page
    const [token, setToken] = useState(null);


    useEffect(() => {
        const storedData = localStorage.getItem('token');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            setToken(parsedData.token);
        }

        if (token) {
            fetch('https://ceramic-back.achila.tech/api/dispatch/completedispatchDetails', {
                headers: {
                    'Authorization': token,
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch dispatch details');
                    }
                    return response.json();
                })
                .then(data => {
                    const formattedData = data.map(dispatch => ({
                        ...dispatch,
                        id: dispatch.DispatchID,
                        RequestedTime: new Date(dispatch.RequestedTime).toLocaleString(),
                        items: dispatch.items.map(item => ({
                            ...item,
                        }))
                    }));
                    setDispatchDetails(formattedData);
                })
                .catch(error => {
                    console.error('Error fetching dispatch details:', error);
                });
        }
    }, [token]);


    const handleRowClick = (rowId) => {
        if (expandedRow === rowId) {
            setExpandedRow(null);
        } else {
            setExpandedRow(rowId);
        }
    };

    useEffect(() => {
        const filteredDispatch = dispatchDetails.filter(dispatch => {
            const items = dispatch.items || [];
            const matchesSearchQuery = items.some(item => {
                const itemCode = item.itemCode ? item.itemCode.toString().toLowerCase() : '';
                const itemName = item.itemName ? item.itemName.toString().toLowerCase() : '';
                const search = searchQuery.toLowerCase();
                return itemName.includes(search) || itemCode.includes(search);
            });
            return matchesSearchQuery;
        });

        setFilteredDispatches(filteredDispatch);
    }, [searchQuery, dispatchDetails]);


    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeItemsPerPage = (event) => {
        setItemsPerPage(event.target.value);
        setCurrentPage(1); // Reset to the first page when changing items per page
    };

    // Calculate pagination range
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = FilteredDispatches.slice(indexOfFirstItem, indexOfLastItem);

    console.log(currentItems);



    return (
        <div className="h-[55vh] flex ">
            <div className="w-full px-5 overflow-auto">
                <div className='mb-5'>
                    <div className='py-2 w-[20%]'>
                        <TextField
                            fullWidth
                            label="Search Name or ItemCode"
                            value={searchQuery}
                            onChange={(e) => {
                                const value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
                                setSearchQuery(value);
                            }}
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: searchQuery && (
                                    <IconButton onClick={() => setSearchQuery('')}>
                                        <ClearIcon />
                                    </IconButton>
                                ),
                            }}
                        />
                    </div>
                </div>
                <div className='mb-4 flex items-end justify-end'>
                    <div className='flex items-center justify-end mr-5'>
                        <span style={{ color: '#4E4E4E' }}>Rows per page: </span>
                        <TextField sx={{ minWidth: 60 }} size="small"
                            select
                            value={itemsPerPage}
                            onChange={handleChangeItemsPerPage}
                            variant="outlined"
                            className="w-18 left-2"
                        >
                            {[10, 25, 50, 100].map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className="flex items-end justify-end">
                        <Pagination
                            count={Math.ceil(FilteredDispatches.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handleChangePage}
                            variant="outlined"
                            shape="rounded"
                        />
                    </div>
                </div>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Dispatch ID</b></TableCell>
                                <TableCell><b>Request Date</b></TableCell>
                                <TableCell><b>Requested User</b></TableCell>
                                <TableCell><b>Approved User</b></TableCell>
                                <TableCell><b>Delivery Accepted User</b></TableCell>
                                <TableCell><b>Dispatch Status</b></TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentItems.map((dispatch) => (
                                <React.Fragment key={dispatch.id}>
                                    <TableRow onClick={() => handleRowClick(dispatch.id)}>
                                        <TableCell>{dispatch.DispatchID}</TableCell>
                                        <TableCell>{dispatch.RequestedTime}</TableCell>
                                        <TableCell>{dispatch.R_UserName}</TableCell>
                                        <TableCell>{dispatch.A_UserName}</TableCell>
                                        <TableCell>{dispatch.D_UserName}</TableCell>
                                        <TableCell>{dispatch.Status}</TableCell>
                                        <TableCell>
                                            <IconButton aria-label="expand row" size="small" onClick={() => handleRowClick(dispatch.id)}>
                                                {expandedRow === dispatch.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                                            <Collapse in={expandedRow === dispatch.id} timeout="auto" unmountOnExit>
                                                <Box margin={1} style={{ backgroundColor: '#F0F0F0', padding: '10px', borderRadius: '10px' }}>
                                                    <Typography variant="h6" gutterBottom component="div">
                                                        Product Details
                                                    </Typography>
                                                    <Table size="small" aria-label="purchases">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell><b>Item Name</b></TableCell>
                                                                <TableCell><b>Item Code</b></TableCell>
                                                                <TableCell><b>Quantity</b></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {dispatch.items.map((item, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>{item.itemName}</TableCell>
                                                                    <TableCell>{item.itemCode}</TableCell>
                                                                    <TableCell>{item.quantity}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div >
    );
};

export default CompleteDispatchTable;
