import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';

const PreviousOrders = () => {
    const [token, setToken] = useState(null);
    const [userID, setUserID] = useState(null);
    const [requests, setRequests] = useState([]);
    const statuses = ['Completed', 'PriceRejected', 'Declined'];
    const statusQuery = statuses.join(',');

    //extract userid from token and run fetch request when loading
    useEffect(() => {
        const storedData = localStorage.getItem('token');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            setToken(parsedData.token);

            try {
                const decodedToken = jwtDecode(parsedData.token);
                if (decodedToken) {
                    setUserID(decodedToken.userId);
                }
            } catch (error) {
                setUserID(null);
            }
        } else {
            setUserID(null);
        }

        if (token) {
            fetchRequests();
        }
    }, [token]);

    //fetch request data
    const fetchRequests = () => {
        fetch(`https://ceramic-back.achila.tech/api/supplier/requestspecific?UserID=${userID}&status=${statusQuery}`, {
            headers: {
                'Authorization': token,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch request details');
                }
                return response.json();
            })
            .then(data => {
                setRequests(data);
                console.log(data);
            })
            .catch(error => {
                console.error('Error fetching request details:', error);
            });
    };

    return (
        <div className="h-screen flex bg-[#F7F7F7]">
            <div className='w-20 h-screen'></div>
            <div className="flex w-full overflow-y-auto overflow-x-hidden">
                <div className='flex-col w-full'>
                    <div className='font-bold text-3xl px-[60px] mt-11 py-2 -m-5 bg-[#00000019]'>
                        <h1>Previous Orders</h1>
                    </div>
                    <div className='flex mt-10 px-10'>
                        {requests.length === 0 ? (
                            <div className="text-gray-500 text-lg">No Previous orders</div>
                        ) : (
                            requests.map(request => (
                                <div key={request.RequestID} className="flex flex-col w-full mb-4 p-4 bg-white rounded-lg shadow-md">
                                    <div className="flex items-center">
                                        <img src={request.photo} alt={request.ProductName} className="w-[250px] max-w-[250px]" />
                                        <div className="ml-8 mr-[80px] space-y-3 w-[40%]">
                                            <div>RequestID: {request.RequestID}</div>
                                            <div>ItemName: {request.ProductName}</div>
                                            <div>Quantity: {request.Quantity}</div>
                                            <div>Required date: {request.FormattedRequiredDate}</div>
                                        </div>
                                        <div className="border-l border-gray-300 h-full mr-[80px]"></div>
                                        <div className="flex space-x-10 w-full h-full items-center">
                                            <div>
                                                <button  className="bg-[green] text-white px-4 py-2 rounded-xl">
                                                    Order {request.Status}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PreviousOrders;
