import React from 'react'

const Showroom_settings = () => {
  return (
    <div className="h-screen flex bg-[#F7F7F7]"> 
      <div className='w-20 h-screen'>
      </div>
      <div className="">
        Settings
      </div>
    </div>
  )
}

export default Showroom_settings
