import React, { useState } from 'react';
import Table from '../../Components/showroom/OrderTable';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import DoneAllIcon from '@mui/icons-material/DoneAll';

const ShowroomOrders = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <div className="h-screen flex bg-[#F7F7F7]">
      <div className='w-20 h-screen'></div>
      <div className="flex w-full overflow-y-auto overflow-x-hidden">
        <div className='flex-col w-full'>
          <div className='font-bold text-3xl px-[60px] mt-11 py-2 -m-5 bg-[#00000019]'>
            <h1>Order History</h1>
          </div>
          <div className='flex justify-end mt-10 mr-10'>
            <Button variant="contained" color="success" style={{ backgroundColor: '#43004a' }} startIcon={<DoneAllIcon />} onClick={handleClickOpen}>Completed Orders</Button>
          </div> 
          <div className='px-10 pb-10'>
            <Table orderStatusFilter="Ordered"/>
          </div>
          <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            disableEscapeKeyDown={true}
            BackdropProps={{
              style: { backdropFilter: 'blur(5px)' },
              invisible: true // This will prevent backdrop click
            }}
            maxWidth="xl" 
            fullWidth
          >
            <DialogTitle id="form-dialog-title" className='text-center font-extrabold'>Completed Orders</DialogTitle>
            <div className='mb-3'>
            <DialogContent dividers style={{ maxHeight: '70vh' }}>
               <Table orderStatusFilter="Completed"/>
              </DialogContent>
            </div>
            <DialogActions className="mx-4 mb-4">
              <div className='w-full space-y-2'>
                <Button onClick={handleClose} color="error" variant="contained" fullWidth >
                  Cancel
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </div>
      </div >
    </div>
  );
};

export default ShowroomOrders;
