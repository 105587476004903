import React, { useState, useEffect } from 'react';
import Table from '../../Components/admin/RequestTable';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, MenuItem, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import Autocomplete from '@mui/material/Autocomplete';
import { jwtDecode } from 'jwt-decode';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Swal from 'sweetalert2';

const SupplyRequest = () => {
    const [open, setOpen] = useState(false);
    const [supplierProductDetails, setSupplierProductDetails] = useState([]);
    const [selectedProduct, setselectedProduct] = useState(null);
    const [FilteredProducts, setFilteredProducts] = useState(null);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [productList, setProductList] = useState([]);
    const [supplierListexclude, setsupplierListexclude] = useState([]);
    const [errors, setErrors] = useState({});
    const [token, setToken] = useState(null);
    const [userRole, setuserRole] = useState(null);
    const [userID, setuserID] = useState(null);
    const [quantity, setQuantity] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [note, setNote] = useState('');

    const handleQuantityChange = (event) => {
        let value = event.target.value.replace(/[^0-9]/g, '');  // Remove non-numeric characters

        // Ensure the value does not exceed 10 digits
        if (value.length > 10) {
            value = value.slice(0, 10);  // Take only the first 10 characters
        }

        setQuantity(value);  // Update the state with the sanitized value
    };


    const formatSupplierName = (supplier) => `${supplier.FirstName} ${supplier.LastName} (UserID: ${supplier.UserID})`;

    useEffect(() => {
        const storedData = localStorage.getItem('token');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            setToken(parsedData.token);

            try {
                const decodedToken = jwtDecode(parsedData.token);
                if (decodedToken) {
                    setuserRole(decodedToken.role);
                    setuserID(decodedToken.userId);
                }
            } catch (error) {
                setuserRole(null);
            }
        } else {
            setuserRole(null);
        }

        if (token) {
            fetchProductList();
            fetchsupplierListexclude();
        }
    }, [token]);

    useEffect(() => {
        if (open && selectedSupplier) {
            fetchSupplierProductDetails(selectedSupplier.SupplierID);
        } else {
            setSupplierProductDetails([]);
        }
    }, [open, selectedSupplier]);


    const fetchsupplierListexclude = async () => {
        try {
            const response = await fetch('https://ceramic-back.achila.tech/api/user/getsupplierexclude', {
                headers: {
                    'authorization': token,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setsupplierListexclude(data.users);
            } else {
                console.error('Failed to fetch supplier list');
            }
        } catch (error) {
            console.error('Error fetching supplier list:', error);
        }
    };


    const fetchSupplierProductDetails = (supplierID) => {
        fetch(`https://ceramic-back.achila.tech/api/supplier/supplierProduct/${supplierID}`, {
            headers: {
                'Authorization': token,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch supplier product details');
                }
                return response.json();
            })
            .then(data => {
                const list = data[0].products;
                const details = list.map(product => ({ item: product[0] }));
                setSupplierProductDetails(details);
                const filteredProductList = productList.filter(product =>
                    details.some(detail => detail.item === product.ItemCode)
                );
                setFilteredProducts(filteredProductList);
            })
            .catch(error => {
                console.error('Error fetching supplier product details:', error);
            });
    };

    const fetchProductList = async () => {
        try {
            const response = await fetch('https://ceramic-back.achila.tech/api/product/getproduct', {
                headers: {
                    'authorization': token,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setProductList(data.products);
            } else {
                console.error('Failed to fetch product list');
            }
        } catch (error) {
            console.error('Error fetching product list:', error);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedSupplier(null);
        setselectedProduct(null);
        setQuantity('');
        setSelectedDate(null);
        setNote('');
        setErrors({});
    };

    const validateForm = () => {
        const errors = {};

        if (!selectedSupplier) {
            errors[`supplier`] = 'Supplier is required';
        }
        if (!selectedProduct) {
            errors[`item`] = 'Product is required';
        }
        if (!quantity) {
            errors['quantity'] = 'Quantity is required';
        } else if (isNaN(quantity) || Number(quantity) <= 0) {
            errors['quantity'] = 'Quantity must be a positive number';
        }
        if (!selectedDate) {
            errors['date'] = 'Date is required';
        } else if (new Date(selectedDate) < new Date()) {
            errors['date'] = 'Date cannot be in the past';
        }

        setErrors(errors);
        return Object.keys(errors).length === 0; // Return true if there are no errors
    };


    const handleSend = async () => {
        if (validateForm()) {
            try {
                const response = await fetch('https://ceramic-back.achila.tech/api/supplier/sendrequest', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    body: JSON.stringify({
                        selectedProduct: selectedProduct,
                        selectedSupplier: selectedSupplier,
                        Quantity: quantity,
                        Date: selectedDate,
                        Note: note,
                        User: userID,
                    }),
                });

                if (response.ok) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Request Sent Successfully!',
                        customClass: {
                            popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                        },
                        didOpen: () => {
                            document.querySelector('.swal2-container').style.zIndex = '9999'; 
                        }
                    }).then(() => {
                        handleClose();
                        window.location.reload();
                    });
                } else {
                    const responseData = await response.json();
                    // Handle error response
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed to send request',
                        customClass: {
                            popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                        },
                        didOpen: () => {
                            document.querySelector('.swal2-container').style.zIndex = '9999'; 
                        },
                        text: responseData.message || 'An error occurred while sending request.',
                    });
                }
            } catch (error) {
                console.error('Error send request:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to send request',
                    customClass: {
                        popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                    },
                    didOpen: () => {
                        document.querySelector('.swal2-container').style.zIndex = '9999'; 
                    },
                    text: 'An error occurred while sending request.',
                });
            }
        }
    };




    return (
        <div className="h-screen flex bg-[#F7F7F7]">
            <div className='w-20 h-screen'></div>
            <div className="flex w-full overflow-y-auto overflow-x-hidden">
                <div className='flex-col w-full'>
                    <div className='font-bold text-3xl px-[60px] mt-11 py-2 -m-5 bg-[#00000019]'>
                        <h1>Request Supply From Suppliers</h1>
                    </div>
                    <div className='flex justify-end mt-10 mr-10'>
                        {/* <Button variant="contained" color="success" style={{ backgroundColor: '#122f4b', marginRight: '12px' }} startIcon={<MoveUpIcon />} onClick={handleTransOpen}>Change Products</Button> */}
                        <Button variant="contained" color="success" startIcon={<SendIcon />} onClick={handleClickOpen}>Send Request</Button>
                        <Dialog
                            open={open}
                            aria-labelledby="form-dialog-title"
                            disableEscapeKeyDown={true}
                            BackdropProps={{
                                style: { backdropFilter: 'blur(5px)' },
                                invisible: true // This will prevent backdrop click
                            }}
                            maxWidth="xs" // Ensure consistent width
                            fullWidth
                            disableAutoFocus
                            disableEnforceFocus
                        >
                            <DialogTitle id="form-dialog-title" className='text-center font-extrabold'>Send Request</DialogTitle>
                            <div className='mb-3'>
                                <DialogContent>
                                    <div className='mb-10'>
                                        <Grid item xs={9.5}>
                                            <Autocomplete
                                                autoHighlight
                                                options={supplierListexclude}
                                                getOptionLabel={(option) => formatSupplierName(option)}
                                                filterOptions={(options, { inputValue }) => options.filter(supplier =>
                                                    supplier.FirstName.toLowerCase().includes(inputValue.toLowerCase()) ||
                                                    supplier.Username.toLowerCase().includes(inputValue.toLowerCase()) ||
                                                    supplier.UserID.toString().includes(inputValue)
                                                )}
                                                onChange={(e, newValue) => {
                                                    setSelectedSupplier(newValue);
                                                }}
                                                renderOption={(props, option) => (
                                                    <Box {...props}>
                                                        {formatSupplierName(option)}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Select Supplier"
                                                        error={(Boolean(errors[`supplier`]))}
                                                        helperText={errors[`supplier`]}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            onInput: (event) => {
                                                                event.target.value = event.target.value.toUpperCase().replace(/[^A-Z0-9\s]/g, ''); // Convert to uppercase and remove non-uppercase characters
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </div>

                                    <div className='mb-2'>Select Product:</div>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                autoHighlight
                                                disabled={!selectedSupplier}
                                                options={FilteredProducts}
                                                getOptionLabel={(option) => `${option.ItemCode} - ${option.Name}`}
                                                filterOptions={(options, { inputValue }) => options.filter(item => item.Name.includes(inputValue) || item.ItemCode.toString().includes(inputValue))}
                                                onChange={(e, newValue) => {
                                                    const selectedItemCode = newValue ? newValue.ItemCode : ''; // Extract ItemCode from newValue
                                                    setselectedProduct(selectedItemCode); // Pass only the ItemCode
                                                }}
                                                renderOption={(props, option) => (
                                                    <Box {...props}>
                                                        {option.ItemCode} - {option.Name}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Select Product"
                                                        error={(Boolean(errors[`item`]))}
                                                        helperText={errors[`item`]}
                                                        disabled={!selectedSupplier}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            onInput: (event) => {
                                                                event.target.value = event.target.value.toUpperCase().replace(/[^A-Z0-9\s]/g, ''); // Convert to uppercase and remove non-uppercase characters
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Enter Quantity"
                                                value={quantity}
                                                disabled={!selectedSupplier}
                                                onChange={handleQuantityChange}
                                                error={Boolean(errors['quantity'])}
                                                helperText={errors['quantity']}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                    pattern: '[0-9]*',
                                                }}
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <DatePicker
                                                selected={selectedDate}
                                                disabled={!selectedSupplier}
                                                onChange={(date) => setSelectedDate(date)}
                                                minDate={new Date()} // Set the minimum selectable date to today
                                                customInput={
                                                    <TextField
                                                        label="Select Date"
                                                        error={Boolean(errors['date'])}
                                                        helperText={errors['date']}
                                                        fullWidth
                                                    />
                                                }
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                label="Notes"
                                                value={note}
                                                disabled={!selectedSupplier}
                                                onChange={(e) => {
                                                    const value = e.target.value.slice(0, 100); // Limit to 100 characters
                                                    setNote(value); // Update state with truncated value
                                                }}
                                                multiline
                                                rows={4}
                                                error={Boolean(errors['note'])}
                                                helperText={errors['note']}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                            </div>
                            <DialogActions className="mx-4 mb-4">
                                <div className='w-full space-y-2'>
                                    <Button onClick={handleSend} color="success" variant="contained" fullWidth >
                                        Send
                                    </Button>
                                    <Button onClick={handleClose} color="error" variant="contained" fullWidth >
                                        Cancel
                                    </Button>
                                </div>
                            </DialogActions>
                        </Dialog>

                    </div>
                    <div className='p-10'>
                        <Table />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SupplyRequest;

