import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton, Collapse, Box, TextField, MenuItem, InputAdornment, Button } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import Pagination from '@mui/material/Pagination';
import SearchIcon from '@mui/icons-material/Search';
import { jwtDecode } from 'jwt-decode';

const ReturnTable = () => {
    const [requests, setRequests] = useState([]);
    const [expandedRow, setExpandedRow] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredRequests, setfilteredRequests] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Default to 10 items per page
    const [token, setToken] = useState(null);

    useEffect(() => {
        const storedData = localStorage.getItem('token');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            setToken(parsedData.token);
        }

        if (token) {
            fetchrequests();
        }
    }, [token]);

    const fetchrequests = () => {
        fetch('https://ceramic-back.achila.tech/api/order/returndetails', {
            headers: {
                'Authorization': token,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch return details');
                }
                return response.json();
            })
            .then(data => {
                setRequests(data);
            })
            .catch(error => {
                console.error('Error fetching request details:', error);
            });
    };

    const handleRowClick = (rowId) => {
        if (expandedRow === rowId) {
            setExpandedRow(null);
        } else {
            setExpandedRow(rowId);
        }
    };

    useEffect(() => {
        const filteredItems = requests.filter(request => {
            const ItemCode = request.ItemCode.toString().toLowerCase();
            const OrderID = request.OrderID.toString().toLowerCase();
            const Name = request.ItemName?.toString().toLowerCase() || '';
            const search = searchQuery.toLowerCase();
            const matchesSearchQuery = Name.includes(search) || Name === search || ItemCode.includes(search) || ItemCode === search || OrderID.includes(search) || OrderID === search;
            // const matchesCategories = categoryfilter.length === 0 || categoryfilter.some(category => category.title === item.category);
            // const matchesWarehouses = warehousefilter.length === 0 || warehousefilter.some(warehouse => warehouse.title === item.warehouse);
            // const matchesAmountRange = item.quanity >= minValue && item.quanity <= maxValue;
            return matchesSearchQuery;
        });

        setfilteredRequests(filteredItems);
    }, [searchQuery, requests]);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeItemsPerPage = (event) => {
        setItemsPerPage(event.target.value);
        setCurrentPage(1); // Reset to the first page when changing items per page
    };

    function formatDate(dateString) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
        const date = new Date(dateString);
        return date.toLocaleDateString(undefined, options).replace(',', '');
    }
    

    // Calculate pagination range
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredRequests.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div className="h-screen flex bg-[#F7F7F7]">
            <div className="w-full px-5 overflow-auto">
                <div className='mb-5'>
                    <div className='py-2 w-[20%]'>
                        <TextField
                            fullWidth
                            label="Search Item Name or ItemCode or OrderID"
                            value={searchQuery}
                            onChange={(e) => {
                                const value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
                                setSearchQuery(value);
                            }}
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: searchQuery && (
                                    <IconButton onClick={() => setSearchQuery('')}>
                                        <ClearIcon />
                                    </IconButton>
                                ),
                            }}
                        />
                    </div>
                </div>
                <div className='mb-4 flex items-end justify-end'>
                    <div className='flex items-center justify-end mr-5'>
                        <span style={{ color: '#4E4E4E' }}>Rows per page: </span>
                        <TextField sx={{ minWidth: 60 }} size="small"
                            select
                            value={itemsPerPage}
                            onChange={handleChangeItemsPerPage}
                            variant="outlined"
                            className="w-18 left-2"
                        >
                            {[10, 25, 50, 100].map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className="flex items-end justify-end">
                        <Pagination
                            count={Math.ceil(filteredRequests.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handleChangePage}
                            variant="outlined"
                            shape="rounded"
                        />
                    </div>
                </div>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Return ID</b></TableCell>
                                <TableCell><b>Return Date</b></TableCell>
                                <TableCell><b>Order ID</b></TableCell>
                                <TableCell><b>User Name</b></TableCell>
                                <TableCell><b>Manager Name</b></TableCell>
                                <TableCell><b>Return Amount (LKR)</b></TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentItems.map((request) => (
                                <React.Fragment key={request.ReturnID}>
                                    <TableRow onClick={() => handleRowClick(request.ReturnID)}>
                                        <TableCell>{request.ReturnID}</TableCell>
                                        <TableCell>{formatDate(request.ReturnDate)}</TableCell>
                                        <TableCell>{request.OrderID}</TableCell>
                                        <TableCell>{request.UserName}</TableCell>
                                        <TableCell>{request.ManagerName}</TableCell>
                                        <TableCell>{request.ReturnAmount}</TableCell>
                                        <TableCell>
                                            <IconButton aria-label="expand row" size="small" onClick={() => handleRowClick(request.ReturnID)}>
                                                {expandedRow === request.ReturnID ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                                            <Collapse in={expandedRow === request.ReturnID} timeout="auto" unmountOnExit>
                                                <Box margin={1} style={{ backgroundColor: '#F0F0F0', padding: '10px', borderRadius: '10px' }}>
                                                    <Typography variant="h6" gutterBottom component="div">
                                                        Product Details
                                                    </Typography>
                                                    <Table size="small" aria-label="purchases">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell><b>Item Code</b></TableCell>
                                                                <TableCell><b>Item Name</b></TableCell>
                                                                <TableCell><b>Quantity</b></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell>{request.ItemCode}</TableCell>
                                                                <TableCell>{request.ItemName}</TableCell>
                                                                <TableCell>{request.Quantity}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default ReturnTable;


