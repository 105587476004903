import React, { useEffect, useState } from 'react';
import SearchBar from '../../Components/showroom/SearchBar';
import CategoryTiles from '../../Components/showroom/CategoryTiles';
import ItemTiles from '../../Components/HomeItemCard';
import PopularIcon from '../../Assets/icons/popular.png';
import DefaultIcon from '../../Assets/icons/gallery.png';
import WallIcon from '../../Assets/icons/wall tiles.png';
import FloorIcon from '../../Assets/icons/floor tiles.png';
import ClosetIcon from '../../Assets/icons/closets.png';
import BasinIcon from '../../Assets/icons/basins.png';
import BidetsIcon from '../../Assets/icons/bidets.png';
import BathIcon from '../../Assets/icons/bath.png';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { Link, useLocation } from 'react-router-dom';

const Product = () => {
    const [products, setProducts] = useState([]);
    const [category, setCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [searchText, setSearchText] = useState('');
    const [sortOption, setSortOption] = useState('NewestFirst');
    const [suggestions, setSuggestions] = useState([]);
    const location = useLocation();
    const [activeLink, setActiveLink] = useState('');


    useEffect(() => {
        fetchProducts();
        fetchCategories();
    }, []);

    const fetchProducts = async () => {
        try {
            const response = await fetch('https://ceramic-back.achila.tech/api/home/getproduct');

            if (response.ok) {
                const data = await response.json();
                setProducts(data.products || []); // Ensure data.products is an array
            } else {
                console.error('Failed to fetch products');
            }
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await fetch('https://ceramic-back.achila.tech/api/home/get');
            if (response.ok) {
                const data = await response.json();
                setCategory(data.categories || []); // Ensure data.categories is an array
            } else {
                console.error('Failed to fetch categories');
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const fetchPopularProducts = async () => {
        try {
            const response = await fetch('https://ceramic-back.achila.tech/api/home/popular');
            if (response.ok) {
                const data = await response.json();
                setProducts(data.popularProducts || []); // Ensure data.popularProducts is an array
            } else {
                console.error('Failed to fetch popular products');
            }
        } catch (error) {
            console.error('Error fetching popular products:', error);
        }
    };

    const handleCategoryClick = (categoryName) => {
        if (categoryName === 'Popular') {
            fetchPopularProducts();
        } else {
            fetchProducts();
        }
        setSelectedCategory(categoryName);
    };

    const handleSearch = (text) => {
        setSearchText(text);
        if (text.length > 0) {
            const matchedSuggestions = products
                .filter((product) =>
                    product.Name.toLowerCase().includes(text.toLowerCase()) ||
                    product.ItemCode.toLowerCase().includes(text.toLowerCase())
                )
                .slice(0, 5); // Limit the number of suggestions
            setSuggestions(matchedSuggestions);
        } else {
            setSuggestions([]);
        }
    };

    const handleSelectSuggestion = (suggestion) => {
        setSearchText(suggestion.Name);
        setSuggestions([]);
    };

    const handleSortChange = (event) => {
        setSortOption(event.target.value);
    };

    const getIconForCategory = (categoryName) => {
        switch (categoryName) {
            case 'Wall Tiles':
                return { icon: WallIcon, isIconComponent: false };
            case 'Floor Tiles':
                return { icon: FloorIcon, isIconComponent: false };
            case 'Water Closets':
                return { icon: ClosetIcon, isIconComponent: false };
            case 'Wash Basins':
                return { icon: BasinIcon, isIconComponent: false };
            case 'Bidets':
                return { icon: BidetsIcon, isIconComponent: false };
            case 'Bath & Shower':
                return { icon: BathIcon, isIconComponent: false };
            default:
                return { icon: DefaultIcon, isIconComponent: false };
        }
    };

    //filter products
    const filteredProducts = products
        .filter((product) =>
            (selectedCategory === 'Popular' || selectedCategory === 'All') || product.CName === selectedCategory
        )
        .filter((product) =>
            product.Name.toLowerCase().includes(searchText.toLowerCase()) ||
            product.ItemCode.toLowerCase().includes(searchText.toLowerCase())
        );


    const sortedProducts = selectedCategory === 'Popular' ? filteredProducts : [...filteredProducts].sort((a, b) => {
        switch (sortOption) {
            case 'A-Z':
                return a.Name.localeCompare(b.Name);
            case 'Z-A':
                return b.Name.localeCompare(a.Name);
            case 'PriceLowHigh':
                return a.Price - b.Price;
            case 'PriceHighLow':
                return b.Price - a.Price;
            case 'NewestFirst':
                return new Date(b.created_at) - new Date(a.created_at);
            case 'OldestFirst':
                return new Date(a.created_at) - new Date(b.created_at);
            default:
                return 0;
        }
    });

    return (
        <div className="h-screen flex bg-[#F7F7F7]">
            <style>
                {`
          html {
            scroll-behavior: smooth;
          }

          .text-gradient {
            background-image: radial-gradient(circle, transparent 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0.8) 80%, rgba(255,255,255,0.9) 100%),linear-gradient(90deg, #000000, #241e2f, #40484a);
            background-size: 200% auto;
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
            animation: gradient 10s linear infinite;
          }

          @keyframes gradient {
            0% {
              background-position: 100% 50%; 
            }
            50% {
              background-position: 0% 50%; 
            }
            100% {
              background-position: 100% 50%; 
            }
          }
        `}
            </style>
            <nav className="bg-[#292929] shadow fixed w-full top-0 z-10">
                <div className="container mx-auto flex justify-between items-center py-8" style={{ fontFamily: 'Rajdhani, sans-serif' }}>
                    <div className="flex items-center">
                        <Link to="/" className="text-3xl font-bold mr-10 ml-10">
                            <span className=" text-gray-200">Ramitha Ceramics</span>
                        </Link>
                    </div>
                    <ul className="flex font-bold space-x-20 mr-20 text-gray-500">
                        <li><Link to="/#home" className={`hover:text-gray-200 ${activeLink === '#home' ? 'text-white' : ''}`}>HOME</Link></li>
                        <li><Link to="/products" className={`hover:text-gray-200 ${location.pathname === '/products' ? 'text-white' : ''}`}>PRODUCTS</Link></li>
                    </ul>
                </div>
            </nav>
            <div className="flex w-full overflow-y-auto px-5">
                <div className="w-full mt-[5%]">
                    <SearchBar
                        onSearch={handleSearch}
                        suggestions={suggestions}
                        onSelectSuggestion={handleSelectSuggestion}
                        setSuggestions={setSuggestions}  // Pass setSuggestions function
                    />
                    <h1 className="text-[#2B2B2B] font-semibold text-xl mx-8 mt-8">Choose Category</h1>
                    <div className="mx-8 overflow-x-auto overflow-y-hidden whitespace-nowrap" style={{ height: '140px', scrollbarWidth: 'none', '-ms-overflow-style': 'none' }}>
                        <div className="flex space-x-[52px]">
                            <CategoryTiles
                                icon={EventNoteIcon}
                                text={"All"}
                                isIconComponent={true}
                                onClick={() => handleCategoryClick('All')}
                                isSelected={selectedCategory === 'All'}
                            />
                            <CategoryTiles
                                icon={PopularIcon}
                                text={"Popular"}
                                onClick={() => handleCategoryClick('Popular')}
                                isSelected={selectedCategory === 'Popular'}
                            />
                            {category?.length > 0 ? (
                                category.map((category, index) => {
                                    const { icon, isIconComponent } = getIconForCategory(category.Name);
                                    return (
                                        <CategoryTiles
                                            key={index}
                                            icon={icon}
                                            text={category.Name}
                                            isIconComponent={isIconComponent}
                                            onClick={() => handleCategoryClick(category.Name)}
                                            isSelected={selectedCategory === category.Name}
                                        />
                                    );
                                })
                            ) : (
                                <p>No categories available.</p>
                            )}
                        </div>
                    </div>

                    <div className="flex justify-end mt-8 mx-8">
                        {selectedCategory !== 'Popular' && (
                            <>
                                <p className="text-[#000000] text-[14px] mr-3">Sort by :</p>
                                <select
                                    value={sortOption}
                                    onChange={handleSortChange}
                                    className="text-[#000000] text-[14px] border border-gray-300 rounded px-2 mb-4 h-5"
                                >
                                    <option value="A-Z">Name (A-Z)</option>
                                    <option value="Z-A">Name (Z-A)</option>
                                    <option value="PriceLowHigh">Price (low to high)</option>
                                    <option value="PriceHighLow">Price (high to low)</option>
                                    <option value="NewestFirst">Date Added (newest first)</option>
                                    <option value="OldestFirst">Date Added (oldest first)</option>
                                </select>
                            </>
                        )}
                    </div>

                    <div className="gap-5 ml-8 mr-4 overflow-y-auto mt-2 flex flex-wrap" style={{ maxHeight: '62vh', height: '100%', paddingRight: '16px', boxSizing: 'content-box' }}>
                        {sortedProducts?.length > 0 ? (
                            sortedProducts.map((product, index) => (
                                <ItemTiles
                                    key={index}
                                    image={product.Photo}
                                    name={product.Name}
                                    icode={product.ItemCode}
                                    price={product.Price}
                                    description={product.Description}
                                    category={product.CName}
                                />
                            ))
                        ) : (
                            <p>No products available.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Product;
