import React, { useState, useEffect } from 'react';
import { Tab, Tabs, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import AdminProducts from './Products';
import AdminCategories from './Categories';
import { jwtDecode } from 'jwt-decode';

const TabbedView = () => {
  const [activeTab, setActiveTab] = useState('products');
  const [loaded, setLoaded] = useState(false); // State to track whether the component has finished loading
  const navigate = useNavigate();
  const location = useLocation();
  const [userRole, setuserRole] = useState();

  useEffect(() => {
    const segments = location.pathname.split('/');
    const lastSegment = segments[segments.length - 1];
    setActiveTab(lastSegment);
    setLoaded(true); // Set loaded to true after updating active tab
  }, [location]);

  const checkUserRole = async () => {
    const storedData = localStorage.getItem('token');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const token = parsedData.token;
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken) {
          setuserRole(decodedToken.role);
        }
      } catch (error) {
        setuserRole(null);
      }
    } else {
      setuserRole(null);
    }
    //setIsLoading(false); // Set loading state to false once authentication check is done
  };

  useEffect(() => {
    checkUserRole();
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    if (userRole === 'Admin') {
      if (newValue === "categories") {
        navigate(`/admin-dashboard/products/categories`);
      } else {
        navigate(`/admin-dashboard/${newValue}`);
      }
    } else if (userRole === 'Warehouse Staff') {
      if (newValue === "categories") {
        navigate(`/warehouse-dashboard/products/categories`);
      } else {
        navigate(`/warehouse-dashboard/${newValue}`);
      }
    }
  };

  return (
    <Box className="h-screen flex bg-[#F7F7F7]">
      <div className='w-20 h-screen'></div>
      <div className="flex w-full overflow-y-auto px-4 pt-4 overflow-x-hidden">
        <div className='flex-col w-full'>
          <div className=' font-bold text-3xl px-[60px] mt-7 py-2 -m-5 mb-5 bg-[#00000019]'>
            <h1>Product Details</h1>
          </div>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Tabs value={activeTab} onChange={handleTabChange}>
              <Tab label="Products" value="products" sx={{ fontWeight: 'bold' }} />
              <Tab label="Categories" value="categories" sx={{ fontWeight: 'bold' }} />
            </Tabs>
          </Box>
          <div>
            {loaded && ( // Only render tab content if component has finished loading
              <>
                {activeTab === 'products' && <AdminProducts />}
                {activeTab === 'categories' && <AdminCategories />}
              </>
            )}
          </div>
        </div>
      </div>
    </Box>
  );
};

export default TabbedView;
