import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Select, MenuItem, FormControl, InputLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Pagination, InputAdornment, Autocomplete } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Swal from 'sweetalert2'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTimesCircle } from '@fortawesome/free-solid-svg-icons';


function createData(id, username, firstName, lastName, email, phoneNumber, address, role, nic, WarehouseID) {
  return {
    id,
    username,
    firstName,
    lastName,
    email,
    phoneNumber,
    address,
    role,
    nic,
    WarehouseID
  };
}

const storedData = localStorage.getItem('token');
let token;

if (storedData) {
  const parsedData = JSON.parse(storedData);
  token = parsedData.token;
} else {// Handle the case where the token is not stored
  token = null;
}


function Row({ row, isOpen, onExpand }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false); // State for toggling new password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State for toggling confirm password visibility
  const [errors, setErrors] = useState({});
  const [userData, setUserData] = useState({
    Username: row.username,
    Password: row.Password,
    FirstName: row.firstName,
    LastName: row.lastName,
    NIC: row.nic,
    Email: row.email,
    PhoneNumber: row.phoneNumber,
    Address: row.address,
    Role: row.role,
    WarehouseID: row.WarehouseID
  });

  useEffect(() => { //when the expand is open do these things
    setUserData({
      Username: row.username,
      Password: row.Password,
      FirstName: row.firstName,
      LastName: row.lastName,
      NIC: row.nic,
      Email: row.email,
      PhoneNumber: row.phoneNumber,
      Address: row.address,
      Role: row.role,
      WarehouseID: row.WarehouseID
    });
    setErrors({});
  }, [row, isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === 'FirstName' || name === 'LastName') {
      newValue = newValue.slice(0, 20); // Limit to 20 characters for Address
      newValue = newValue.replace(/[0-9]/g, ''); // Allow only alphabets
    } else if (name === 'PhoneNumber') {
      newValue = newValue.slice(0, 10);
      newValue = newValue.replace(/[^0-9]/g, ''); // Allow only numbers
    } else if (name === 'Username') {
      newValue = newValue.slice(0, 25); // Limit to 25 characters for Username
      newValue = newValue.replace(/\s/g, ''); // Remove spaces
    } else if (name === 'Address') {
      newValue = newValue.slice(0, 100); // Limit to 100 characters for Address
    } else if (name === 'Email') {
      newValue = newValue.slice(0, 50); // Limit to 50 characters for Email
    } else if (name === 'NIC') {
      newValue = newValue.slice(0, 12); // Limit to 12 characters for NIC
    }
    setUserData({ ...userData, [name]: newValue });
    setErrors({ ...errors, [name]: '' }); // Clear the error when input changes
  };

  const validateForm = () => {
    const errors = {};
    if (!userData.FirstName.trim()) {
      errors.FirstName = 'First Name is required';
    }
    if (!userData.LastName.trim()) {
      errors.LastName = 'Last Name is required';
    }
    if (!userData.NIC.trim()) {
      errors.NIC = 'NIC is required';
    } else if (!/^\d{9}[vVxX]?$/.test(userData.NIC) && !/^\d{12}$/.test(userData.NIC)) {
      errors.NIC = 'Invalid NIC format';
    }
    if (!userData.Email) {
      userData.Email = "";
    } else if (!/\S+@\S+\.\S+/.test(userData.Email)) {
      errors.Email = 'Invalid email address';
    }
    if (userData.Role==='Supplier' && !userData.Email.trim()) {
      errors.Email = 'Email is required';
    }
    if (!userData.PhoneNumber.trim()) {
      errors.PhoneNumber = 'Phone Number is required';
    } else if (!/^0\d{9}$/.test(userData.PhoneNumber)) {
      errors.PhoneNumber = 'Invalid phone number';
    }
    if (!userData.Address.trim()) {
      errors.Address = 'Address is required';
    }
    if (!userData.Role.trim()) {
      errors.Role = 'Role is required';
    }
    if ((userData.Role === 'Warehouse Staff' || userData.Role === 'Manager') && (!userData.WarehouseID)) {
      errors.Warehouse = 'Warehouse is required';
    }
    if (!userData.Username.trim()) {
      errors.Username = 'Username is required';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0; // Return true if there are no errors
  };

  const handleUpdate = async () => {
    if (validateForm()) {
      try {
        const confirmed = await Swal.fire({
          icon: 'warning',
          title: 'Are you sure?',
          text: 'You are about to update this user. This action cannot be undone.',
          showCancelButton: true,
          confirmButtonText: 'Yes, update it!',
          cancelButtonText: 'Cancel',
        });

        if (confirmed.isConfirmed) {
          const response = await fetch(`https://ceramic-back.achila.tech/api/user/update/${row.id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'authorization': token,
            },
            body: JSON.stringify(userData),
          });

          if (response.ok) {
            // User updated successfully
            const data = await response.json();
            const userId = data.UserId;
            console.log('User Updated:', userId);
            Swal.fire({
              icon: 'success',
              title: 'User Updated Successfully!',
              customClass: {
                popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
              },
              didOpen: () => {
                document.querySelector('.swal2-container').style.zIndex = '9999'; 
              }
            }).then(() => {
              window.location.reload(); // Reload the page after successful deletion
            });
          } else if (response.status === 400) {// Error due to existing fields
            const { errors } = await response.json();
            // Handle specific error cases
            if (errors.Username) {
              Swal.fire({
                icon: 'error',
                title: 'User Updating Failed',
                text: errors.Username,
                customClass: {
                  popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                },
                didOpen: () => {
                  document.querySelector('.swal2-container').style.zIndex = '9999'; 
                }
              });
            } else if (errors.Email) {
              Swal.fire({
                icon: 'error',
                title: 'User Updating Failed',
                text: errors.Email,
                customClass: {
                  popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                },
                didOpen: () => {
                  document.querySelector('.swal2-container').style.zIndex = '9999'; 
                }
              });
            } else if (errors.NIC) {
              Swal.fire({
                icon: 'error',
                title: 'User Updating Failed',
                text: errors.NIC,
                customClass: {
                  popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                },
                didOpen: () => {
                  document.querySelector('.swal2-container').style.zIndex = '9999'; 
                }
              });
            } else if (errors.PhoneNumber) {
              Swal.fire({
                icon: 'error',
                title: 'User Updating Failed',
                text: errors.PhoneNumber,
                customClass: {
                  popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                },
                didOpen: () => {
                  document.querySelector('.swal2-container').style.zIndex = '9999'; 
                }
              });
            } else {
              // Handle other validation errors
              Swal.fire({
                icon: 'error',
                title: 'User Updating Failed',
                text: '',
                html: Object.values(errors).map(error => `<div>${error}</div>`).join(''),
                customClass: {
                  popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                },
                didOpen: () => {
                  document.querySelector('.swal2-container').style.zIndex = '9999'; 
                }
              });
            }
          } else {
            // Other server-side error
            console.error('Failed to Update user');
            Swal.fire({
              icon: 'error',
              title: 'User Updating Failed',
              text: 'An error occurred while Updating the user.',
              customClass: {
                popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
              },
              didOpen: () => {
                document.querySelector('.swal2-container').style.zIndex = '9999'; 
              }
            });
          }
        }
      } catch (error) {
        // Handle network or unexpected errors
        console.error('Error Updating user:', error);
        let errorMessage = 'An error occurred while Updating the user.';
        Swal.fire({
          icon: 'error',
          title: 'User Updating Failed',
          text: errorMessage,
          customClass: {
            popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
          },
          didOpen: () => {
            document.querySelector('.swal2-container').style.zIndex = '9999'; 
          }
        });
      }
    }
  };


  const handleResetPassword = () => {
    setOpenDialog(true);
  };

  const handleReset = async () => {
    let newPasswordError = '';
    let confirmPasswordError = '';

    // Check if newPassword and confirmPassword match
    if (newPassword !== confirmPassword) {
      confirmPasswordError = 'Passwords do not match';
    }

    // Check if newPassword meets your criteria, e.g., minimum length
    if (newPassword.length < 8 || newPassword.length > 15) {
      newPasswordError = 'Password must be between 8 and 15 characters';
    }

    // If there are errors, update the state to display them
    if (newPasswordError || confirmPasswordError) {
      setNewPasswordError(newPasswordError);
      setConfirmPasswordError(confirmPasswordError);
      return; // Don't proceed further if there are errors
    }

    try {
      const response = await fetch(`https://ceramic-back.achila.tech/api/user/resetpass/${row.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': token,
        },
        body: JSON.stringify({ newPassword }), // Pass newPassword as an object property
      })

      if (response.ok) {
        const data = await response.json();
        const userId = data.UserId;

        console.log('Password reset successfully for user ID:', userId);
        setOpenDialog(false);
        // Show success message using SweetAlert with user ID
        Swal.fire({
          icon: 'success',
          title: 'Password Reset Successful',
          text: `Password reset successfully for user ID: ${userId}`,
        });
        handleCancel();
      } else if (response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Password Reset Failed',
          text: 'New password cannot be the same as the current password.',
          didOpen: () => {
            document.querySelector('.swal2-container').style.zIndex = '9999'; 
          }
        });

      } else if (response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'Password Reset Failed',
          didOpen: () => {
            document.querySelector('.swal2-container').style.zIndex = '9999'; 
          }
        });

      }
    } catch (error) {
      console.error('Error resetting password:', error);
      setOpenDialog(false);
      let errorMessage = 'An error occurred while resetting your password. Please try again later.';

      // Customize error message based on specific scenarios
      if (error.message === 'New password cannot be the same as the current password') {
        errorMessage = 'New password cannot be the same as the current password';
      }

      // Show error message using SweetAlert
      Swal.fire({
        icon: 'error',
        title: 'Password Reset Failed',
        text: errorMessage,
      });
    }
  };

  const handleDelete = async () => {
    try {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: 'Are you sure?',
        text: 'You are about to delete this user. This action cannot be undone.',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel',

      });

      if (confirmed.isConfirmed) {
        const response = await fetch(`https://ceramic-back.achila.tech/api/user/delete/${row.id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'authorization': token,
          },
        });

        if (response.ok) {
          // User deleted successfully
          const data = await response.json();
          const userId = data.UserId;
          console.log('User Deleted:', userId);
          Swal.fire({
            icon: 'success',
            title: 'User Deleted Successfully!',
            customClass: {
              popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
            },
            didOpen: () => {
              document.querySelector('.swal2-container').style.zIndex = '9999'; 
            }
          }).then(() => {
            window.location.reload(); // Reload the page after successful deletion
          });
        }
      } else {
        Swal.close();
      }
    } catch (error) {
      // Handle network or unexpected errors
      console.error('Error Deleting user:', error);
      let errorMessage = 'An error occurred while Deleting the user.';
      Swal.fire({
        icon: 'error',
        title: 'User Deleting Failed',
        text: errorMessage,
        customClass: {
          popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
        },
        didOpen: () => {
          document.querySelector('.swal2-container').style.zIndex = '9999'; 
        }
      });
    }
  };

  const handleCancel = () => {
    setNewPassword('');
    setConfirmPassword('');
    setNewPasswordError('');
    setConfirmPasswordError('');
    setOpenDialog(false);
  };



  return (
    <React.Fragment>
      {!isOpen && (
        <TableRow>
          <TableCell component="th" scope="row" align="left">
            {row.id}
          </TableCell>
          <TableCell align="left">{row.username}</TableCell>
          <TableCell align="left">{row.firstName}</TableCell>
          <TableCell align="left">{row.lastName}</TableCell>
          <TableCell align="left">{row.email}</TableCell>
          <TableCell align="left">{row.phoneNumber}</TableCell>
          <TableCell align="left">{row.address}</TableCell>
          <TableCell align="left">{row.role}</TableCell>
          <TableCell align="left">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => onExpand(row.id)}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      )}
      {isOpen && (
        <TableRow>
          <TableCell colSpan={9}>
            <div className="border border-gray-300 py-4 px-4 rounded-lg flex flex-col bg-[#ffffff]">
              <div className=' flex justify-end'>
                <IconButton
                  aria-label="collapse row"
                  size="small"
                  onClick={() => onExpand(null)}
                >
                  <KeyboardArrowUpIcon />
                </IconButton>
              </div>
              <div className='px-[70px] pt-12 pb-6'>
                <div className="flex justify-between mb-10">
                  <TextField variant="standard" label="UserID" defaultValue={row.id} style={{ width: '75px' }} InputProps={{ readOnly: true, disableUnderline: true }} />
                  <TextField variant="standard" label="First Name" value={userData.FirstName} name="FirstName" onChange={handleChange} error={Boolean(errors.FirstName)} helperText={errors.FirstName} />
                  <TextField variant="standard" label="Last Name" value={userData.LastName} name="LastName" onChange={handleChange} error={Boolean(errors.LastName)} helperText={errors.LastName} />
                  <TextField variant="standard" label="NIC" value={userData.NIC} style={{ width: '120px' }} name="NIC" onChange={handleChange} error={Boolean(errors.NIC)} helperText={errors.NIC} />
                  <TextField variant="standard" label="Phone Number" value={userData.PhoneNumber} style={{ width: '120px' }} name="PhoneNumber" onChange={handleChange} error={Boolean(errors.PhoneNumber)} helperText={errors.PhoneNumber} />
                  <FormControl variant="standard" style={{ width: '125px' }}>
                    <InputLabel id="role-label">Role</InputLabel>
                    <Select
                      labelId="role-label"
                      id="role"
                      name="Role"
                      value={userData.Role}
                      onChange={handleChange}
                      label="Role"
                      disabled={userData.Role === 'Supplier'}
                    >
                      <MenuItem value="Admin">Admin</MenuItem>
                      <MenuItem value="Manager">Manager</MenuItem>
                      <MenuItem value="Showroom Staff">Showroom Staff</MenuItem>
                      <MenuItem value="Warehouse Staff">Warehouse Staff</MenuItem>
                      <MenuItem value="Supplier" disabled={userData.Role !== 'Supplier'}>Supplier</MenuItem>
                    </Select>
                  </FormControl>
                  {['Warehouse Staff','Manager'].includes(userData.Role) && (
                    <FormControl variant="standard" style={{ width: '125px' }}>
                      <InputLabel id="warehouse-label">Warehouse</InputLabel>
                      <Select
                        labelId="warehouse-label"
                        id="warehouse"
                        name="WarehouseID"
                        value={userData.WarehouseID}
                        onChange={handleChange}
                        label="Warehouse"
                      >
                        {/* Replace with your warehouse options */}
                        <MenuItem value="1">Gurudeniya</MenuItem>
                        <MenuItem value="2">Kandy</MenuItem>
                      </Select>
                      {errors.Warehouse && <div style={{ color: 'red' }}>{errors.Warehouse}</div>}
                    </FormControl>
                  )}
                </div>
                <div className="flex justify-between mb-8">
                  <TextField variant="standard" label="Username" value={userData.Username} style={{ width: '15%' }} name="Username" onChange={handleChange} error={Boolean(errors.Username)} helperText={errors.Username} />
                  <TextField variant="standard" label="Email" value={userData.Email} style={{ width: '25%' }} name="Email" onChange={handleChange} error={Boolean(errors.Email)} helperText={errors.Email} />
                  <TextField variant="standard" label="Address" value={userData.Address} style={{ width: '50%' }} name="Address" onChange={handleChange} error={Boolean(errors.Address)} helperText={errors.Address} />
                </div>
                <div className="flex justify-end gap-4">
                  <Button variant="contained" color="primary" onClick={handleResetPassword} style={{ backgroundColor: '#122f4b', width: '18%' }}>Reset Password</Button>
                  <Button variant="contained" color="secondary" onClick={handleDelete} style={{ backgroundColor: '#F05756', width: '8%' }}>Delete</Button>
                  <Button variant="contained" color="primary" onClick={handleUpdate} style={{ backgroundColor: '#2D865B', width: '8%' }}>Update</Button>
                </div>
              </div>
            </div>
          </TableCell>
        </TableRow>
      )}
      <Dialog open={openDialog}
        disableEscapeKeyDown={true}
        BackdropProps={{
          style: { backdropFilter: 'blur(5px)' },
          invisible: true // This will prevent backdrop click
        }}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter your new password and confirm.
          </DialogContentText>
          <div style={{ width: '350px' }}>
            <TextField
              autoFocus
              margin="dense"
              label="New Password"
              type={showNewPassword ? 'text' : 'password'} // Toggle password visibility based on showNewPassword state
              fullWidth
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
                // Hide helper text if the input field is touched
                if (e.target.value.length > 0) {
                  setNewPasswordError('');
                }
              }}
              error={!!newPasswordError}
              helperText={newPasswordError}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
            <TextField
              margin="dense"
              label="Confirm Password"
              type={showConfirmPassword ? 'text' : 'password'} // Toggle password visibility based on showConfirmPassword state
              fullWidth
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                // Hide helper text if the input field is touched
                if (e.target.value.length > 0) {
                  setConfirmPasswordError('');
                }
              }}
              error={!!confirmPasswordError}
              helperText={confirmPasswordError}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleReset} color="primary">
            Reset
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string,
    phoneNumber: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    nic: PropTypes.string.isRequired
    // WarehouseID: (props, propName) => {
    //   // Check if the role is Manager or Warehouse Staff
    //   if ((props.role === 'Manager' || props.role === 'Warehouse Staff') && !props[propName]) {
    //     return new Error('WarehouseID is required for Manager and Warehouse Staff roles');
    //   }
    //   // WarehouseID is not required for other roles
    //   return null;
    // }
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
};

export default function CollapsibleTable() {
  const [expandedRow, setExpandedRow] = useState(null);
  const [userData, setUserData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [rolefilter, setroleFilter] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1); // Reset page to 1 whenever rows per page changes
  };

  const handleFilterClick = () => {
    setIsOpen(!isOpen);
  };

  const handleResetFilters = () => {
    setroleFilter([]);
  };

  useEffect(() => {
    const filteredUsers = userData.filter(user => {
      const userId = user.id.toString().toLowerCase();
      const username = user.username?.toString().toLowerCase() || '';
      const firstname = user.firstName?.toString().toLowerCase() || '';
      const pn = user.phoneNumber?.toString().toLowerCase() || '';
      const search = searchQuery.toLowerCase();
      const matchesSearchQuery = username.includes(search) || username === search || firstname.includes(search) || firstname === search || userId.includes(search) || userId === search || pn.includes(search) || pn === search;
      const matchesRoles = rolefilter.length === 0 || rolefilter.some(role => role.title === user.role);
    return matchesSearchQuery && matchesRoles;
    });

    setFilteredUsers(filteredUsers);
  }, [searchQuery, userData,rolefilter]);

  const handleFilterChange = (event, newValue) => {
    setroleFilter(newValue);
  };
  
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://ceramic-back.achila.tech/api/user/getUser', {
          headers: {
            'authorization': token,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const userData = data.users; // Access the users property
        if (Array.isArray(userData)) {
          const transformedData = userData.map(user => createData(
            user.UserID,
            user.Username,
            user.FirstName,
            user.LastName,
            user.Email,
            user.PhoneNumber,
            user.Address,
            user.Role,
            user.NIC,
            user.WarehouseID,
          ));
          setUserData(transformedData);

        } else {
          throw new Error('Data received is not in the expected format');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, []);

  const handleRowExpand = (rowId) => {
    if (expandedRow === rowId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowId);
    }
  };

  const roles = [
    { title: "Admin" },
    { title: "Manager" },
    { title: "Showroom Staff" },
    { title: "Warehouse Staff" },
    { title: "Supplier" }
  ];

  return (
    <div>
      <div className='mb-5 w-[20%]'>
        <TextField
          fullWidth
          label="Search Name or UserID or Phone Number"
          value={searchQuery}
          onChange={(e) => {
            const value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
            setSearchQuery(value);
          }}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchQuery && (
              <IconButton onClick={() => setSearchQuery('')}>
                <ClearIcon />
              </IconButton>
            ),
          }}
        />
      </div>
      <div>
        <button
          onClick={handleFilterClick}
          className="bg-[#000000] hover:bg-[#000000c1] text-white font-bold py-2 px-4 rounded-lg inline-flex items-center"
        >
          <FontAwesomeIcon icon={faFilter} className="mr-2" />
          Filter
        </button>
      </div>
      {isOpen && (
        <div className="bg-gray-200 p-5 rounded-lg inline-block  relative">
          <div className='w-[500px]'>
            <Autocomplete
              multiple
              id="filter-roles"
              options={roles}
              getOptionLabel={(option) => option.title}
              value={rolefilter}
              isOptionEqualToValue={(option, value) => option.title === value.title}
              onChange={handleFilterChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Filter Roles"
                  placeholder="Role"
                />
              )}
            />
          </div>
          <button
            onClick={handleResetFilters}
            className="bg-[#af4242] text-white font-bold py-2 px-4 rounded inline-flex items-center mt-5"
          >
            <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
            Reset Filters
          </button>
        </div>
      )}
      <div className='mb-4 flex items-end justify-end'>
        <div className='items-center flex mr-5'>
          <div><span style={{ color: '#4E4E4E' }}>Rows per page: &nbsp;</span></div>
          <FormControl sx={{ minWidth: 60 }} size="small">
            <Select
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
            >
              {[10, 20, 50].map((rows) => (
                <MenuItem key={rows} value={rows}>
                  {rows}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Pagination
          count={Math.ceil(filteredUsers.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          style={{ marginTop: '1rem' }}
        />
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow style={{ borderBottom: '2px solid black', background: '#F7F7F7' }}>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>User ID</TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>Username</TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>First Name</TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>Last Name</TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>Email</TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>Phone Number</TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>Address</TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>Role</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ background: '#F7F7F7' }}>
            {filteredUsers.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((user) => (
              <React.Fragment key={user.id}>
                <Row
                  row={user}
                  isOpen={expandedRow === user.id}
                  onExpand={handleRowExpand}
                />
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
