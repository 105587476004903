import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Select, MenuItem, FormControl, InputLabel, TextField, Pagination, InputAdornment, Autocomplete, Box, Typography, Slider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Swal from 'sweetalert2'
import { jwtDecode } from 'jwt-decode';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTimesCircle } from '@fortawesome/free-solid-svg-icons';



function createData(ItemCode, Name, Description, Price, Photo, Category, CName, Discount) {
  return {
    id: ItemCode,
    ItemCode,
    Name,
    Description,
    Price,
    Photo,
    Category,
    CName,
    Discount
  };
}

function Row({ row, isOpen, onExpand }) {
  const [errors, setErrors] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [userRole, setuserRole] = useState();
  const [warehouse, setwarehouse] = useState(null);
  const [itemData, setItemData] = useState({
    ItemCode: row.ItemCode,
    Name: row.Name,
    Description: row.Description,
    Price: row.Price,
    Photo: row.Photo,
    Category: row.Category,
    CName: row.CName,
    Discount: row.Discount
  });

  const storedData = localStorage.getItem('token');
  let token;

  if (storedData) {
    const parsedData = JSON.parse(storedData);
    token = parsedData.token;
  } else {// Handle the case where the token is not stored
    token = null;
  }

  const checkUserRole = async () => {
    try {
      const decodedToken = jwtDecode(token);
      if (decodedToken) {
        setuserRole(decodedToken.role);
        if (decodedToken.role === 'Warehouse Staff') {
          setwarehouse(decodedToken.WarehouseID);
        }
      }
    } catch (error) {
      setuserRole(null);
    }
  };

  useEffect(() => {
    checkUserRole();
  }, []);

  useEffect(() => { //when the expand is open do these things
    setItemData({
      ItemCode: row.ItemCode,
      Name: row.Name,
      Description: row.Description,
      Price: row.Price,
      Photo: row.Photo,
      Category: row.Category,
      CName: row.CName,
      Discount: row.Discount
    });
    setErrors({});
  }, [row, isOpen]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://ceramic-back.achila.tech/api/category/get', {
          headers: {
            'authorization': token,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch categories');
        }
        const data = await response.json();
        const CategoryData = data.categories;
        if (Array.isArray(CategoryData)) {
          const transformedData = CategoryData.map(category => createData(
            category.CategoryID,
            category.Name,
          ));
          setCategoryList(transformedData);
        } else {
          throw new Error('Data received is not in the expected format');
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, [token]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === 'ItemCode') {
      newValue = newValue.slice(0, 10); // Limit to 10 characters for Address
      newValue = newValue.replace(/[^a-zA-Z0-9]/g, ''); // Allow only alphabets and numbers
      newValue = newValue.replace(/\s/g, '');
    } else if (name === 'Name') {
      newValue = newValue.slice(0, 20).toUpperCase();
    } else if (name === 'Price') {
      newValue = newValue.slice(0, 15);
      newValue = newValue.replace(/[^0-9.]/g, ''); // Allow only numbers and dots
      newValue = newValue.replace(/^(\d*\.\d{0,2}).*/, '$1'); // Allow only up to 2 decimal places
    } else if (name === 'Discount') {
      newValue = newValue.slice(0, 15); // Limit value to 15 characters
      newValue = newValue.replace(/^(\d*\.\d{0,2}).*/, '$1'); // Allow only up to 2 decimal places
    }

    setItemData({ ...itemData, [name]: newValue });
    setErrors({ ...errors, [name]: '' }); // Clear the error when input changes
  };

  const validateForm = () => {
    const errors = {};
    if (!itemData.ItemCode.trim()) {
      errors.ItemCode = 'Item Code is required';
    }
    if (!itemData.Name.trim()) {
      errors.Name = 'Item Name is required';
    }
    if (!itemData.Description.trim()) {
      errors.Description = 'Description is required';
    }
    if (!itemData.Price && itemData.Price !== 0) {
      errors.Price = 'Price is required';
    }
    if (!itemData.Category) {
      errors.Category = 'Category is required';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0; // Return true if there are no errors
  };

  const handleUpdate = async () => {
    if (validateForm()) {
      try {
        if (userRole === 'Admin' || userRole === 'Manager') {
          const confirmed = await Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: 'You are about to update this product. This action cannot be undone.',
            showCancelButton: true,
            confirmButtonText: 'Yes, update it!',
            cancelButtonText: 'Cancel',
          });

          if (confirmed.isConfirmed) {
            console.log(itemData);
            console.log(row.ItemCode);
            const response = await fetch(`https://ceramic-back.achila.tech/api/product/update/${row.ItemCode}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'authorization': token,
              },
              body: JSON.stringify(itemData),
            });

            if (response.ok) {
              // User updated successfully
              const data = await response.json();
              const userId = data.UserId;
              console.log('Product Updated:', userId);
              Swal.fire({
                icon: 'success',
                title: 'Product Updated Successfully!',
                customClass: {
                  popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                },
                didOpen: () => {
                  document.querySelector('.swal2-container').style.zIndex = '9999'; 
                }
              }).then(() => {
                window.location.reload(); // Reload the page after successful deletion
              });
            } else if (response.status === 400) {// Error due to existing fields
              const { errors } = await response.json();
              // Handle specific error cases
              if (errors.ItemCode) {
                Swal.fire({
                  icon: 'error',
                  title: 'Product Updating Failed',
                  text: errors.ItemCode,
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  }
                });
              } else if (errors.Name) {
                Swal.fire({
                  icon: 'error',
                  title: 'Product Updating Failed',
                  text: errors.Name,
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  }
                });
              } else if (errors.Description) {
                Swal.fire({
                  icon: 'error',
                  title: 'Product Updating Failed',
                  text: errors.Description,
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  }
                });
              } else if (errors.Price) {
                Swal.fire({
                  icon: 'error',
                  title: 'Product Updating Failed',
                  text: errors.Price,
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  }
                });
              } else if (errors.category) {
                Swal.fire({
                  icon: 'error',
                  title: 'Product Updating Failed',
                  text: errors.category,
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  }
                });
              } else {
                // Handle other validation errors
                Swal.fire({
                  icon: 'error',
                  title: 'Product Updating Failed',
                  text: '',
                  html: Object.values(errors).map(error => `<div>${error}</div>`).join(''),
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  }
                });
              }
            } else {
              // Other server-side error
              console.error('Failed to Update Product');
              Swal.fire({
                icon: 'error',
                title: 'Product Updating Failed',
                text: 'An error occurred while Updating the Product.',
                customClass: {
                  popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                },
                didOpen: () => {
                  document.querySelector('.swal2-container').style.zIndex = '9999'; 
                }
              });
            }
          }
        } else if (userRole === 'Warehouse Staff') {
          const { value: formValues } = await Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: 'You are about to update this customer. This action cannot be undone.',
            showCancelButton: true,
            confirmButtonText: 'Yes, update it!',
            cancelButtonText: 'Cancel',
            html: `
              <input type="text" id="swal-username" class="swal2-input" placeholder="Username">
              <input type="password" id="swal-password" class="swal2-input" placeholder="Password">
            `,
            focusConfirm: false,
            preConfirm: () => {
              const username = document.getElementById('swal-username').value;
              const password = document.getElementById('swal-password').value;

              if (!username || !password) {
                Swal.showValidationMessage('Please enter both username and password');
                return false;
              }

              return {
                username,
                password
              };
            }
          });

          if (formValues) {
            // Call the authentication endpoint
            const authResponse = await fetch('https://ceramic-back.achila.tech/api/auth/managerlogin', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'authorization': token
              },
              body: JSON.stringify({
                username: formValues.username,
                password: formValues.password,
                warehouse: warehouse
              })
            });

            const authResult = await authResponse.json();

            if (authResponse.ok && authResult.success) {
              const response = await fetch(`https://ceramic-back.achila.tech/api/product/update/${row.ItemCode}`, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                  'authorization': token,
                },
                body: JSON.stringify(itemData),
              });

              if (response.ok) {
                // User updated successfully
                const data = await response.json();
                const userId = data.UserId;
                console.log('Product Updated:', userId);
                Swal.fire({
                  icon: 'success',
                  title: 'Product Updated Successfully!',
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  }
                }).then(() => {
                  window.location.reload(); // Reload the page after successful deletion
                });
              } else if (response.status === 400) {// Error due to existing fields
                const { errors } = await response.json();
                // Handle specific error cases
                if (errors.ItemCode) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Product Updating Failed',
                    text: errors.ItemCode,
                    customClass: {
                      popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                    },
                    didOpen: () => {
                      document.querySelector('.swal2-container').style.zIndex = '9999'; 
                    }
                  });
                } else if (errors.Name) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Product Updating Failed',
                    text: errors.Name,
                    customClass: {
                      popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                    },
                    didOpen: () => {
                      document.querySelector('.swal2-container').style.zIndex = '9999'; 
                    }
                  });
                } else if (errors.Description) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Product Updating Failed',
                    text: errors.Description,
                    customClass: {
                      popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                    },
                    didOpen: () => {
                      document.querySelector('.swal2-container').style.zIndex = '9999'; 
                    }
                  });
                } else if (errors.Price) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Product Updating Failed',
                    text: errors.Price,
                    customClass: {
                      popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                    },
                    didOpen: () => {
                      document.querySelector('.swal2-container').style.zIndex = '9999'; 
                    }
                  });
                } else if (errors.category) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Product Updating Failed',
                    text: errors.category,
                    customClass: {
                      popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                    },
                    didOpen: () => {
                      document.querySelector('.swal2-container').style.zIndex = '9999'; 
                    }
                  });
                } else {
                  // Handle other validation errors
                  Swal.fire({
                    icon: 'error',
                    title: 'Product Updating Failed',
                    text: '',
                    html: Object.values(errors).map(error => `<div>${error}</div>`).join(''),
                    customClass: {
                      popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                    },
                    didOpen: () => {
                      document.querySelector('.swal2-container').style.zIndex = '9999'; 
                    }
                  });
                }
              } else {
                // If authentication fails, show an error message
                Swal.fire('Error!', 'Username or password incorrect.', 'error');
              }
            } else {
              if (authResponse.status === 401) {
                // If status is 401, set a specific error message
                Swal.fire({
                  icon: 'error',
                  title: 'Failed to Update Product',
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  },
                  text: 'Invalid username or password',
                });
              } else {
                console.error('Failed to Update Product');
                Swal.fire({
                  icon: 'error',
                  title: 'Product Updating Failed',
                  text: 'An error occurred while Updating the Product.',
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  }
                });
              }
            }
          }

        }
      } catch (error) {
        // Handle network or unexpected errors
        console.error('Error Updating Product:', error);
        let errorMessage = 'An error occurred while Updating the Product.';
        Swal.fire({
          icon: 'error',
          title: 'Product Updating Failed',
          text: errorMessage,
          customClass: {
            popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
          },
          didOpen: () => {
            document.querySelector('.swal2-container').style.zIndex = '9999'; 
          }
        });
      }
    }
  };


  const handleDelete = async () => {
    try {
      if (userRole === 'Admin' || userRole === 'Manager') {
        const confirmed = await Swal.fire({
          icon: 'warning',
          title: 'Are you sure?',
          text: 'You are about to delete this Product. This action cannot be undone.',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'Cancel',

        });

        if (confirmed.isConfirmed) {
          const response = await fetch(`https://ceramic-back.achila.tech/api/product/delete/${row.id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'authorization': token,
            },
          });

          if (response.ok) {
            // User deleted successfully
            const data = await response.json();
            const ItemCode = data.ItemCode;
            console.log('Product Deleted:', ItemCode);
            Swal.fire({
              icon: 'success',
              title: 'Product Deleted Successfully!',
              customClass: {
                popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
              },
              didOpen: () => {
                document.querySelector('.swal2-container').style.zIndex = '9999'; 
              }
            }).then(() => {
              window.location.reload(); // Reload the page after successful deletion
            });
          }
        } else {
          Swal.close();
        }
      } else if (userRole === 'Warehouse Staff') {
        const { value: formValues } = await Swal.fire({
          icon: 'warning',
          title: 'Are you sure?',
          text: 'You are about to update this customer. This action cannot be undone.',
          showCancelButton: true,
          confirmButtonText: 'Yes, update it!',
          cancelButtonText: 'Cancel',
          html: `
            <input type="text" id="swal-username" class="swal2-input" placeholder="Username">
            <input type="password" id="swal-password" class="swal2-input" placeholder="Password">
          `,
          focusConfirm: false,
          preConfirm: () => {
            const username = document.getElementById('swal-username').value;
            const password = document.getElementById('swal-password').value;

            if (!username || !password) {
              Swal.showValidationMessage('Please enter both username and password');
              return false;
            }

            return {
              username,
              password
            };
          }
        });

        if (formValues) {
          // Call the authentication endpoint
          const authResponse = await fetch('https://ceramic-back.achila.tech/api/auth/managerlogin', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'authorization': token
            },
            body: JSON.stringify({
              username: formValues.username,
              password: formValues.password,
              warehouse: warehouse
            })
          });

          const authResult = await authResponse.json();

          if (authResponse.ok && authResult.success) {
            const response = await fetch(`https://ceramic-back.achila.tech/api/product/delete/${row.id}`, {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
                'authorization': token,
              },
            });

            if (response.ok) {
              // User deleted successfully
              const data = await response.json();
              const ItemCode = data.ItemCode;
              console.log('Product Deleted:', ItemCode);
              Swal.fire({
                icon: 'success',
                title: 'Product Deleted Successfully!',
                customClass: {
                  popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                },
                didOpen: () => {
                  document.querySelector('.swal2-container').style.zIndex = '9999'; 
                }
              }).then(() => {
                window.location.reload(); // Reload the page after successful deletion
              });
            }
          } else {
            // If authentication fails, show an error message
            Swal.fire('Error!', 'Username or password incorrect.', 'error');
          }
        }
      }
    } catch (error) {
      // Handle network or unexpected errors
      console.error('Error Deleting Product:', error);
      let errorMessage = 'An error occurred while Deleting the Product.';
      Swal.fire({
        icon: 'error',
        title: 'Product Deleting Failed',
        text: errorMessage,
        customClass: {
          popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
        },
        didOpen: () => {
          document.querySelector('.swal2-container').style.zIndex = '9999'; 
        }
      });
    }
  };

  return (
    <React.Fragment>
      {!isOpen && (
        <TableRow>
          <TableCell component="th" scope="row" align="left">
            {row.ItemCode}
          </TableCell>
          <TableCell align="left">{row.Name}</TableCell>
          <TableCell align="left">{row.Price}</TableCell>
          <TableCell align="left">{row.CName}</TableCell>
          <TableCell align="left">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => onExpand(row.id)}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      )}
      {isOpen && (
        <TableRow>
          <TableCell colSpan={9}>
            <div className="border border-gray-300 py-4 px-4 rounded-lg flex flex-col bg-[#ffffff]">
              <div className=' flex justify-end'>
                <IconButton
                  aria-label="collapse row"
                  size="small"
                  onClick={() => onExpand(null)}
                >
                  <KeyboardArrowUpIcon />
                </IconButton>
              </div>
              <div className='pl-[60px] pr-[70px] pt-12 pb-6'>
                <div className='flex'>
                  <div className="flex-col mr-9">
                    <img src={itemData.Photo} alt={itemData.Name} className="w-[300px] max-w-[300px]" />
                  </div>
                  <div className='flex flex-col w-full'>
                    <div className="flex mb-8">
                      <div className="flex-grow">
                        <TextField variant="standard" label="Item Code" defaultValue={itemData.ItemCode} InputProps={{ readOnly: true, disableUnderline: true }} />
                      </div>
                      <div className="flex-grow  -ml-5 mr-2">
                        <TextField variant="standard" label="Name" value={itemData.Name} name="Name" style={{ width: '300px' }} onChange={handleChange} error={Boolean(errors.Name)} helperText={errors.Name} />
                      </div>
                      <div className="flex-grow mr-2">
                        <TextField variant="standard" label="Price" value={itemData.Price} name="Price" onChange={handleChange} error={Boolean(errors.Price)} helperText={errors.Price} />
                      </div>
                      <div className="flex-grow">
                        <FormControl variant="standard">
                          <InputLabel id="role-label">Category</InputLabel>
                          <Select
                            value={itemData.Category}
                            onChange={handleChange}
                            name="Category"
                          >
                            {categoryList.map((category) => (
                              <MenuItem key={category.id} value={category.id}>{category.Name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="flex-col justify-between mb-6">
                      <TextField variant="standard" label="Description" multiline rows={5} value={itemData.Description} style={{ width: '100%' }} name="Description" onChange={handleChange} error={Boolean(errors.Description)} helperText={errors.Description} />
                    </div>
                    <div className="flex-grow mr-2">
                      <TextField variant="standard" label="Discount" value={itemData.Discount} name="Discount" onChange={handleChange} error={Boolean(errors.Discount)} helperText={errors.Discount} 
                      InputProps={{
                        startAdornment: <p>LKR&nbsp;</p>,
                      }} />
                    </div>
                  </div>
                </div>
                <div className="flex justify-end gap-4">
                  <Button variant="contained" color="secondary" onClick={handleDelete} style={{ backgroundColor: '#F05756', width: '8%' }}>Delete</Button>
                  <Button variant="contained" color="primary" onClick={handleUpdate} style={{ backgroundColor: '#2D865B', width: '8%' }}>Update</Button>
                </div>
              </div>
            </div>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    ItemCode: PropTypes.string.isRequired,
    Name: PropTypes.string.isRequired,
    Description: PropTypes.string.isRequired,
    Price: PropTypes.number.isRequired,
    Photo: PropTypes.string,
    Category: PropTypes.number.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
};

export default function CollapsibleTable() {
  const [expandedRow, setExpandedRow] = useState(null);
  const [itemData, setItemData] = useState([]);
  const [token, setToken] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [categoryfilter, setcategoryFilter] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [amountRange, setAmountRange] = useState([0, 999999]);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(999999);


  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1); // Reset page to 1 whenever rows per page changes
  };

  const handleFilterClick = () => {
    setIsOpen(!isOpen);
  };

  const handleAmountChange = (event, newValue) => {
    setAmountRange(newValue);
    setMinValue(newValue[0]);
    setMaxValue(newValue[1]);
  };

  const handleMinInputChange = (event) => {
    let value = parseInt(event.target.value);
    if (isNaN(value) || value < 0) {
      value = 0;
    }
    if (value <= maxValue) {
      setMinValue(value);
      setAmountRange([value, maxValue]);
    }
  };

  const handleMaxInputChange = (event) => {
    let value = parseInt(event.target.value);
    if (isNaN(value) || value < minValue) {
      value = minValue;
    }
    if (value <= 999999) {
      setMaxValue(value);
      setAmountRange([minValue, value]);
    }
  };

  const handleResetFilters = () => {
    setcategoryFilter([]);
    setAmountRange([0, 999999]);
    setMinValue(0);
    setMaxValue(999999);
  };

  useEffect(() => {
    const filteredItems = itemData.filter(item => {
      const ItemCode = item.ItemCode.toString().toLowerCase();
      const Name = item.Name?.toString().toLowerCase() || '';
      const search = searchQuery.toLowerCase();
      const matchesSearchQuery = Name.includes(search) || Name === search || ItemCode.includes(search) || ItemCode === search;
      const matchesCategories = categoryfilter.length === 0 || categoryfilter.some(category => category.title === item.CName);
      const matchesAmountRange = item.Price >= minValue && item.Price <= maxValue;
      return matchesSearchQuery && matchesCategories && matchesAmountRange;
    });

    setFilteredItems(filteredItems);
  }, [searchQuery, itemData, categoryfilter, minValue, maxValue]);

  const handleFilterChange = (event, newValue) => {
    setcategoryFilter(newValue);
  };


  useEffect(() => {
    const storedData = localStorage.getItem('token');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setToken(parsedData.token);
    }

    const fetchCategories = async () => {
      try {
        const response = await fetch('https://ceramic-back.achila.tech/api/category/get', {
          headers: {
            'authorization': token,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const categoryData = data.categories;
        if (Array.isArray(categoryData)) {
          const transformedData = categoryData.map(category => ({
            title: category.Name,
            CategoryID: category.CategoryID,
          }));
          setCategoryData(transformedData);
        } else {
          throw new Error('Data received is not in the expected format');
        }
      } catch (error) {
        console.error('Error fetching category data:', error);
      }
    };

    const fetchData = async () => {
      try {
        const response = await fetch('https://ceramic-back.achila.tech/api/product/getproduct', {
          headers: {
            'authorization': token,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const itemdata = data.products;
        if (Array.isArray(itemdata)) {
          const transformedData = itemdata.map(item => createData(
            item.ItemCode,
            item.Name,
            item.Description,
            item.Price,
            item.Photo,
            item.CategoryID,
            item.CName,
            item.Discount,
          ));
          setItemData(transformedData);
          //console.log(itemData);
        } else {
          throw new Error('Data received is not in the expected format');
        }
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };

    fetchData();
    fetchCategories();
  }, [token]);

  const handleRowExpand = (rowId) => {
    setExpandedRow(prevRowId => (prevRowId === rowId ? null : rowId));
  };


  return (
    <div>
      <div className='mb-5 w-[20%]'>
        <TextField
          fullWidth
          label="Search Name or ItemCode"
          value={searchQuery}
          onChange={(e) => {
            const value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
            setSearchQuery(value);
          }}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchQuery && (
              <IconButton onClick={() => setSearchQuery('')}>
                <ClearIcon />
              </IconButton>
            ),
          }}
        />
      </div>
      <div>
        <button
          onClick={handleFilterClick}
          className="bg-[#000000] hover:bg-[#000000c1] text-white font-bold py-2 px-4 rounded-lg inline-flex items-center"
        >
          <FontAwesomeIcon icon={faFilter} className="mr-2" />
          Filter
        </button>
      </div>
      {isOpen && (
        <div className="bg-gray-200 p-5 rounded-lg inline-block relative" >
          <div className='w-[500px]'>
            <Autocomplete
              multiple
              id="filter-category"
              options={categoryData}
              getOptionLabel={(option) => option.title}
              value={categoryfilter}
              isOptionEqualToValue={(option, value) => option.title === value.title}
              onChange={handleFilterChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Filter Categories"
                  placeholder="Category"
                />
              )}
            />
          </div>
          <div className="mt-5">
            <Typography id="range-slider" gutterBottom>
              Transaction Amount
            </Typography>
            <Box width={400} className="mx-auto">
              <Slider
                value={amountRange}
                onChange={handleAmountChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                min={0}
                max={999999}
                step={1000}
              />
            </Box>
            <div className="flex justify-between mt-4">
              <TextField
                label="Min"
                type="number"
                value={minValue}
                onChange={handleMinInputChange}
                variant="outlined"
                size="small"
                className="w-[150px]"
                InputProps={{
                  startAdornment: <InputAdornment position="start">LKR</InputAdornment>,
                  inputProps: { min: 0, max: maxValue }
                }}
              />
              <TextField
                label="Max"
                type="number"
                value={maxValue}
                onChange={handleMaxInputChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start">LKR</InputAdornment>,
                  inputProps: { min: minValue, max: 9999999 }
                }}
                variant="outlined"
                size="small"
                className="w-[150px]"
              />
            </div>
          </div>

          <button
            onClick={handleResetFilters}
            className="bg-[#af4242] text-white font-bold py-2 px-4 rounded inline-flex items-center mt-5"
          >
            <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
            Reset Filters
          </button>
        </div>
      )}
      <div className='mb-4 flex items-end justify-end'>
        <div className='items-center flex mr-5'>
          <div><span style={{ color: '#4E4E4E' }}>Rows per page: &nbsp;</span></div>
          <FormControl sx={{ minWidth: 60 }} size="small">
            <Select
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
            >
              {[10, 20, 50].map((rows) => (
                <MenuItem key={rows} value={rows}>
                  {rows}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Pagination
          count={Math.ceil(filteredItems.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          style={{ marginTop: '1rem' }}
        />
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow style={{ borderBottom: '2px solid black', background: '#F7F7F7' }}>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>Item Code</TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>Name</TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>Price (LKR)</TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>Category</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ background: '#F7F7F7' }}>
            {filteredItems.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((item) => (
              <React.Fragment key={item.id}>
                <Row
                  row={item}
                  isOpen={expandedRow === item.id}
                  onExpand={handleRowExpand}
                />
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
