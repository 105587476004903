import React, { useState, useEffect } from 'react';
import Table from '../../Components/showroom/ReturnTable';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, Box } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import Autocomplete from '@mui/material/Autocomplete';
import { jwtDecode } from 'jwt-decode';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';

function ReturnProducts() {

  const [open, setOpen] = useState(false);
  const [productDetails, setProductDetails] = useState([]);
  const [selectedProduct, setselectedProduct] = useState(null);
  const [selectedProductDetails, setselectedProductDetails] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orderID, setOrderID] = useState([]);
  const [errors, setErrors] = useState({});
  const [token, setToken] = useState(null);
  const [userRole, setuserRole] = useState(null);
  const [userID, setuserID] = useState(null);
  const [quantity, setQuantity] = useState('');

  const handleQuantityChange = (event) => {
    const value = event.target.value.replace(/[^0-9]/g, '');
    setQuantity(value);
  };

  const formatorder = (order) => {
    const orderDate = new Date(order.OrderDate);
    const formattedDate = orderDate.toISOString().split('T')[0];
    return `${order.OrderID} (Date: ${formattedDate})`;
  };


  useEffect(() => {
    const storedData = localStorage.getItem('token');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setToken(parsedData.token);

      try {
        const decodedToken = jwtDecode(parsedData.token);
        if (decodedToken) {
          setuserRole(decodedToken.role);
          setuserID(decodedToken.userId);
        }
      } catch (error) {
        setuserRole(null);
      }
    } else {
      setuserRole(null);
    }

    if (token) {
      fetchorderlist();
    }
  }, [token]);

  useEffect(() => {
    if (open && selectedOrder) {
      fetchOrderProductDetails(selectedOrder.OrderID);
    } else {
      setProductDetails([]);
    }
  }, [open, selectedOrder]);

  useEffect(() => {
    const selectedProductDetails = productDetails.find(product => product.ItemCode === selectedProduct);
    setselectedProductDetails(selectedProductDetails);

  }, [selectedProduct]);

  const fetchorderlist = async () => {
    try {
      const response = await fetch('https://ceramic-back.achila.tech/api/order/orderDetails', {
        headers: {
          'authorization': token,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setOrderID(data);
      } else {
        console.error('Failed to fetch order list');
      }
    } catch (error) {
      console.error('Error fetching order list:', error);
    }
  };


  const fetchOrderProductDetails = (orderID) => {
    fetch(`https://ceramic-back.achila.tech/api/order/orderdetails/${orderID}`, {
      headers: {
        'Authorization': token,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch supplier product details');
        }
        return response.json();
      })
      .then(data => {
        console.log(data);
        setProductDetails(data);
      })
      .catch(error => {
        console.error('Error fetching product details:', error);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedOrder(null);
    setselectedProduct(null);
    setQuantity('');
    setErrors({});
  };


  const validateForm = () => {
    const errors = {};

    if (!selectedOrder) {
      errors[`order`] = 'OrderID is required';
    }
    if (!selectedProduct) {
      errors[`item`] = 'Product is required';
    }
    if (!quantity) {
      errors['quantity'] = 'Quantity is required';
    } else if (isNaN(quantity) || Number(quantity) <= 0) {
      errors['quantity'] = 'Quantity must be a positive number';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0; // Return true if there are no errors
  };


  const handleSend = async () => {
    if (validateForm()) {
      try {
        if (userRole === 'Admin' || userRole === 'Manager') {
          const confirmed = await Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: 'You are about to update this product. This action cannot be undone.',
            showCancelButton: true,
            confirmButtonText: 'Yes, update it!',
            cancelButtonText: 'Cancel',
            customClass: {
              popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
            },
            didOpen: () => {
              document.querySelector('.swal2-container').style.zIndex = '9999'; 
            }
          });

          if (confirmed.isConfirmed) {
            const response = await fetch('https://ceramic-back.achila.tech/api/order/returnrequest', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
              },
              body: JSON.stringify({
                selectedProduct: selectedProductDetails,
                selectedOrder: selectedOrder,
                Quantity: quantity,
                User: userID,
              }),
            });

            if (response.ok) {
              Swal.fire({
                icon: 'success',
                title: 'Return Successfully!',
                customClass: {
                  popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                },
                didOpen: () => {
                  document.querySelector('.swal2-container').style.zIndex = '9999'; 
                }
              }).then(() => {
                handleClose();
                window.location.reload();
              });
            } else {
              const responseData = await response.json();
              // Handle error response
              Swal.fire({
                icon: 'error',
                title: 'Failed to return',
                customClass: {
                  popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                },
                didOpen: () => {
                  document.querySelector('.swal2-container').style.zIndex = '9999'; 
                },
                text: responseData.message || 'An error occurred while sending request.',
              });
            }
          }
        } else if (userRole === 'Showroom Staff') {
          const { value: formValues } = await Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: 'You are about to update this customer. This action cannot be undone.',
            showCancelButton: true,
            confirmButtonText: 'Yes, update it!',
            cancelButtonText: 'Cancel',
            html: `
            <input type="text" id="swal-username" class="swal2-input" placeholder="Username">
            <input type="password" id="swal-password" class="swal2-input" placeholder="Password">
          `,
            focusConfirm: false,
            customClass: {
              popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
            },
            didOpen: () => {
              document.querySelector('.swal2-container').style.zIndex = '9999'; 
            },
            preConfirm: () => {
              const username = document.getElementById('swal-username').value;
              const password = document.getElementById('swal-password').value;

              if (!username || !password) {
                Swal.showValidationMessage('Please enter both username and password');
                return false;
              }

              return {
                username,
                password
              };
            }
          });

          if (formValues) {
            // Call the authentication endpoint
            const authResponse = await fetch('https://ceramic-back.achila.tech/api/auth/managerlogin', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'authorization': token
              },
              body: JSON.stringify({
                username: formValues.username,
                password: formValues.password,
                warehouse: '2'
              })
            });

            const authResult = await authResponse.json();

            if (authResponse.ok && authResult.success) {
              const response = await fetch('https://ceramic-back.achila.tech/api/order/returnrequest', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': token,
                },
                body: JSON.stringify({
                  selectedProduct: selectedProductDetails,
                  selectedOrder: selectedOrder,
                  Quantity: quantity,
                  User: userID,
                  Manager: authResult.userID
                }),
              });

              if (response.ok) {
                Swal.fire({
                  icon: 'success',
                  title: 'Return Successfully!',
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  }
                }).then(() => {
                  handleClose();
                  window.location.reload();
                });
              } else {
                const responseData = await response.json();
                // Handle error response
                Swal.fire({
                  icon: 'error',
                  title: 'Failed to return',
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  },
                  text: responseData.message || 'An error occurred while sending request.',
                });
              }
            }
          }
        }
      } catch (error) {
        console.error('Error send request:', error);
        Swal.fire({
          icon: 'error',
          title: 'Failed to return',
          customClass: {
            popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
          },
          didOpen: () => {
            document.querySelector('.swal2-container').style.zIndex = '9999'; 
          },
          text: 'An error occurred while sending request.',
        });
      }
    }
  };

  return (
    <div className="h-screen flex bg-[#F7F7F7]">
      <div className='w-20 h-screen'></div>
      <div className="flex w-full overflow-y-auto overflow-x-hidden">
        <div className='flex-col w-full'>
          <div className='font-bold text-3xl px-[60px] mt-11 py-2 -m-5 bg-[#00000019]'>
            <h1>Return Products</h1>
          </div>
          <div className='flex justify-end mt-10 mr-10'>
            <Button variant="contained" color="success" startIcon={<SendIcon />} onClick={handleClickOpen}>Return Request</Button>
            <Dialog
              open={open}
              aria-labelledby="form-dialog-title"
              disableEscapeKeyDown={true}
              BackdropProps={{
                style: { backdropFilter: 'blur(5px)' },
                invisible: true // This will prevent backdrop click
              }}
              maxWidth="xs" // Ensure consistent width
              fullWidth
              disableAutoFocus
              disableEnforceFocus
            >
              <DialogTitle id="form-dialog-title" className='text-center font-extrabold'>Return Request</DialogTitle>
              <div className='mb-3'>
                <DialogContent>
                  <div className='mb-10'>
                    <Grid item xs={9.5}>
                      <Autocomplete
                        autoHighlight
                        options={orderID}
                        getOptionLabel={(option) => formatorder(option)}
                        filterOptions={(options, { inputValue }) => options.filter(order =>
                          order.OrderID.toString().includes(inputValue)
                        )}
                        onChange={(e, newValue) => {
                          setSelectedOrder(newValue);
                        }}
                        renderOption={(props, option) => (
                          <Box {...props}>
                            {formatorder(option)}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select OrderID"
                            error={(Boolean(errors[`order`]))}
                            helperText={errors[`order`]}
                            inputProps={{
                              ...params.inputProps,
                              onInput: (event) => {
                                event.target.value = event.target.value.replace(/[^0-9]/g, '');
                              }
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </div>

                  <div className='mb-2'>Select Product:</div>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Autocomplete
                        autoHighlight
                        disabled={!selectedOrder}
                        options={productDetails}
                        getOptionLabel={(option) => `${option.ItemCode} - ${option.ProductName}`}
                        filterOptions={(options, { inputValue }) => options.filter(item => item.ProductName.includes(inputValue) || item.ItemCode.toString().includes(inputValue))}
                        onChange={(e, newValue) => {
                          const selectedItemCode = newValue ? newValue.ItemCode : ''; // Extract ItemCode from newValue
                          setselectedProduct(selectedItemCode); // Pass only the ItemCode
                        }}
                        renderOption={(props, option) => (
                          <Box {...props}>
                            {option.ItemCode} - {option.ProductName}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Product"
                            error={(Boolean(errors[`item`]))}
                            helperText={errors[`item`]}
                            disabled={!selectedOrder}
                            inputProps={{
                              ...params.inputProps,
                              onInput: (event) => {
                                event.target.value = event.target.value.toUpperCase().replace(/[^A-Z0-9\s]/g, ''); // Convert to uppercase and remove non-uppercase characters
                              }
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Enter Quantity"
                        value={quantity}
                        disabled={!selectedOrder}
                        onChange={(e) => {
                          let value = e.target.value;

                          // Enforce max length of 10 characters
                          if (value.length > 10) {
                            value = value.slice(0, 10);
                          } 
                          // Ensure only numeric input
                          value = value.replace(/\D/g, '');

                          setQuantity(value); // Update the state with the sanitized value
                        }}
                        error={Boolean(errors['quantity'])}
                        helperText={errors['quantity']}
                        inputProps={{
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                          maxLength: 10,
                        }}
                        fullWidth
                      />

                    </Grid>
                    <Grid item xs={6}>
                      <div>
                        {selectedProductDetails ? (
                          <div>
                            <p>Order Quantity:</p>
                            <p>{selectedProductDetails.Quantity}</p>
                          </div>
                        ) : (
                          <div>
                            <p>Order Quantity: N/A</p>
                          </div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </DialogContent>
              </div>
              <DialogActions className="mx-4 mb-4">
                <div className='w-full space-y-2'>
                  <Button onClick={handleSend} color="success" variant="contained" fullWidth >
                    Send
                  </Button>
                  <Button onClick={handleClose} color="error" variant="contained" fullWidth >
                    Cancel
                  </Button>
                </div>
              </DialogActions>
            </Dialog>

          </div>
          <div className='p-10'>
            <Table />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReturnProducts
