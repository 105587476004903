import React, { useState, useEffect } from 'react';
import Table from '../../Components/admin/StockTable';
import Table1 from '../../Components/admin/StockLogTable';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, MenuItem, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import Autocomplete from '@mui/material/Autocomplete';
import { jwtDecode } from 'jwt-decode';

import Swal from 'sweetalert2';

const Stock = () => {
  const [open, setOpen] = useState(false);
  const [damageopen, setDamageOpen] = useState(false);
  const [removeopen, setRemoveOpen] = useState(false);
  const [stocklogopen, setstocklogOpen] = useState(false);
  const [stockEntries, setStockEntries] = useState([{ item: null, warehouse: '', quantity: '' }]);
  const [productList, setProductList] = useState([]);
  const [errors, setErrors] = useState({});
  const [token, setToken] = useState(null);
  const [userID, setuserID] = useState(null);
  const [userRole, setuserRole] = useState(null);
  const [warehouse, setwarehouse] = useState(null);
  const [warehouseid, setwarehouseid] = useState(null);

  useEffect(() => {
    const storedData = localStorage.getItem('token');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setToken(parsedData.token);

      try {
        const decodedToken = jwtDecode(parsedData.token);
        if (decodedToken) {
          setuserID(decodedToken.userId);
          setuserRole(decodedToken.role);
          if (decodedToken.role === 'Warehouse Staff') {
            setwarehouse(decodedToken.WarehouseName);
            setwarehouseid(decodedToken.WarehouseID);
          }
        }
      } catch (error) {
        setuserID(null);
        setuserRole(null);
      }
    } else {
      setuserID(null);
      setuserRole(null);
    }

    if (token) {
      fetchProductList();
    }
  }, [token]);

  const fetchProductList = async () => {
    try {
      const response = await fetch('https://ceramic-back.achila.tech/api/product/getproduct', {
        headers: {
          'authorization': token,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setProductList(data.products);
      } else {
        console.error('Failed to fetch product list');
      }
    } catch (error) {
      console.error('Error fetching product list:', error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleDamageOpen = () => {
    setDamageOpen(true);
  };
  const handleRemoveOpen = () => {
    setRemoveOpen(true);
  };

  const handlestockOpen = () => {
    setstocklogOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setStockEntries([{ item: '', warehouse: '', quantity: '' }]);
    setErrors({});
  };
  const handleDamageClose = () => {
    setDamageOpen(false);
    setStockEntries([{ item: '', warehouse: '', quantity: '' }]);
    setErrors({});
  };
  const handleRemoveClose = () => {
    setRemoveOpen(false);
    setStockEntries([{ item: '', warehouse: '', quantity: '' }]);
    setErrors({});
  };

  const handlestockClose = () => {
    setstocklogOpen(false);
  };

  const handleStockEntryChange = (index, e) => {
    const { name, value } = e.target;
    const newStockEntries = [...stockEntries];
    newStockEntries[index][name] = value;
    setStockEntries(newStockEntries);
  };

  const handleAddRow = () => {
    setStockEntries([...stockEntries, { item: '', warehouse: '', quantity: '' }]);
  };

  const handleDeleteRow = (index) => {
    const newStockEntries = stockEntries.filter((_, i) => i !== index);
    setStockEntries(newStockEntries);
  };

  const validateForm = () => {
    const errors = {};
    stockEntries.forEach((entry, index) => {
      if (!entry.item || !entry.item.trim()) {
        errors[`item${index}`] = 'Item is required';
      }
      if (!entry.warehouse.trim()) {
        errors[`warehouse${index}`] = 'Warehouse is required';
      }
      if (!entry.quantity.trim()) {
        errors[`quantity${index}`] = 'Quantity is required';
      } else if (!/^\d{1,10}$/.test(entry.quantity)) {
        errors[`quantity${index}`] = 'Quantity should be a number with up to 10 digits';
      }
    });
    setErrors(errors);
    return Object.keys(errors).length === 0; // Return true if there are no errors
  };

  const handleAddStock = async () => {
    if (validateForm()) {
      try {
        if (userRole === 'Admin' || userRole === 'Manager') {
          const response = await fetch('https://ceramic-back.achila.tech/api/product/addStock', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
            body: JSON.stringify({ stockEntries, userID }),
          });
          if (response.ok) {
            Swal.fire({
              icon: 'success',
              title: 'Stock Added Successfully!',
              customClass: {
                popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
              },
              didOpen: () => {
                document.querySelector('.swal2-container').style.zIndex = '9999'; 
              }
            }).then(() => {
              handleClose();
              window.location.reload();
            });
          } else {
            const responseData = await response.json();
            // Handle error response
            Swal.fire({
              icon: 'error',
              title: 'Failed to Add Stock',
              customClass: {
                popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
              },
              didOpen: () => {
                document.querySelector('.swal2-container').style.zIndex = '9999'; 
              },
              text: responseData.message || 'An error occurred while adding stock.',
            });
          }
        } else if (userRole === 'Warehouse Staff') {
          const { value: formValues } = await Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: 'You are about to Add Stocks. This action cannot be undone.',
            showCancelButton: true,
            confirmButtonText: 'Yes, Add it!',
            cancelButtonText: 'Cancel',

            html: `
          <!-- index.html -->
          <input type="text" id="swal-username" class="swal2-input" style="z-index: 10000!important;" placeholder="Username">
          <input type="password" id="swal-password" class="swal2-input" style="z-index: 10000!important;" placeholder="Password">
          `,
            focusConfirm: false,
            preConfirm: () => {
              const username = document.getElementById('swal-username').value;
              const password = document.getElementById('swal-password').value;

              if (!username || !password) {
                Swal.showValidationMessage('Please enter both username and password');
                return false;
              }

              return {
                username,
                password
              };
            },
            didOpen: () => {
              document.querySelector('.swal2-container').style.zIndex = '9999';
            }
          });

          if (formValues) {
            // Call the authentication endpoint
            const authResponse = await fetch('https://ceramic-back.achila.tech/api/auth/managerlogin', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'authorization': token
              },
              body: JSON.stringify({
                username: formValues.username,
                password: formValues.password,
                warehouse: warehouseid
              })
            });

            const authResult = await authResponse.json();
            const managerID = authResult.userID;

            if (authResponse.ok && authResult.success) {
              const response = await fetch('https://ceramic-back.achila.tech/api/product/addStock', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': token,
                },
                body: JSON.stringify({ stockEntries, userID, managerID }),
              });
              if (response.ok) {
                Swal.fire({
                  icon: 'success',
                  title: 'Stock Added Successfully!',
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  }
                }).then(() => {
                  handleClose();
                  window.location.reload();
                });
              } else {
                const responseData = await response.json();
                // Handle error response
                Swal.fire({
                  icon: 'error',
                  title: 'Failed to Add Stock',
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  },
                  text: responseData.message || 'An error occurred while adding stock.',
                });
              }
            } if (authResponse.status === 401) {
              // If status is 401, set a specific error message
              Swal.fire({
                icon: 'error',
                title: 'Failed to Update Product',
                customClass: {
                  popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                },
                didOpen: () => {
                  document.querySelector('.swal2-container').style.zIndex = '9999'; 
                },
                text: 'Invalid username or password',
              });
            }
          }
        }
      } catch (error) {
        console.error('Error adding stock:', error);
        Swal.fire({
          icon: 'error',
          title: 'Failed to Add Stock',
          customClass: {
            popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
          },
          didOpen: () => {
            document.querySelector('.swal2-container').style.zIndex = '9999'; 
          },
          text: 'An error occurred while adding stock.',
        });
      }
    }
  };



  const handleRemoveStock = async () => {
    if (validateForm()) {
      try {
        const response = await fetch('https://ceramic-back.achila.tech/api/product/removestock', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
          body: JSON.stringify({ stockEntries, userID }),
        });
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Stock Removed Successfully!',
            customClass: {
              popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
            },
            didOpen: () => {
              document.querySelector('.swal2-container').style.zIndex = '9999'; 
            }
          }).then(() => {
            handleClose();
            window.location.reload();
          });
        } else {
          const responseData = await response.json();
          // Handle error response
          Swal.fire({
            icon: 'error',
            title: 'Failed to Remove Stock',
            customClass: {
              popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
            },
            didOpen: () => {
              document.querySelector('.swal2-container').style.zIndex = '9999'; 
            },
            text: responseData.message || 'An error occurred while removing stock.',
          });
        }
      } catch (error) {
        console.error('Error removing stock:', error);
        Swal.fire({
          icon: 'error',
          title: 'Failed to Remove Stock',
          customClass: {
            popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
          },
          didOpen: () => {
            document.querySelector('.swal2-container').style.zIndex = '9999'; 
          },
          text: 'An error occurred while removing stock.',
        });
      }
    }
  };

  const handleDamageStock = async () => {
    if (validateForm()) {
      try {
        if (userRole === 'Manager') {
          const response = await fetch('https://ceramic-back.achila.tech/api/product/damagestock', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
            body: JSON.stringify({ stockEntries, userID }),
          });
          if (response.ok) {
            Swal.fire({
              icon: 'success',
              title: 'Stock Removed Successfully!',
              customClass: {
                popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
              },
              didOpen: () => {
                document.querySelector('.swal2-container').style.zIndex = '9999'; 
              }
            }).then(() => {
              handleClose();
              window.location.reload();
            });
          } else {
            const responseData = await response.json();
            // Handle error response
            Swal.fire({
              icon: 'error',
              title: 'Failed to Remove Stock',
              customClass: {
                popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
              },
              didOpen: () => {
                document.querySelector('.swal2-container').style.zIndex = '9999';
              },
              text: responseData.message || 'An error occurred while removing stock.',
            });
          }
        } else if (userRole === 'Warehouse Staff') {
          const { value: formValues } = await Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: 'You are about to Remove Stocks. This action cannot be undone.',
            showCancelButton: true,
            confirmButtonText: 'Yes, Remove it!',
            cancelButtonText: 'Cancel',
            customClass: {
              popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
            },
            didOpen: () => {
              document.querySelector('.swal2-container').style.zIndex = '9999'; 
            },
            html: `
          <input type="text" id="swal-username" class="swal2-input" placeholder="Username">
          <input type="password" id="swal-password" class="swal2-input" placeholder="Password">
        `,
            focusConfirm: false,
            preConfirm: () => {
              const username = document.getElementById('swal-username').value;
              const password = document.getElementById('swal-password').value;

              if (!username || !password) {
                Swal.showValidationMessage('Please enter both username and password');
                return false;
              }

              return {
                username,
                password
              };
            }
          });

          if (formValues) {
            // Call the authentication endpoint
            const authResponse = await fetch('https://ceramic-back.achila.tech/api/auth/managerlogin', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'authorization': token
              },
              body: JSON.stringify({
                username: formValues.username,
                password: formValues.password,
                warehouse: warehouseid
              })
            });

            const authResult = await authResponse.json();
            const managerID = authResult.userID;

            if (authResponse.ok && authResult.success) {
              const response = await fetch('https://ceramic-back.achila.tech/api/product/damagestock', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': token,
                },
                body: JSON.stringify({ stockEntries, userID, managerID }),
              });
              if (response.ok) {
                Swal.fire({
                  icon: 'success',
                  title: 'Stock Removed Successfully!',
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  }
                }).then(() => {
                  handleClose();
                  window.location.reload();
                });
              } else {
                const responseData = await response.json();
                // Handle error response
                Swal.fire({
                  icon: 'error',
                  title: 'Failed to Remove Stock',
                  customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                  },
                  didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                  },
                  text: responseData.message || 'An error occurred while removing stock.',
                });
              }
            } if (authResponse.status === 401) {
              // If status is 401, set a specific error message
              Swal.fire({
                icon: 'error',
                title: 'Failed to Update Product',
                customClass: {
                  popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                },
                didOpen: () => {
                  document.querySelector('.swal2-container').style.zIndex = '9999'; 
                },
                text: 'Invalid username or password',
              });
            }
          }
        }
      } catch (error) {
        console.error('Error removing stock:', error);
        Swal.fire({
          icon: 'error',
          title: 'Failed to Remove Stock',
          customClass: {
            popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
          },
          didOpen: () => {
            document.querySelector('.swal2-container').style.zIndex = '9999'; 
          },
          text: 'An error occurred while removing stock.',
        });
      }
    }
  };

  return (
    <div className="h-screen flex bg-[#F7F7F7]">
      <div className='w-20 h-screen'></div>
      <div className="flex w-full overflow-y-auto overflow-x-hidden">
        <div className='flex-col w-full'>
          <div className='font-bold text-3xl px-[60px] mt-11 py-2 -m-5 bg-[#00000019]'>
            <h1>Manage Stock</h1>
          </div>
          <div className='flex justify-end mt-10 mr-10'>
            {(userRole === "Admin" || userRole === "Manager") &&
              <Button variant="contained" color="success" style={{ backgroundColor: '#916600', marginRight: '12px' }} startIcon={<BookOutlinedIcon />} onClick={handlestockOpen}>Stock Log</Button>
            }
            {userRole === "Admin" &&
              <Button variant="contained" color="success" style={{ backgroundColor: '#842025', marginRight: '12px' }} startIcon={<RemoveIcon />} onClick={handleRemoveOpen}>Remove Stock</Button>
            }
            {userRole !== "Admin" &&
              <Button variant="contained" color="success" style={{ backgroundColor: '#842025', marginRight: '12px' }} startIcon={<RemoveIcon />} onClick={handleDamageOpen}>Damage Stock</Button>
            }
            {/* <Button variant="contained" color="success" style={{ backgroundColor: '#122f4b', marginRight: '12px' }} startIcon={<MoveUpIcon />} onClick={handleTransOpen}>Transfer Stock</Button> */}
            <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={handleClickOpen}>Add Stock</Button>
            <Dialog
              open={open}
              aria-labelledby="form-dialog-title"
              disableEscapeKeyDown={true}
              BackdropProps={{
                style: { backdropFilter: 'blur(5px)' },
                invisible: true // This will prevent backdrop click
              }}
              maxWidth="md" // Ensure consistent width
              fullWidth
              disableAutoFocus
              disableEnforceFocus
            >
              <DialogTitle id="form-dialog-title" className='text-center font-extrabold'>Add New Stock</DialogTitle>
              <div className='mb-3'>
                <DialogContent>
                  <Grid container spacing={2}>
                    {stockEntries.map((entry, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={5}>
                          <Autocomplete
                            autoHighlight
                            options={productList.filter(option =>
                              !stockEntries.some((entry, i) => i !== index && entry.item === option.ItemCode)
                            )}
                            getOptionLabel={(option) => `${option.ItemCode} - ${option.Name}`}
                            filterOptions={(options, { inputValue }) => options.filter(item => item.Name.includes(inputValue) || item.ItemCode.toString().includes(inputValue))}
                            onChange={(e, newValue) => {
                              const selectedItemCode = newValue ? newValue.ItemCode : ''; // Extract ItemCode from newValue
                              handleStockEntryChange(index, { target: { name: 'item', value: selectedItemCode } }); // Pass only the ItemCode
                            }}
                            renderOption={(props, option) => (
                              <Box {...props}>
                                {option.ItemCode} - {option.Name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Item"
                                error={Boolean(errors[`item${index}`])}
                                helperText={errors[`item${index}`]}
                                inputProps={{
                                  ...params.inputProps,
                                  onInput: (event) => {
                                    event.target.value = event.target.value.toUpperCase().replace(/[^A-Z0-9\s]/g, ''); // Convert to uppercase and remove non-uppercase characters
                                  }
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={3.4}>
                          <TextField
                            select
                            label="Warehouse"
                            name="warehouse"
                            value={entry.warehouse}
                            onChange={(e) => handleStockEntryChange(index, e)}
                            fullWidth
                            error={Boolean(errors[`warehouse${index}`])}
                            helperText={errors[`warehouse${index}`]}
                          >
                            <MenuItem value="1" disabled={warehouse === 'Kandy'}>Gurudeniya</MenuItem>
                            <MenuItem value="2" disabled={warehouse === 'Gurudeniya'}>Kandy</MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item xs={2.5}>
                          <TextField
                            label="Quantity"
                            name="quantity"
                            value={entry.quantity}
                            onChange={(e) => {
                              if (/^\d*$/.test(e.target.value) && e.target.value.length <= 10) {
                                handleStockEntryChange(index, e);
                              }
                            }}
                            fullWidth
                            error={Boolean(errors[`quantity${index}`])}
                            helperText={errors[`quantity${index}`]}
                          />
                        </Grid>
                        <Grid item xs={1} className="flex items-center">
                          {index !== 0 && (
                            <Button onClick={() => handleDeleteRow(index)} color="error" variant="contained">
                              <DeleteIcon />
                            </Button>
                          )}
                        </Grid>
                      </React.Fragment>
                    ))}
                    <Grid item xs={1.5} className="flex">
                      <Button onClick={handleAddRow} color="primary" variant="contained" fullWidth>
                        <AddIcon /> Add
                      </Button>
                    </Grid>
                  </Grid>
                </DialogContent>
              </div>
              <DialogActions className="mx-4 mb-4">
                <div className='w-full space-y-2'>
                  <Button onClick={handleAddStock} color="success" variant="contained" fullWidth >
                    Add Stock
                  </Button>
                  <Button onClick={handleClose} color="error" variant="contained" fullWidth >
                    Cancel
                  </Button>
                </div>
              </DialogActions>
            </Dialog>

            <Dialog
              open={damageopen}
              aria-labelledby="form-dialog-title"
              disableEscapeKeyDown={true}
              BackdropProps={{
                style: { backdropFilter: 'blur(5px)' },
                invisible: true // This will prevent backdrop click
              }}
              maxWidth="md" // Ensure consistent width
              fullWidth
              disableAutoFocus
              disableEnforceFocus
            >
              <DialogTitle id="form-dialog-title" className='text-center font-extrabold'>Damage Stock</DialogTitle>
              <div className='mb-3'>
                <DialogContent>
                  <Grid container spacing={2}>
                    {stockEntries.map((entry, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={5}>
                          <Autocomplete
                            autoHighlight
                            options={productList.filter(option =>
                              !stockEntries.some((entry, i) => i !== index && entry.item === option.ItemCode)
                            )}
                            getOptionLabel={(option) => `${option.ItemCode} - ${option.Name}`}
                            filterOptions={(options, { inputValue }) => options.filter(item => item.Name.includes(inputValue) || item.ItemCode.toString().includes(inputValue))}
                            onChange={(e, newValue) => {
                              const selectedItemCode = newValue ? newValue.ItemCode : ''; // Extract ItemCode from newValue
                              handleStockEntryChange(index, { target: { name: 'item', value: selectedItemCode } }); // Pass only the ItemCode
                            }}
                            renderOption={(props, option) => (
                              <Box {...props}>
                                {option.ItemCode} - {option.Name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Item"
                                error={Boolean(errors[`item${index}`])}
                                helperText={errors[`item${index}`]}
                                inputProps={{
                                  ...params.inputProps,
                                  onInput: (event) => {
                                    event.target.value = event.target.value.toUpperCase().replace(/[^A-Z0-9\s]/g, ''); // Convert to uppercase and remove non-uppercase characters
                                  }
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={3.4}>
                          <TextField
                            select
                            label="From Which Warehouse"
                            name="warehouse"
                            value={entry.warehouse}
                            onChange={(e) => handleStockEntryChange(index, e)}
                            fullWidth
                            error={Boolean(errors[`warehouse${index}`])}
                            helperText={errors[`warehouse${index}`]}
                          >
                            <MenuItem value="1" disabled={warehouse === 'Kandy'}>Gurudeniya</MenuItem>
                            <MenuItem value="2" disabled={warehouse === 'Gurudeniya'}>Kandy</MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item xs={2.5}>
                          <TextField
                            label="Quantity"
                            name="quantity"
                            value={entry.quantity}
                            onChange={(e) => {
                              if (/^\d*$/.test(e.target.value) && e.target.value.length <= 10) {
                                handleStockEntryChange(index, e);
                              }
                            }}
                            fullWidth
                            error={Boolean(errors[`quantity${index}`])}
                            helperText={errors[`quantity${index}`]}
                          />
                        </Grid>
                        <Grid item xs={1} className="flex items-center">
                          {index !== 0 && (
                            <Button onClick={() => handleDeleteRow(index)} color="error" variant="contained">
                              <DeleteIcon />
                            </Button>
                          )}
                        </Grid>
                      </React.Fragment>
                    ))}
                    <Grid item xs={1.5} className="flex">
                      <Button onClick={handleAddRow} color="primary" variant="contained" fullWidth>
                        <AddIcon /> Add
                      </Button>
                    </Grid>
                  </Grid>
                </DialogContent>
              </div>
              <DialogActions className="mx-4 mb-4">
                <div className='w-full space-y-2'>
                  <Button onClick={handleDamageStock} color="success" variant="contained" fullWidth >
                    Remove Stock
                  </Button>
                  <Button onClick={handleDamageClose} color="error" variant="contained" fullWidth >
                    Cancel
                  </Button>
                </div>
              </DialogActions>
            </Dialog>


            <Dialog
              open={removeopen}
              aria-labelledby="form-dialog-title"
              disableEscapeKeyDown={true}
              BackdropProps={{
                style: { backdropFilter: 'blur(5px)' },
                invisible: true // This will prevent backdrop click
              }}
              maxWidth="md" // Ensure consistent width
              fullWidth
              disableAutoFocus
              disableEnforceFocus
            >
              <DialogTitle id="form-dialog-title" className='text-center font-extrabold'>Remove Stock</DialogTitle>
              <div className='mb-3'>
                <DialogContent>
                  <Grid container spacing={2}>
                    {stockEntries.map((entry, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={5}>
                          <Autocomplete
                            autoHighlight
                            options={productList.filter(option =>
                              !stockEntries.some((entry, i) => i !== index && entry.item === option.ItemCode)
                            )}
                            getOptionLabel={(option) => `${option.ItemCode} - ${option.Name}`}
                            filterOptions={(options, { inputValue }) => options.filter(item => item.Name.includes(inputValue) || item.ItemCode.toString().includes(inputValue))}
                            onChange={(e, newValue) => {
                              const selectedItemCode = newValue ? newValue.ItemCode : ''; // Extract ItemCode from newValue
                              handleStockEntryChange(index, { target: { name: 'item', value: selectedItemCode } }); // Pass only the ItemCode
                            }}
                            renderOption={(props, option) => (
                              <Box {...props}>
                                {option.ItemCode} - {option.Name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Item"
                                error={Boolean(errors[`item${index}`])}
                                helperText={errors[`item${index}`]}
                                inputProps={{
                                  ...params.inputProps,
                                  onInput: (event) => {
                                    event.target.value = event.target.value.toUpperCase().replace(/[^A-Z0-9\s]/g, ''); // Convert to uppercase and remove non-uppercase characters
                                  }
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={3.4}>
                          <TextField
                            select
                            label="From Which Warehouse"
                            name="warehouse"
                            value={entry.warehouse}
                            onChange={(e) => handleStockEntryChange(index, e)}
                            fullWidth
                            error={Boolean(errors[`warehouse${index}`])}
                            helperText={errors[`warehouse${index}`]}
                          >
                            <MenuItem value="1" disabled={warehouse === 'Kandy'}>Gurudeniya</MenuItem>
                            <MenuItem value="2" disabled={warehouse === 'Gurudeniya'}>Kandy</MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item xs={2.5}>
                          <TextField
                            label="Quantity"
                            name="quantity"
                            value={entry.quantity}
                            onChange={(e) => {
                              if (/^\d*$/.test(e.target.value) && e.target.value.length <= 10) {
                                handleStockEntryChange(index, e);
                              }
                            }}
                            fullWidth
                            error={Boolean(errors[`quantity${index}`])}
                            helperText={errors[`quantity${index}`]}
                          />
                        </Grid>
                        <Grid item xs={1} className="flex items-center">
                          {index !== 0 && (
                            <Button onClick={() => handleDeleteRow(index)} color="error" variant="contained">
                              <DeleteIcon />
                            </Button>
                          )}
                        </Grid>
                      </React.Fragment>
                    ))}
                    <Grid item xs={1.5} className="flex">
                      <Button onClick={handleAddRow} color="primary" variant="contained" fullWidth>
                        <AddIcon /> Add
                      </Button>
                    </Grid>
                  </Grid>
                </DialogContent>
              </div>
              <DialogActions className="mx-4 mb-4">
                <div className='w-full space-y-2'>
                  <Button onClick={handleRemoveStock} color="success" variant="contained" fullWidth >
                    Remove Stock
                  </Button>
                  <Button onClick={handleRemoveClose} color="error" variant="contained" fullWidth >
                    Cancel
                  </Button>
                </div>
              </DialogActions>
            </Dialog>

            <Dialog
              open={stocklogopen}
              aria-labelledby="complete-requests-dialog-title"
              disableEscapeKeyDown={true}
              BackdropProps={{
                style: { backdropFilter: 'blur(5px)' },
                invisible: true // This will prevent backdrop click
              }}
              maxWidth="xl"
              fullWidth
              disableAutoFocus
            >
              <DialogTitle id="form-dialog-title" className='text-center font-extrabold'>STOCK LOG</DialogTitle>
              <div className='mb-3'>
                <DialogContent style={{ maxHeight: '70vh' }}>
                  <Table1 />
                </DialogContent>
              </div>
              <DialogActions className="mx-4 mb-4">
                <div className='w-full space-y-2'>
                  <Button onClick={handlestockClose} color="error" variant="contained" fullWidth >
                    Cancel
                  </Button>
                </div>
              </DialogActions>
            </Dialog>
          </div>
          <div className='p-10'>
            <Table />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stock;

