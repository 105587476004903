import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Autocomplete, Select, MenuItem, FormControl, Pagination } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const createData = (ItemCode, Name, kandyQuantity, gurudeniyaQuantity, category) => ({
    ItemCode,
    Name,
    kandyQuantity,
    gurudeniyaQuantity,
    category,
});

const storedData = localStorage.getItem('token');
let token;

if (storedData) {
    const parsedData = JSON.parse(storedData);
    token = parsedData.token;
} else {
    token = null;
}

export default function LowStock() {
    const [productData, setProductData] = useState([]);
    const [lowStockData, setLowStockData] = useState([]);
    const [limits, setLimits] = useState({});
    const [open, setOpen] = useState(false);
    const [userRole, setuserRole] = useState();
    const [categories, setCategories] = useState([]);
    const [limitsToUpdate, setLimitsToUpdate] = useState({}); // State to store limits to be updated
    const [isOpen, setIsOpen] = useState(false);
    const [categoryfilter, setcategoryFilter] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filteredItems, setFilteredItems] = useState([]);
    const isAdmin = userRole === 'Admin';

    const token = JSON.parse(localStorage.getItem('token')).token;

    const fetchCategories = async () => {
        try {
            const response = await fetch('https://ceramic-back.achila.tech/api/category/getcategory', {
                headers: {
                    'authorization': token,
                },
            });
            if (response.ok) {
                const data = await response.json();
                const fetchedLimits = {};
                data.categories.forEach(category => {
                    fetchedLimits[category.Name] = category.StockLimit || 0; // Default limit to 0 if not provided
                });
                setCategories(data.categories || []);
                setLimits(fetchedLimits);
            } else {
                console.error('Failed to fetch categories');
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const handleClick = () => {
        setOpen(true); // Open the dialog
    };

    const handleFilterClick = () => {
        setIsOpen(!isOpen);
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    //Filtering
    useEffect(() => {
        const filteredItems = lowStockData.filter(item => {
            const matchesCategories = categoryfilter.length === 0 || categoryfilter.some(category => category.title === item.category);
            return matchesCategories;
        });

        setFilteredItems(filteredItems);
    }, [lowStockData, categoryfilter]);

    //Update Limits
    const updateCategoryLimits = async () => {
        try {
            console.log(limitsToUpdate);
            const response = await fetch('https://ceramic-back.achila.tech/api/category/updatelimit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': token,
                },
                body: JSON.stringify(limitsToUpdate), // Send updated limits to the backend
            });
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: 'Category limits updated successfully',
                    customClass: {
                        popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                    },
                    didOpen: () => {
                        document.querySelector('.swal2-container').style.zIndex = '9999'; 
                    }
                }).then(() => {
                    setOpen(false);
                    window.location.reload();
                });
            } else {
                const responseData = await response.json();
                // Handle error response
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to Update Category Limits',
                    customClass: {
                        popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                    },
                    didOpen: () => {
                        document.querySelector('.swal2-container').style.zIndex = '9999'; 
                    },
                    text: responseData.message || 'An error occurred while updating values.',
                });
            }
        } catch (error) {
            console.error('Failed to Update Category Limits', error);
            Swal.fire({
                icon: 'error',
                title: 'Failed to Update Category Limitsk',
                customClass: {
                    popup: 'z-50', // Apply Tailwind CSS class to adjust z-index
                },
                didOpen: () => {
                    document.querySelector('.swal2-container').style.zIndex = '9999'; 
                },
                text: 'Failed to Update Category Limits',
            });
        }
    };

    //Get stock Data
    const fetchData = async () => {
        try {
            const response = await fetch('https://ceramic-back.achila.tech/api/product/getstock', {
                headers: {
                    'authorization': token,
                },
            });
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();
            const productMap = {};
            data.products.forEach(product => {
                if (!productMap[product.ItemCode]) {
                    productMap[product.ItemCode] = {
                        ItemCode: product.ItemCode,
                        Name: product.Name,
                        kandyQuantity: 0,
                        gurudeniyaQuantity: 0,
                        category: product.CName,
                    };
                }
                if (product.WarehouseLocation === 'Kandy') {
                    productMap[product.ItemCode].kandyQuantity += product.StockQuantity;
                } else if (product.WarehouseLocation === 'Gurudeniya') {
                    productMap[product.ItemCode].gurudeniyaQuantity += product.StockQuantity;
                }
            });



            const productData = Object.values(productMap).map(product => createData(
                product.ItemCode,
                product.Name,
                product.kandyQuantity,
                product.gurudeniyaQuantity,
                product.category,
            ));
            setProductData(productData);
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    };

    //fetch category list
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch('https://ceramic-back.achila.tech/api/category/get', {
                    headers: {
                        'authorization': token,
                    },
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const categoryData = data.categories;
                if (Array.isArray(categoryData)) {
                    const transformedData = categoryData.map(category => ({
                        title: category.Name,
                        CategoryID: category.CategoryID,
                    }));
                    setCategoryData(transformedData);
                } else {
                    throw new Error('Data received is not in the expected format');
                }
            } catch (error) {
                console.error('Error fetching category data:', error);
            }
        };
        fetchCategories();
    }, [token])

    const handleFilterChange = (event, newValue) => {
        setcategoryFilter(newValue);
    };

    useEffect(() => {
        fetchCategories();
        checkUserRole();
        fetchData();
    }, []);

    //filter low stock items
    useEffect(() => {
        const applyLimits = () => {
            const filteredData = productData.filter(product => {
                const limit = limits[product.category];
                return limit && (product.gurudeniyaQuantity <= limit && product.kandyQuantity <= limit);
            });
            setLowStockData(filteredData);
        };
        applyLimits();
    }, [limits]);



    const handleClose = () => {
        setOpen(false);
        setLimitsToUpdate({});
    };

    const handleLimitChange = (categoryName, limit) => {
        // Check if the limit is a valid number or empty
        const newLimit = /^\d+$/.test(limit) || limit === '' ? limit : ''; // Accept only numbers or empty string
        setLimitsToUpdate(prevLimits => ({
            ...prevLimits,
            [categoryName]: newLimit
        }));
    };



    const checkUserRole = async () => {
        const storedData = localStorage.getItem('token');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            const token = parsedData.token;
            try {
                const decodedToken = jwtDecode(token);
                if (decodedToken) {
                    setuserRole(decodedToken.role);
                }
            } catch (error) {
                setuserRole(null);
            }
        } else {
            setuserRole(null);
        }
    };

    const handleResetFilters = () => {
        setcategoryFilter([]);
    };

    return (
        <div className="h-screen flex bg-[#F7F7F7]">
            <div className='w-20 h-screen'></div>
            <div className="flex w-full overflow-y-auto overflow-x-hidden">
                <div className='flex-col w-full'>
                    <div className='font-bold text-3xl px-[60px] mt-11 py-2 -m-5 bg-[#00000019]'>
                        <h1>Low Stock Alerts</h1>
                    </div>
                    <div className='flex justify-end mt-8 mr-10'>
                        <Button variant="contained" color="success" style={{ backgroundColor: '#122f4b' }} onClick={handleClick}>
                            Set Limits
                        </Button>
                    </div>
                    <div className='ml-10'>
                        <div>
                            <button
                                onClick={handleFilterClick}
                                className="bg-[#000000] hover:bg-[#000000c1] text-white font-bold py-2 px-4 rounded-lg inline-flex items-center"
                            >
                                <FontAwesomeIcon icon={faFilter} className="mr-2" />
                                Filter
                            </button>
                        </div>
                        {isOpen && (
                            <div className="bg-gray-200 p-5 rounded-lg inline-block relative" >
                                <div className='w-[500px]'>
                                    <Autocomplete
                                        multiple
                                        id="filter-category"
                                        options={categoryData}
                                        getOptionLabel={(option) => option.title}
                                        value={categoryfilter}
                                        isOptionEqualToValue={(option, value) => option.title === value.title}
                                        onChange={handleFilterChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Filter Categories"
                                                placeholder="Category"
                                            />
                                        )}
                                    />
                                </div>

                                <button
                                    onClick={handleResetFilters}
                                    className="bg-[#af4242] text-white font-bold py-2 px-4 rounded inline-flex items-center mt-5"
                                >
                                    <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
                                    Reset Filters
                                </button>
                            </div>
                        )}
                    </div>
                    <div className='mb-4 flex items-end justify-end mr-10'>
                        <div className='items-center flex mr-5'>
                            <div><span style={{ color: '#4E4E4E' }}>Rows per page: &nbsp;</span></div>
                            <FormControl sx={{ minWidth: 60 }} size="small">
                                <Select
                                    value={rowsPerPage}
                                    onChange={handleChangeRowsPerPage}
                                >
                                    {[10, 20, 50].map((rows) => (
                                        <MenuItem key={rows} value={rows}>
                                            {rows}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <Pagination
                            count={Math.ceil(filteredItems.length / rowsPerPage)}
                            page={page}
                            onChange={handleChangePage}
                            variant="outlined"
                            shape="rounded"
                            style={{ marginTop: '1rem' }}
                        />
                    </div>
                    <div className='flex justify-end mt-10 mx-10'>
                        <TableContainer component={Paper}>
                            <Table aria-label="low-stock table">
                                <TableHead>
                                    <TableRow style={{ borderBottom: '2px solid black', background: '#F7F7F7' }}>
                                        <TableCell align="left" style={{ fontWeight: 'bold' }}>Product ID</TableCell>
                                        <TableCell align="left" style={{ fontWeight: 'bold' }}>Product Name</TableCell>
                                        <TableCell align="left" style={{ fontWeight: 'bold' }}>Category</TableCell>
                                        <TableCell align="left" style={{ fontWeight: 'bold' }}>Gurudeniya Quantity</TableCell>
                                        <TableCell align="left" style={{ fontWeight: 'bold' }}>Kandy Quantity</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ background: '#F7F7F7' }}>
                                    {filteredItems.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((product) => (
                                        <TableRow key={product.ItemCode}>
                                            <TableCell align="left">{product.ItemCode}</TableCell>
                                            <TableCell align="left">{product.Name}</TableCell>
                                            <TableCell align="left">{product.category}</TableCell>
                                            <TableCell align="left">{product.gurudeniyaQuantity}</TableCell>
                                            <TableCell align="left">{product.kandyQuantity}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="form-dialog-title"
                            disableEscapeKeyDown={true}
                            BackdropProps={{
                                style: { backdropFilter: 'blur(5px)' },
                                invisible: true // This will prevent backdrop click
                            }}
                            maxWidth="md"
                            fullWidth
                            disableAutoFocus
                        >
                            <DialogTitle id="form-dialog-title" className='text-center font-extrabold'>Set Limits to Categories</DialogTitle>
                            <DialogContent>
                                <div className='h-5'></div>
                                <Grid container spacing={2}>
                                    {categories.map(category => (
                                        <React.Fragment key={category.CategoryID}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label={category.Name}
                                                    variant="outlined"
                                                    value={limitsToUpdate[category.Name] !== undefined ? limitsToUpdate[category.Name] : (limits[category.Name] !== undefined ? limits[category.Name] : '')}
                                                    onChange={(e) => handleLimitChange(category.Name, e.target.value)}
                                                    inputProps={{
                                                        maxLength: 9,
                                                        inputMode: 'numeric',
                                                        pattern: '[0-9]*' // Only allow numbers
                                                    }}
                                                    fullWidth
                                                    disabled={!isAdmin}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </DialogContent>
                            <DialogActions className="mx-4 mb-4">
                                {userRole === 'Admin' && (
                                    <Button onClick={updateCategoryLimits} color="success" variant="contained">
                                        Set Limits
                                    </Button>
                                )}
                                <Button onClick={handleClose} color="error" variant="contained">
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
            </div>
        </div>
    );
}
